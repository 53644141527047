import React from "react";

import { utils } from "../config/userConfig";
import PzInput from "../common/PzInput";
import PzSymbol from "../common/PzSymbol";
import EMModals from "./EMModals";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";

export default function EMComQstHeader({poll, pqst, size='xl', bold=false, op50=false, className='', 
    folded=false, setFolded=undefined, topView=false, setTopView=undefined, topN=0 }) {
    const pstatus = (poll ? poll.pstatus : ' ');
    const qstatus = (pqst ? pqst.qstatus : ' ');
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const fonts = (bold ? " font-bold " : " font-normal ") + (op50 ? " opacity-50 " : " ");
    const [open4RHist, setOpen4RHist] = React.useState(false);
    const [resultMode, setResultMode] = React.useState('T');
    const TEXTSIZE = "text-" + size;
    const SSIZE = utils.getSizeRelative(size, -1);
    // console.log("EMComQstHeader : ", "  bold:", bold, "  op50:", op50);

    function onChangeToResultMode(event) {
        let top_or_all = event.target.value;
        setResultMode(top_or_all);
        if (typeof setTopView === 'function') {
            setTopView(top_or_all === 'T' ? true : false);
        }
    }

    if (!poll) {
        return (<h3>poll is null</h3>);
    } else if (!pqst) {
        return (<h3>pqst is null</h3>);
    }

    return (
        <>
            <div className={"w-full flex flex-col justify-start items-start gap-4 " + className} >
                <div className={"w-full flex flex-row justify-between items-start "} >
                    <div className={"w-full flex flex-row justify-start items-start gap-4 "} >
                        <PzSymbol.Text text={'Q'+pqst.qseq} size={size}
                            className="!bg-purple-900 !text-white hover:cursor-pointer" 
                            onClick={()=>{ console.log(pqst); }} />
                        { pqst.qround > 1 &&
                            <PzSymbol.Text text={'Round'+pqst.qround} size={size}
                                className="!bg-purple-900 !text-white hover:cursor-pointer"
                                onClick={(e)=>{e.stopPropagation(); if (qstatus!=='Vt') setOpen4RHist(true);}} />
                        }
                    </div>
                    { pqst.qstatus <= 'Vs' ?
                        <IconChevron width={24} height={24} 
                            className={`cursor-pointer hover:text-blue-500 ${folded ? `` : `rotate-180`}`} 
                            onClick={()=>{ if (typeof setFolded === 'function') setFolded(!folded); }}/> 
                    : (pqst.qstatus === 'Vx' &&  pqst.qkind !== 'A' && setTopView) ?
                        <div className="w-full flex flex-row justify-end items-center gap-4">
                            <PzInput.Radio1 value="T" checked={resultMode==="T"} onChange={onChangeToResultMode}><span>{`Top${topN>0 ? topN : 'N'}`}</span></PzInput.Radio1>
                            <PzInput.Radio1 value="A" checked={resultMode==="A"} onChange={onChangeToResultMode}><span>ALL </span></PzInput.Radio1>
                        </div>
                    :
                        <></>
                    }
                </div>
                { pstatus === 'Os' && qstatus === 'Vs' ? 
                    <label className={TEXTSIZE + " w-full " + fonts}>{pqst.question}</label> 
                :
                    <></>
                }
            </div>

            { open4RHist &&
                <EMModals.RoundHistory poll={poll} pqst={pqst} onClose={()=>{setOpen4RHist(false);}} xclose={true} />
            }
        </>
    );
}

