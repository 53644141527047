import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SHA256, enc } from "crypto-js";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import AzArea from "./azcomp/AzArea";
import AzButton from "./azcomp/AzButton";
import AzButtonToNavigate from "./azcomp/AzButtonToNavigate";
import AzInputForVCode from "./azcomp/AzInputForVCode";
import AzInputForEmail from "./azcomp/AzInputForEmail";
import AzInputForPWord from "./azcomp/AzInputForPWord";

export default function ResetPWord() {
    const navigate = useNavigate();
    const localeMap = useSelector(utils.selectLocaleMap);
    // const ulocale = useSelector((state)=>state.ucfg.locale);  // user's current locale selection
    // const utzone = useSelector((state)=>state.ucfg.timezone); // user's current timezone selection
    const [email, setEmail] = React.useState({value:'', errmsg:''});
    const [vcode, setVcode] = React.useState({value:'', errmsg:''});
    const [pword1, setPword1] = React.useState({value:'', errmsg:''});
    const [pword2, setPword2] = React.useState({value:'', errmsg:''});
    const [errMsg, setErrMsg] = React.useState('');
    const [step, setStep] = React.useState(1);
    const [loading, setLoading] = React.useState(false);

    function sendEmailWithVcode() {
        setLoading(true);
        appConfig.getAPI().post("/api/auth/sendvcode", {uname:'', uemail:email.value, purpose:'resetpw'})
        .then((response)=>{
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI 'sendvcode' success : ", pzdata.resource);
            setStep(2);
        })
        .catch((error)=>{
            // console.log("error:", error);
            if (error.code === 'ERR_NETWORK') {
                setErrMsg(localeMap["auth.errmsg.network"]); 
            } else if (error.response && error.response.status === 400) { // BAD_REQUEST
                const pzdata = error.response.data;     // {respcode:0, respmsg:'success', resource:{...}}
                switch (pzdata.respcode) {
                    case 22 : // "email not found"
                        setErrMsg(localeMap["auth.vode.errmsg.notfound"]); 
                        break;   
                    case 23 : // "failed to send email with vcode ..."
                        setErrMsg(localeMap["auth.vcode.errmsg.emailerror"]); 
                        break;   
                    default:
                        setErrMsg(pzdata.respmsg);
                        break;   
                }
            } else {
                setErrMsg(localeMap["auth.errmsg.unknown"] + ` (${error.code})`); 
                console.log("PzAPI 'resetpw' failure - status:", error.response?.status, ", error:", error);
            }
        })
        .finally(()=>{
            setLoading(false);
        });
    }

    function postResetPW() {
        setLoading(true);
        const pwhash64 = SHA256(pword1.value).toString(enc.Hex);
        appConfig.getAPI().post("/api/auth/resetpw", {email:email.value, vcode:vcode.value, pword64:pwhash64})
        .then((response)=>{
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI 'resetpw' success : ", pzdata);
            navigate("/auth/login");
        })
        .catch((error)=>{
            // console.log("error:", error);
            if (error.code === 'ERR_NETWORK') {
                setErrMsg(localeMap["auth.errmsg.network"]); 
            } else if (error.response && error.response.status === 400) { // BAD_REQUEST
                const pzdata = error.response.data;     // {respcode:0, respmsg:'success', resource:{...}}
                switch (pzdata.respcode) {
                    case 20 : // "vcode mismatch"
                        setErrMsg(localeMap["auth.vcode.errmsg.nomatch"]); 
                        break;   
                    case 21 : // "email not found"
                        setErrMsg(localeMap["auth.resetpw.errmsg.notfound"]); 
                        break;   
                    case 22 : // ""
                        setErrMsg(localeMap["auth.resetpw.errmsg.invalid"]); 
                        break;   
                    default:
                        console.log("PzAPI 'resetpw' failure - error:", error);
                        setErrMsg(pzdata.respmsg);
                        break;   
                }
            } else {
                setErrMsg(localeMap["auth.errmsg.unknown"] + ` (${error.code})`); 
                console.log("PzAPI 'resetpw' failure - status:", error.response?.status, ", error:", error);
            }
        })
        .finally(()=>{
            setLoading(false);
        });
    };
    
    return (
        <AzArea.MwhBoardInContainer>

            { step <= 1 ?

                <div className="w-full flex flex-col justify-start items-center py-4 gap-10" >
                    <div className="w-full flex flex-col justify-start items-center gap-4" >
                        <label className="text-4xl font-bold">{localeMap["auth.resetpw.step1.title"]}</label>
                        <label className="text-xl font-bold flex flex-row justify-center text-slate-500">
                            &nbsp;{localeMap["auth.resetpw.step1.subtitle"]}&nbsp;
                        </label>
                    </div>
                    <div>&nbsp;</div>
                    <div className="w-[26rem] flex flex-col justify-start items-stretch ">
                        <div className="flex flex-col justify-start items-stretch gap-5">
                            <AzInputForEmail v={email} setV={setEmail} placeholder={localeMap["auth.resetpw.step1.input.email"]}    className="w-full" onChange={()=>{if (errMsg!=='') setErrMsg('');}} />
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-start items-center gap-4">
                        <label className="text-xl font-bold text-center text-slate-500">&nbsp;{errMsg}&nbsp;</label>
                        <AzButton type='R' className="w-[26rem] flex justify-center" 
                            disabled={email.value === '' || email.errmsg!=='' || loading} 
                            onClick={sendEmailWithVcode} loading={loading} >
                            {localeMap["auth.resetpw.step1.button"]}
                        </AzButton>
                    </div>
                </div>
            
            :

                <div className="w-full flex flex-col justify-start items-center py-4 gap-4" >
                    <div className="w-full flex flex-col justify-start items-center gap-4" >
                        <label className="text-4xl font-bold">{localeMap["auth.resetpw.step2.title"]}</label>
                        <label className="ext-xl font-bold w-[80%] flex flex-row justify-center text-slate-500">
                            &nbsp;{utils.completeMessageText(localeMap["auth.resetpw.step2.subtitle"], [email.value])}&nbsp;
                        </label>
                    </div>
                    <div>&nbsp;</div>
                    <div className="w-[26rem] flex flex-col justify-start items-stretch">
                        <div className="flex flex-col justify-start items-stretch gap-5">
                            <AzInputForVCode  v={vcode}  setV={setVcode}  placeholder={localeMap["auth.resetpw.step2.input.vcode"]}  className="w-full" onChange={()=>{if (errMsg!=='') setErrMsg('');}} />
                            <AzInputForPWord  v={pword1} setV={setPword1} placeholder={localeMap["auth.resetpw.step2.input.pword1"]} className="w-full" onChange={()=>{if (errMsg!=='') setErrMsg('');}} />
                            <AzInputForPWord  v={pword2} setV={setPword2} placeholder={localeMap["auth.resetpw.step2.input.pword2"]} className="w-full" onChange={()=>{if (errMsg!=='') setErrMsg('');}} vref={pword1} />
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-start items-center gap-2">
                        <label className="text-xl font-bold text-center text-slate-500">&nbsp;{errMsg}&nbsp;</label>
                        <AzButton type='R' className="w-[26rem] flex justify-center" 
                            disabled={vcode.value === '' || vcode.errmsg!=='' || pword1.value === '' || pword1.errmsg!=='' || pword2.value === '' || pword2.errmsg!=='' || errMsg!=='' || loading} 
                            onClick={postResetPW} loading={loading} >
                            {localeMap["auth.resetpw.step2.button"]}
                        </AzButton>
                        <div className="flex flex-col justify-start items-center gap-0">
                            <AzButtonToNavigate label={localeMap["auth.vcode.retry.label"]} button={localeMap["auth.vcode.retry.button"]} onClick={()=>{setStep(1);setErrMsg('');}} />
                        </div>
                    </div>
                </div>

            }

        </AzArea.MwhBoardInContainer>
    );
}
