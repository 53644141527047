import React from "react";

import { utils } from "../config/userConfig";
import PzInput from "../common/PzInput";

export default function EMComQstAnswers({pqst, answers, size='xl', preview=false, disabled=false, selection=[], setSelection=()=>{}, className=''}) {
    const nc2s = (pqst ? pqst.nwanted - (pqst.chosen?.length - 1) : 0);
    const toomany = (answers.length > 7);
    const clsflex = (toomany ? "w-full flex flex-wrap gap-3" : "w-auto flex flex-col gap-3");

    if (!answers) {
        return (<h3>answers is null</h3>);
    }

    return (
        <div className={"w-full flex flex-row justify-start items-center " + className}>
            <div className={ clsflex + " justify-start items-start"}>
                { pqst.qstatus === 'Vs' ?
                    answers.map((a)=>{return (
                        <PzInput.Answer key={a.aidx} value={a.aidx} text={a.aname} 
                            checked={selection && selection.includes(a.aidx)} size={toomany ? size : size}
                            disabled={disabled || !setSelection} preview={preview} 
                            heartbeat={setSelection && selection && selection.length === 0}
                            selection={selection} setSelection={setSelection} nSelection={nc2s} />
                    )}) 
                :
                    <></>
                }
            </div>
        </div>
    );
}

