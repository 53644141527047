import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import EventTimer from "../../event/EventTimer";
import PollCard from "../../event/PollCard";
import EvtStaffList from "../../event/EvtStaffList";
import EvtVoterList from "../../event/EvtVoterList";
import UserSplash from "../UserSplash";
import { ReactComponent as IconStepNext } from "../../resource/icons/pz-step-next.svg";
import { ReactComponent as IconStepPrev } from "../../resource/icons/pz-step-prev.svg";
import { ReactComponent as IconSmartPhone } from "../../resource/icons/pz-tool-smartphone.svg";
import EntranceToRunPoll from "../../entrance/EntranceToRunPoll";
import EntranceToSupport from "../../entrance/EntranceToSupport";
import EMWithPzServer from "../../eventmonitor/EMWithPzServer";

export default function SupportEvent() {
    const [cookies] = useCookies();
    const navigate = useNavigate();
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const [poll, setPoll] = React.useState(upoll);  
    const [open4EntRun, setOpen4EntRun] = React.useState(false);
    const [open4EntSup, setOpen4EntSup] = React.useState(false);
    const localeMap = useSelector(utils.selectLocaleMap);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const dispatch = useDispatch();
    const [open4Transfer, setOpen4Transfer] = React.useState(false);

    // React.useEffect(() => {
    //   uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    //   if (!uauth) navigate("/");
    // }, [cookies]);

    function setPoll2(new_poll) {
        setPoll(new_poll);                  // update the poll in memory
        dispatch(setUPoll(new_poll));       // update the poll in Redux storage
    }

    function onClickToOpenEvent() {
        if (!upoll || upoll.pstatus != 'Ax') return;
        EMWithPzServer.setPollStatus(upoll, setPoll2, 'Oo');
    }

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'S' && upoll.xrole !== 'M' && upoll.xrole !== 'P') {
        console.log("Missing permission for the event : '" + upoll.xrole + "'");
    }  

    return (
        <PzArea.Container>
            <PzArea.TBoard>

                <div className="w-full px-5 flex flex-col justify-start items-start gap-4">
                    <PollCard.Item poll={upoll} size='lg' expandable={true} withClock={true} />
                </div>

                <div className="w-full flex flex-row justify-between items-center">
                    <h2>{localeMap["menu.staff.support"]}</h2>
                    <div className={"flex flex-row justify-start items-center "} >
                        <label className={"text-xl"}>Supporting as</label> &emsp;
                        <label className={"text-xl font-bold"}>{localeMap["poll.card.staff.myrole."+upoll?.xrole?.toLowerCase()]}</label> &emsp;
                    </div>
                </div>

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    <div className="w-full flex flex-row justify-center items-start">
                        <div className="w-fit flex flex-col justify-start items-center gap-5">
                            { upoll.xrole === 'M' &&
                                <>
                                <PzButton.Std text={localeMap["poller.runpoll.open.event"]} size='xl' 
                                    className={"w-full " + (EventTimer.isOpenable(upoll) ? "heartbeat" : "")}
                                    onClick={onClickToOpenEvent} disabled={!EventTimer.isOpenable(upoll)} />
                                <PzButton.Std Icon={IconSmartPhone} text={localeMap["staff.open.runner.as.manager"]} 
                                    size='xl' className="w-full" 
                                    onClick={(e)=>{setOpen4EntRun(true);}} disabled={upoll.xrole !== 'M'} />
                                </>
                            }
                            <PzButton.Std Icon={IconSmartPhone} text={localeMap["staff.open.support.as.staff"]} size='xl' className="w-full" 
                                onClick={(e)=>{setOpen4EntSup(true);}} disabled={upoll.pstatus < 'Oo'} />
                        </div>
                    </div>
                </div>

                {/* Staff */}
                { upoll.xrole === 'M' &&
                <EvtStaffList.Module poll={upoll} asPoller={false} />
                }

                {/* Voters */}
                <EvtVoterList.Module poll={upoll} asPoller={false} />

                <div className="w-full flex flex-row justify-between items-center mt-10">
                    <PzButton.Icon Icon={IconStepPrev} size='xl' onClick={()=>{navigate(-1);}}/>
                    <PzButton.Std  Icon={IconStepNext} text={localeMap["menu.staff.sresult"]} size='lg' 
                        onClick={()=>{navigate("/pages/staff/sresult");}} 
                        disabled={poll.pstatus < 'Xc'} />
                </div>

            </PzArea.TBoard>

            { open4EntRun && 
                <EntranceToRunPoll.Dialog poll={poll} onClose={(e)=>{setOpen4EntRun(false);}} asPoller={false} />
            }
            { open4EntSup && 
                <EntranceToSupport.Dialog poll={poll} onClose={(e)=>{setOpen4EntSup(false);}}/>
            }

        </PzArea.Container>
    );
}
