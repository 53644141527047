import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useInterval } from "react-use";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";

dayjs.extend(relativeTime);

const EMEventPoster = {
    ForVoter:    EventPosterForVoter,
    ForPoller:   EventPosterForPoller,
};
export default EMEventPoster;


function EventPosterForVoter({intro}) {
    const localeMap = utils.selectMapByLocale(intro?.locale);
    const tzone = dayjs.tz.guess() // browser's timezone
    const schedule = getEventTimeInUserTimezone(localeMap, intro?.pwhen, tzone);
    const [remain, setRemain] = React.useState('');

    useInterval(() => {
        setRemain(getEventTimeRemaining(intro?.pwhen));
    }, 1000);

    function onClickToTitle() {
        console.log(intro);
    }

    function onClickToWhen() {
        let pwhen = dayjs(intro?.pwhen);
        console.log(intro?.pwhen, " => ", pwhen.format('YYYY/MM/DD h:mm A'));
        pwhen = pwhen.tz(tzone);
        console.log(intro?.pwhen, tzone, " => ", pwhen.format('YYYY/MM/DD h:mm A'));
    }

    return (
        <div className="w-full flex flex-col justify-end items-start gap-[6rem]">

            <div className="w-full flex flex-col justify-start items-center gap-5" onClick={onClickToTitle} >
                <label className="text-4xl font-bold">{intro.ptitle}</label>
                <label className="text-3xl font-bold">by {intro.uname}</label>
            </div>

            <div className="w-full flex flex-col justify-start items-center gap-5" onClick={onClickToWhen}>
                <label className="text-4xl font-bold">&nbsp;{schedule}&nbsp;</label>
                { remain ?
                    <label className={"text-3xl font-bold"}>in {remain}</label>
                : intro.pwhen === '' ?
                    <PzButton.EMWaiting size="2xl" noBorder={true} />
                :
                    <label className={"text-3xl font-bold"}>&nbsp;</label>
                }
            </div>

            <div className="w-full flex flex-col justify-start items-center gap-5">
                <label className="text-xl font-bold opacity-50">{localeMap["voter.schedule.wait"]}</label>
            </div>

        </div>
    );

}

function EventPosterForPoller({intro}) {
    const localeMap = utils.selectMapByLocale(intro?.locale);
    const utzone = useSelector((state)=>state.ucfg.timezone); // user's current timezone selection
    const schedule = getEventTimeInUserTimezone(localeMap, intro?.pwhen, utzone);
    const [remain, setRemain] = React.useState('');

    useInterval(() => {
        setRemain(getEventTimeRemaining(intro?.pwhen));
    }, 1000);

    function onClickToWhen() {
        let pwhen = dayjs(intro?.pwhen);
        console.log(intro?.pwhen, " => ", pwhen.format('YYYY/MM/DD h:mm A'));
        pwhen = pwhen.tz(utzone);
        console.log(intro?.pwhen, utzone, " => ", pwhen.format('YYYY/MM/DD h:mm A'));
    }

    return (
        <div className="w-full flex flex-col justify-start items-center gap-5" onClick={onClickToWhen}>
            <label className="text-4xl font-bold">&nbsp;{schedule}&nbsp;</label>
            { remain ?
                <label className={"text-3xl font-bold"}>in {remain}</label>
            : intro.pwhen === '' ?
                <PzButton.EMWaiting size="2xl" noBorder={true} />
            :
                <label className={"text-3xl font-bold"}>&nbsp;</label>
            }
        </div>
    );

}

function getEventTimeInUserTimezone(localeMap, time_string, user_timezone='UTC') {
    // 'time_format' should be like "YYYY/MM/DD HH:mm"
    if (!time_string) return localeMap["voter.schedule.day+0"] + ", " + localeMap["voter.schedule.soon"];
    let when = dayjs(time_string);
    let now  = dayjs();
    // console.log("When:", time_string, " => ", when.format("YYYY-MM-DD HH:mm:ss Z"), user_timezone);
    if (!user_timezone || user_timezone.length === 0) user_timezone = 'UTC';
    if (user_timezone.length === 9 && user_timezone.substring(0,3) === 'UTC') {
        let sign = (user_timezone.charAt(3) === '+' ? +1 : -1);
        let hh = parseInt(user_timezone.substring(4,6));
        let mm = parseInt(user_timezone.substring(7,9));
        let offset = sign * (hh * 60 + mm);
        when = when.utcOffset(offset);
        now = now.utcOffset(offset);
    } else {
        when = when.tz(user_timezone);
        now = now.tz(user_timezone);
    }
    // console.log("When:", when.format('YYYY/MM/DD h:mm A'), "Now:", now.format('YYYY/MM/DD h:mm A'));
    let date = when.format('YYYY/MM/DD');
    if      (date == now.format('YYYY/MM/DD')) date = localeMap["voter.schedule.day+0"];
    else if (date == now.add(+1,'day').format('YYYY/MM/DD')) date = localeMap["voter.schedule.day+1"];
    else if (date == now.add(-1,'day').format('YYYY/MM/DD')) date = localeMap["voter.schedule.day-1"];
    return date + ", " + when.format('h:mm A');
}

function getEventTimeRemaining(time_string) {
    if (!time_string) return '';
    let when = dayjs(time_string);
    let now  = dayjs();
    if (when.isBefore(now)) return '';
    return dayjs(when).fromNow(true);
}

