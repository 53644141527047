import React from "react";
import { useSelector } from "react-redux";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzInput from "../common/PzInput";
import PzSetting from "../common/PzSetting";
import PzButton from "../common/PzButton";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";
import { ReactComponent as IconThumbUp } from "../resource/icons/pz-thumb-up.svg";
import { ReactComponent as IconThumbDown } from "../resource/icons/pz-thumb-down.svg";
import { ReactComponent as IconToolMax } from "../resource/icons/pz-tool-max.svg";
import { ReactComponent as IconToolTarget } from "../resource/icons/pz-tool-target.svg";
import { ReactComponent as IconToolType } from "../resource/icons/pz-tool-type.svg";
import { ReactComponent as IconButtonSub } from "../resource/icons/pz-button-sub.svg";
import { ReactComponent as IconButtonAdd } from "../resource/icons/pz-button-add.svg";

const PollPqstGoal = {
    Footer:    PqstGoalFooter,
};
export default PollPqstGoal;


function PqstGoalFooter({poll, setPoll, qidx, ranswers=[], bold=false, size='xl', className='', editable=false, initFolded=false, onClick=(e)=>{}}) {
    const pqst = (poll && qidx >= 0 && qidx < poll.pqstlist.length ? poll.pqstlist[qidx] : undefined);
    const ulocale = useSelector((state)=>state.ucfg.locale);  // user's current locale selection
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const nChosenPrev = utils.countChosenAnswers(pqst, 1, pqst?.qround-1); // # of answers chosen in the previous rounds
    const nc2s = (pqst ? (pqst.nwanted - nChosenPrev) : 0);
    const qgoal = (pqst ? utils.completeLocaleMapText(localeMap, "monitor.quest.goal." + pqst.qkind, [ pqst.nwanted, pqst.nanswers ], poll.pkind) : '');
    const rgoal = (pqst ? utils.completeLocaleMapText(localeMap, "monitor.quest.goal." + pqst.qkind, [ nc2s, ranswers.length], poll.pkind) : '');
    const justAdded = (pqst?.justadded ? true : false);
    const [goalFolded, setGoalFolded] = React.useState(initFolded ? true : (justAdded || poll?.pqstlist.length === 1) ? false : true);
    const [selQKind, setSelQKind] = React.useState([pqst.qkind]);
    const [editKnd, setEditKnd] = React.useState(poll?.pqstlist.length === 1 ? true : false);
    const [editC2W, setEditC2W] = React.useState(poll?.pqstlist.length === 1 ? true : false);
    const [editC2D, setEditC2D] = React.useState(poll?.pqstlist.length === 1 ? true : false);
    const [editN2S, setEditN2S] = React.useState(poll?.pqstlist.length === 1 ? true : false);
    const [editMax, setEditMax] = React.useState(poll?.pqstlist.length === 1 ? true : false);
    const [chosen, setChosen] = React.useState([]); // list of the answers chosen so far
    if (pqst?.qstatus === 'Vx' && chosen.length !== utils.countChosenAnswers(pqst, 1, pqst?.qround)) {
        setChosen(utils.getChosenAnswers(pqst, 1, pqst?.qround));
    }
    const TEXTSIZE = 'text-' + size;
    const STEXTSIZE = 'text-' + utils.getSizeRelative(size, -1);
    const GAP = ((size==='4xl' || size==='3xl') ? " gap-3 " : " gap-1 ");
    // console.log(pqst?.qround, pqst.nwanted, nChosenPrev, nc2s);

    function onClickToFolder(e) {
        if (goalFolded) setGoalFolded(false); 
        else { setGoalFolded(true); setEditKnd(false); setEditN2S(false); } 
        e.stopPropagation();
    }

    function setPollWithPqst(new_pqst) {
        if (!new_pqst || !editable) return;
        let pqstlist = [ ...poll.pqstlist ];
        pqstlist[qidx] = new_pqst;
        setPoll({ ...poll, pqstlist:pqstlist });
    }

    function onChangeToQKind(e, sold, snew) {
        if (!pqst || !editable) return;
        const get_answers = (qkind) => {
            switch (qkind) {
                case 'A': return [ ...(localeMap["poll02.answers.A"] ? localeMap["poll02.answers.A"].split('\n') : []) ];
                case 'S': return [ ...(localeMap["poll02.answers.S"] ? localeMap["poll02.answers.S"].split('\n') : []) ];
                default:  return [ ...(localeMap["poll02.answers.E"] ? localeMap["poll02.answers.E"].split('\n') : []) ];
            }
        };
        if (!pqst || snew.length !== 1 || sold.length !== 1 || sold[0] === snew[0]) return;
        let new_qkind = snew[0];
        let new_answers = get_answers(new_qkind);
        let new_nwanted = (pqst.nwanted < new_answers.length ? pqst.nwanted : new_answers.length-1);
        let new_c2win = (new_qkind === 'A' ? 'Majority' : pqst.qoption.c2win);
        let new_qoption = { ...pqst.qoption, c2win: new_c2win};
        let new_qroundmax = (new_qkind === 'A' ? 1 : 3);
        let new_pqst = { ...pqst, qkind: new_qkind, 
            answers: new_answers, nanswers: new_answers.length, 
            nwanted: new_nwanted, qoption: new_qoption, qroundmax: new_qroundmax };
        setPollWithPqst(new_pqst);
        e.stopPropagation();
    }

    function setCriteria2Win(new_c2win) {
        if (!pqst || !editable) return;
        let new_qoption = { ...pqst.qoption, c2win: new_c2win };
        setPollWithPqst({ ...pqst, qoption: new_qoption });
    }

    function setCriteria2Drop(new_c2drop) {
        if (!pqst || !editable) return;
        let new_qoption = { ...pqst.qoption, c2drop: new_c2drop };
        setPollWithPqst({ ...pqst, qoption: new_qoption });
    }

    function changeNWanted(inc) {
        if (!pqst || !editable) return;
        setPollWithPqst({ ...pqst, nwanted: pqst.nwanted + inc });
    }

    function changeQRoundMax(inc) {
        if (!pqst || !editable) return;
        setPollWithPqst({ ...pqst, qroundmax: pqst.qroundmax + inc });
    }

    if (!poll) {
        return (<h3>poll is null</h3>);
    } else if (!pqst) {
        return (<h3>pqst is null</h3>);
    }

    return (
        <div className={"w-full flex flex-col justify-start items-start " + GAP + className} onClick={onClick} >
            {/* Question Type */}
            { editable && (justAdded || editKnd) &&
                <div className={"w-full grid grid-cols-[auto_1fr] justify-start items-center gap-2 "} >
                    <PzButton.Icon Icon={IconToolType} size={'md'} noBackground={true} 
                        popover={<label className={TEXTSIZE+" px-2 py-1"}>{localeMap["poll02.qkind.label"]}</label>} />
                    <div className="w-full flex flex-row justify-between items-start">
                        <PzInput.Radio2 value='A' size='lg' text={localeMap["poll02.qkind.A"]} selection={selQKind} setSelection={setSelQKind} onChange={onChangeToQKind} />
                        <PzInput.Radio2 value='S' size='lg' text={localeMap["poll02.qkind.S"]} selection={selQKind} setSelection={setSelQKind} onChange={onChangeToQKind} />
                        <PzInput.Radio2 value='E' size='lg' text={localeMap["poll02.qkind.E"]} selection={selQKind} setSelection={setSelQKind} onChange={onChangeToQKind} />
                    </div>
                </div>
            }

            {/* Round Result, if exists */}
            { pqst.qstatus === 'Vx' && 
                <div className={"w-full flex flex-col justify-start items-start gap-1 mb-2"} >
                    {/* Answers selected so far */}
                    <div className="w-full flex flex-row justify-start items-center gap-2" >
                        <label className={TEXTSIZE + (bold ? " font-bold" : " font-normal")}>
                                {localeMap["monitor.quest.result.chosen"]} :
                        </label>
                        <label className={TEXTSIZE + " font-bold"}>
                            {chosen.length === 0 ? '-' : chosen.join(', ')}
                        </label> 
                    </div>
                    {/* What to do next */}
                    <div className="flex flex-row justify-start items-center gap-2" >
                        <label className={TEXTSIZE + (bold ? " font-bold" : " font-normal")}>
                            {localeMap["monitor.quest.result.todo"]} :
                        </label>
                        { chosen.length >= pqst.nwanted ? 
                            <span className={TEXTSIZE}>{localeMap["monitor.quest.todo.done"]}</span>
                        : pqst.qround >= pqst.qroundmax && chosen.length >= 1 ?
                            <span className={TEXTSIZE}>{utils.completeMessageText(localeMap["monitor.quest.todo.fail1"], [pqst.nwanted - chosen.length])}</span>
                        : pqst.qround >= pqst.qroundmax ?
                            <span className={TEXTSIZE}>{localeMap["monitor.quest.todo.fail0"]}</span>
                        :
                            <span className={TEXTSIZE}>{utils.completeLocaleMapText(localeMap, "monitor.quest.todo."+pqst.qkind, [(pqst.nwanted - chosen.length), utils.countNextRoundAnswers(pqst,pqst.qround)], poll.pkind)}</span>
                        }
                    </div>
                </div>
            }

            {/* Goal */}
            <div className={"w-full flex flex-row justify-between items-center "}>
                <div className={"flex flex-row justify-start items-center gap-4 hover:cursor-pointer " + (pqst.qstatus === 'Vt' ? "invisible" : "")} >
                    <PzButton.Icon Icon={IconToolTarget} size={size} noBackground={true} 
                        popover={<label className={TEXTSIZE+" px-2 py-1"}>{localeMap["monitor.quest.goal.label"]}</label>} />
                    <label className={TEXTSIZE + (bold ? " font-bold" : " font-normal")} 
                        onClick={()=>{
                            if (goalFolded || !editable) return;
                            if (pqst.qkind === 'A' || editN2S) setEditKnd(true); 
                            else setEditN2S(true); 
                        }}>
                        <span className={TEXTSIZE + " font-bold cursor-pointer"}>
                            <span className={TEXTSIZE + " font-bold"}>{rgoal}</span>
                            { (pqst.qround > 1 && rgoal !== qgoal) &&
                                <span className={STEXTSIZE}> &nbsp; (R1: {qgoal}) </span>
                            }
                        </span>
                    </label> 
                    { editable && (justAdded || editN2S) && pqst.nanswers > 2 &&
                        <div className="flex flex-row justify-start items-center gap-2" onClick={()=>{setEditN2S(true);}}>
                            <PzButton.Icon Icon={IconButtonSub} size='xs' onClick={()=>{changeNWanted(-1);}} disabled={pqst.nwanted <=  1 ? true : false} />
                            <PzButton.Icon Icon={IconButtonAdd} size='xs' onClick={()=>{changeNWanted(+1);}} disabled={pqst.nwanted >= pqst.nanswers-1 ? true : false}/>
                        </div>
                    }
                </div>
                { ((!justAdded && poll.pqstlist.length !== 1) || initFolded) &&
                    <PzButton.Folder folded={goalFolded} setFolded={setGoalFolded} size={size} 
                        onChange={(newfolded)=>{if (!newfolded) { setEditKnd(false); setEditN2S(false);} }} />
                }
            </div>

            { (!goalFolded || justAdded) &&
            <>
                {/* Criteria2Win */}
                <div className={"w-full flex flex-row justify-start items-center gap-4 hover:cursor-pointer " + (pqst.qstatus === 'Vt' ? "invisible" : "")} >
                    <PzButton.Icon Icon={IconThumbUp} size={size} noBackground={true} 
                        popover={<label className={TEXTSIZE+" px-2 py-1"}>{localeMap["poll02.wcriteria.label." + pqst.qkind]}</label>} />
                    { editable && (justAdded || editC2W) ?
                        <PzSetting.C2Win criteria={pqst.qoption.c2win} setCriteria={setCriteria2Win} 
                            locale={ulocale} size={size} className='w-full' />
                    :
                        <label className={TEXTSIZE + " font-bold cursor-pointer"} onClick={()=>{setEditC2W(true);}}>
                            {utils.translateCriteria(pqst.qoption.c2win, localeMap)}
                        </label> 
                    }
                </div>
                {/* Criteria2Drop */}
                { pqst.qkind !== 'A' && pqst.qroundmax > 1 &&
                <div className={"w-full flex flex-row justify-start items-center gap-4 hover:cursor-pointer " + (pqst.qstatus === 'Vt' ? "invisible" : "")} >
                    <PzButton.Icon Icon={IconThumbDown} size={size} noBackground={true} 
                        popover={<label className={TEXTSIZE+" px-2 py-1"}>{localeMap["poll02.dcriteria.label"]}</label>} />
                    { editable && (justAdded || editC2D) ?
                        <PzSetting.C2Drop criteria={pqst.qoption.c2drop} setCriteria={setCriteria2Drop} 
                            locale={ulocale} size={size} className='w-full' />
                    :
                        <label className={TEXTSIZE + " font-bold cursor-pointer"} onClick={()=>{setEditC2D(true);}}>
                            {utils.translateCriteria(pqst.qoption.c2drop, localeMap)}
                        </label> 
                    }
                </div>
                }
                {/* QRoundMax */}
                <div className={"w-full flex flex-row justify-start items-center gap-4 hover:cursor-pointer " + (pqst.qstatus === 'Vt' ? "invisible" : "")} >
                    <PzButton.Icon Icon={IconToolMax} size={size} noBackground={true} 
                        popover={<label className={TEXTSIZE+" px-2 py-1"}>{localeMap["monitor.quest.goal.roundmax"]}</label>} />
                    <label className={TEXTSIZE + " font-bold cursor-pointer"} onClick={()=>{setEditMax(true);}}>
                        {pqst.qroundmax}
                    </label> 
                    { editable && pqst.qkind !== 'A' && (justAdded || editMax) &&
                        <div className="flex flex-row justify-start items-center gap-2">
                            <PzButton.Icon Icon={IconButtonSub} size='xs' onClick={()=>{changeQRoundMax(-1);}} disabled={pqst.qroundmax <= 1 ? true : false} />
                            <PzButton.Icon Icon={IconButtonAdd} size='xs' onClick={()=>{changeQRoundMax(+1);}} disabled={pqst.qroundmax >= 9 ? true : false} />
                        </div>
                    }
                </div>
            </>
            }
        </div>
    );
}

