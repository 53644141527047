import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import EntranceForVoters from "../entrance/EntranceForVoters";
import EMModals from "./EMModals";
import { ReactComponent as IconLBuoy } from "../resource/icons/pz-tool-lifebuoy.svg";
import { ReactComponent as IconVResult } from "../resource/icons/pz-vote-result.svg";

const EMButtonForStaff = {
    HelpMenu:   EMButtonForStaffHelpMenu,
    RsltMenu:   EMButtonForStaffRsltMenu,
};
export default EMButtonForStaff;


function EMButtonForStaffHelpMenu({poll, setPoll, pqst, size='xl'}) {
    const location = useLocation();
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const pstatus = (poll ? poll.pstatus : '');
    const [open4SList, setOpen4SList] = React.useState(false);
    const [open4VList, setOpen4VList] = React.useState(false);
    const [open4Entrance, setOpen4Entrance] = React.useState(false);
    // console.log(poll.qactive, poll.qactive_selected);

    return (
        <>
            <PzButton.EMMenu size={size} Icon={IconLBuoy} pale={true}
                className={pstatus === 'Ax' || pstatus.startsWith('O') ? '' : 'hidden'}
                items={[
                    { text:localeMap["monitor.help.stafflist"], callback:()=>{setOpen4SList(true);}, hidden:(poll.nstaff === 0) },
                    { text:localeMap["monitor.help.voterlist"], callback:()=>{setOpen4VList(true);} },
                    { text:localeMap["monitor.help.qrcode"],    callback:()=>{setOpen4Entrance(true);}, hidden:(pstatus>='Oz') },
                    { text:localeMap["monitor.help.help2checkin"],  callback:()=>{}, hidden:(pstatus>='Oz') },
                    { text:localeMap["monitor.help.help2vote"],     callback:()=>{}, hidden:(pstatus!=='Os') },
                ]}  ha='left'  />

            {open4Entrance && 
                <EntranceForVoters.Dialog poll={poll} onClose={()=>{setOpen4Entrance(false);}} />
            }
            {open4SList && 
                <EMModals.StaffList poll={poll} onClose={()=>{setOpen4SList(false);}} xclose={true} />
            }
            {open4VList && 
                <EMModals.VoterList poll={poll} onClose={()=>{setOpen4VList(false);}} xclose={true} />
            }

        </>
    );

}

function EMButtonForStaffRsltMenu({poll, size='xl'}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    return (
        <>
            <PzButton.EMMenu size={size} Icon={IconVResult} pale={true} 
                className={poll.pstatus === 'Oz' ? '' : 'hidden'}
                items={[
                    { text:localeMap["monitor.action.copyresult"], callback:()=>{console.log('copied')}, disabled:true },
                ]} />
        </>
    );
}

