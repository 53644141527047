import React from "react";
import { createSlice } from '@reduxjs/toolkit'
import { useLocation } from "react-router-dom";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

import appConfig from "./appConfig";
import en_map from "../resource/locales/en-US";
import es_map from "../resource/locales/es-ES";
import ko_map from "../resource/locales/ko-KR";

dayjs.extend(relativeTime);

export const userConfigSlice = createSlice({
    name: 'ucfg',
    initialState: {
        locale:     '',     // selected locale
        timezone:   null,   // selected timezone
    },
    reducers: {
        setLocale: (state, action) => {
            if (!action.payload) return;
            state.locale = action.payload;
        },
        setTimezone: (state, action) => {
            if (!action.payload) return;
            state.timezone = action.payload;
        },
    }
})

const selectLocaleMap = (state) => { 
    // select the map automatically using user configuration
    let ucfg = state.ucfg;
    if (!ucfg) {
        return ko_map; 
    } else {
        switch (ucfg.locale) {
            case 'EN':
            case "en": return en_map;
            case 'ES':
            case "es": return es_map;
            case 'KO':
            case "ko": return ko_map;
            default:   return ko_map;
        }
    }
};

const selectMapByLocale = (locale) => {
    // select the map manually by given locale
    switch (locale) {
        case 'EN':
        case 'en': return en_map;
        case 'ES':
        case 'es': return es_map;
        case 'KO':
        case 'ko': return ko_map;
        default:   return ko_map;
    }
}

const translateCriteria = (criteria, localeMap) => {
    if (criteria.startsWith('N >') || criteria.startsWith('N <')) {
        return criteria;
    } else {
        return localeMap["poll.criteria."+criteria];
    }
}

const completeMessageText = (msg, params) => {
    const replace_if_exist = (msg, oldsub, newsub) => {
        let idx = msg.indexOf(oldsub);
        if (idx >= 0) {
            let new_msg = msg.substring(0,idx) + newsub + msg.substring(idx+oldsub.length);
            return {new_msg:new_msg, found:true};
        } else {
            return {new_msg:msg, found:false};
        }
    };
    if (!msg) return msg;
    if (Array.isArray(params)) {
        for (let i=1; i < 10 && i <= params.length; i++) {
            const {new_msg, found} = replace_if_exist(msg, "{"+i+"}", params[i-1]);
            if (found) msg = new_msg;
        }
    }
    return msg;
};

const replaceMessageSubstring = (msg, substring, newstring='') => {
    if (typeof msg === 'string') return msg.replace(substring, newstring).trim();
    else return msg;
}

const completeLocaleMapText = (localeMap, key, params, pkind) => {
    const lmText = localeMap[key];
    if (!lmText) { console.log(`localeMap['${key}'] not found`); return '';}
    // replace {1},{2},{3},... with 'params' values
    let msg = completeMessageText(lmText, params);
    if (typeof pkind !== 'string' || pkind.length < 3) return msg;
    let ptype = pkind.toLowerCase().charAt(0);
    if (ptype !== 'p' && ptype !== 's' && ptype !== 'q') {
        return msg;
    } else if (msg.indexOf('{') < 0) {
        return msg;
    }
    // replace other {???} entries
    const replace_if_exist = (msg, oldsub, newsub) => {
        let idx = msg.indexOf(oldsub);
        if (idx >= 0) {
            let new_msg = msg.substring(0,idx) + newsub + msg.substring(idx+oldsub.length);
            return {new_msg:new_msg, found:true};
        } else {
            return {new_msg:msg, found:false};
        }
    };
    // replace '{Etype}' and '{etype}' in the message
    if (msg.indexOf('vent}') >= 0) {
        let evttype = localeMap["comm.etype."+ptype];
        if (!evttype) {
            console.log(`localeMap['${"comm.etype."+ptype}'] not found`); 
        } else {
            const {new_msg:new_msg1, found:found1} = replace_if_exist(msg, "{etype}", evttype);
            if (found1) msg = new_msg1;
            // console.log("ptype:", ptype, "evttype:", evttype, "found1:", found1);
            if (evttype.charAt(0) >= 'a' && evttype.charAt(0) <= 'z') {
                evttype = evttype.charAt(0).toUpperCase() + evttype.substring(1)
            }
            const {new_msg:new_msg2, found:found2} = replace_if_exist(msg, "{Etype}", evttype);
            if (found2) msg = new_msg2;
            // console.log("ptype:", ptype, "evttype:", evttype, "found2:", found2);
        }
    }
    // replace '{voters}' and '{Voters}' in the message
    if (msg.indexOf('oters}') >= 0) {
        let target = localeMap["comm.voters."+ptype];
        if (!target) {
            console.log(`localeMap['${"comm.voters."+ptype}'] not found`); 
        } else {
            const {new_msg:new_msg1, found:found1} = replace_if_exist(msg, "{voters}", target);
            if (found1) msg = new_msg1;
            // console.log("ptype:", ptype, "target:", target, "found1:", found1);
            if (target.charAt(0) >= 'a' && target.charAt(0) <= 'z') {
                target = target.charAt(0).toUpperCase() + target.substring(1)
            }
            const {new_msg:new_msg2, found:found2} = replace_if_exist(msg, "{Voters}", target);
            if (found2) msg = new_msg2;
            // console.log("ptype:", ptype, "target:", target, "found2:", found2);
        }
    }
    // replace '{voter}' and '{Voter}' in the message
    if (msg.indexOf('oter}') >= 0) {
        let target = localeMap["comm.voter."+ptype];
        if (!target) {
            console.log(`localeMap['${"comm.voter."+ptype}'] not found`); 
        } else {
            const {new_msg:new_msg1, found:found1} = replace_if_exist(msg, "{voter}", target);
            if (found1) msg = new_msg1;
            // console.log("ptype:", ptype, "target:", target, "found1:", found1);
            if (target.charAt(0) >= 'a' && target.charAt(0) <= 'z') {
                target = target.charAt(0).toUpperCase() + target.substring(1)
            }
            const {new_msg:new_msg2, found:found2} = replace_if_exist(msg, "{Voter}", target);
            if (found2) msg = new_msg2;
            // console.log("ptype:", ptype, "target:", target, "found2:", found2);
        }
    }
    return msg;
}

const completeTimeText = (msg, reftime, user_timezone) => {
    const replace_if_exist = (msg, oldsub, newsub) => {
        let idx = msg.indexOf(oldsub);
        if (idx >= 0) {
            let new_msg = msg.substring(0,idx) + newsub + msg.substring(idx+oldsub.length);
            return {new_msg:new_msg, found:true};
        } else {
            return {new_msg:msg, found:false};
        }
    };
    if (!msg) return msg;
    let targets = [];
    targets.push({t:'{YYYY/MM/DD HH:mm}', v:getTimeInUserTimezone(reftime.toISOString(), 'YYYY/MM/DD HH:mm', user_timezone)});
    targets.push({t:'{MM/DD HH:mm}', v:getTimeInUserTimezone(reftime.toISOString(), 'MM/DD HH:mm', user_timezone)});
    targets.push({t:'{hh:mm:ss}', v:getTimeInUserTimezone(reftime.toISOString(), 'HH:mm:ss', user_timezone)});
    targets.push({t:'{rm:rs}', v:getTimeRemainingInString(reftime, 'mm:ss')});
    for (let i=0; i < targets.length; i++) {
        const {new_msg, found} = replace_if_exist(msg, targets[i].t, targets[i].v);
        if (found) msg = new_msg;
    }
    return msg;
};

const getTimeInUserTimezone = (time_string, time_format, user_timezone='UTC', with_utc=false) => {
    // 'time_format' should be like "YYYY/MM/DD HH:mm"
    if (time_string === '') return '';
    if (!user_timezone || user_timezone.length === 0) user_timezone = 'UTC';
    let tstr = '';
    if (user_timezone.length === 9 && user_timezone.substring(0,3) === 'UTC') {
        let sign = (user_timezone.charAt(3) === '+' ? +1 : -1);
        let hh = parseInt(user_timezone.substring(4,6));
        let mm = parseInt(user_timezone.substring(7,9));
        let offset = sign * (hh * 60 + mm);
        tstr = dayjs(time_string).utcOffset(offset).format(time_format);
    } else {
        tstr = dayjs(time_string).tz(user_timezone).format(time_format);
    }
    if (with_utc && user_timezone !== 'UTC' && tstr.substring(0,7) !== 'Invalid') {
        tstr += " (" + dayjs(time_string).utc().format("HH:mm[z]") + ")";
    }
    return tstr;
};

const getPeriodInUserTimezone = (from_string, upto_string, user_timezone='UTC', with_utc=false) => {
    if (!user_timezone || user_timezone.length === 0) user_timezone = 'UTC';
    let from = dayjs(from_string);
    let upto = dayjs(upto_string);
    if (user_timezone.length === 9 && user_timezone.substring(0,3) === 'UTC') {
        let sign = (user_timezone.charAt(3) === '+' ? +1 : -1);
        let hh = parseInt(user_timezone.substring(4,6));
        let mm = parseInt(user_timezone.substring(7,9));
        let offset = sign * (hh * 60 + mm);
        from = from.utcOffset(offset);
        upto = upto.utcOffset(offset);
    } else {
        from = from.tz(user_timezone);
        upto = upto.tz(user_timezone);
    }
    let period = '';
    if (upto.diff(from, 'hour') >= 24) {
        period = from.format("YYYY/MM/DD") + " ~ " + upto.format("MM/DD");
    } else {
        period = from.format("YYYY/MM/DD HH:mm") + " ~ " + upto.format("HH:mm");
    }
    if (with_utc && user_timezone !== 'UTC' && period.substring(0,7) !== 'Invalid') {
        period += " (" + from.utc().format("HH:mm[Z]") + ")";
    }
    return period;
};

function getTimeRemainingInString(when, format) {
    if (format == 'fromNow') {
        return dayjs(when).fromNow(true);
    } else {
        let hh = 0, mm = 0, ss = 0;
        let diff = dayjs(when).diff(dayjs(), 'second');
        if (diff > 0) {
            hh = Math.floor(diff / 3600);
            let diff_h = diff - (hh * 3600);
            mm = Math.floor(diff_h / 60);
            ss = diff_h - (mm * 60);
        }
        switch (format) {
            case 'hh:mm:ss':
            case 'mm:ss':
                if (hh > 0) return `${hh}:${mm>9 ? mm : '0'+mm.toString()}:${ss>9 ? ss : '0'+ss.toString()}`;
                else        return `${mm>9 ? mm : '0'+mm.toString()}:${ss>9 ? ss : '0'+ss.toString()}`;
            default:
                return `${diff} seconds`;
        }
    }
}

function getQstGoalSummary(q, localeMap) {
    if (!q || !localeMap) return '';

    switch (q.qkind) {
    case 'A': return localeMap["monitor.quest.goal.A"];
    case 'S': return utils.completeMessageText(localeMap["monitor.quest.goal.S"], [q.nwanted, q.nanswers]);
    case 'E': return utils.completeMessageText(localeMap["monitor.quest.goal.E"], [q.nwanted, q.nanswers]);
    case 'C': return localeMap["monitor.quest.goal.C"];
    default: return ``;
    }
}

function getQstResultSummary(q, localeMap) {
    if (!q || !localeMap) return '';

    switch (q.qkind) {
    case 'A': 
        let rstA = localeMap["poll02.xresult.A"].split('/');
        if      (q.chosen && q.chosen.length >= 2 && q.chosen[1].aname === 'YES') return rstA[1];
        else if (q.chosen && q.chosen.length >= 2 && q.chosen[1].aname === 'NO' ) return rstA[2];
        else return rstA[0];
    case 'S': 
        const rstS = localeMap["poll02.xresult.S"].split('/');
        if      (q.chosen && q.chosen.length >= 3) return completeMessageText(rstS[2], [q.chosen[1].aname, q.chosen.length-2]);
        else if (q.chosen && q.chosen.length == 2) return completeMessageText(rstS[1], [q.chosen[1].aname]);
        else return rstS[0];
    case 'E': 
        const rstE = localeMap["poll02.xresult.E"].split('/');
        if      (q.chosen && q.chosen.length >= 3) return completeMessageText(rstE[2], [q.chosen[1].aname, q.chosen.length-2]);
        else if (q.chosen && q.chosen.length == 2) return completeMessageText(rstE[1], [q.chosen[1].aname]);
        else return rstE[0];
    case 'C': 
        return `${localeMap["poll02.xresult.C"]}`;
    default: return ``;
    }
}

// export const { setLocale, setTimezone } = userConfigSlice.actions;

const rex_digit_only = /^[-.+() 0-9]+[0-9]$/;
const rex_bdate = /^(?:(19[0-9]{2}|20[0-9]{2}|[0-9]{2})[-.]?)?(0[1-9]|1[0-2])[-.]?(0[1-9]|[1-2][0-9]|30|31)$/;
const rex_phone = /^(?:(\+[0-9]{1,3}|\(\+?[0-9]{1,3}\))[-. ]?)?([0-9]{2,4}[-. ]?(?:[0-9]{3,4}[-. ]?){0,2}[0-9]{3,4})$/;
const rex_email = /^(\w[-.a-zA-Z0-9_]*)@([-a-zA-Z0-9]+(?:\.[-a-zA-Z0-9]+){0,3}\.[a-zA-z]{2,3})$/;
const rex_vcode4 = /^\d{4}$/;
const rex_vcode6 = /^\d{6}$/;
const rex_passwd = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;

function parse_email_string(str) {
    let m = rex_email.exec(str);
    if (!m) return [ undefined, undefined, undefined ];  
    return [ m[0], m[1], m[2] ];         // [ 'UserID@Domain', 'UserID', 'Domain' ]
}

function parse_bdate_string(str) {
    let m = rex_bdate.exec(str);
    if (!m) return [ undefined, undefined, undefined, undefined ];  
    let bdate = m[0].replace(/[-. ]/g, '');     // digits with length 4, 6, or 8 
    return [ bdate, m[1], m[2], m[3] ];         // [ 'YYYYMMDD', 'YYYY', 'MM', 'DD' ]
}

function parse_phone_string(str, locale='en') {
    let m = rex_phone.exec(str);
    if (!m) return [ undefined, undefined, undefined ];     // 
    let cnum = (m[1] ? m[1].replace(/[+()]/g, '') : appConfig.getCountryNumberFromLocale(locale));
    let pnum = (m[2] ? m[2].replace(/[-. ]/g, '') : '');
    let phone = `+${cnum} ${pnum}`;
    return [ phone, cnum, pnum ];               // [ '+82 01028621234', '82', '01028621234' ]
}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function getChosenAnswers(pqst, rmin, rmax) {
    if (!pqst || !pqst.chosen) return [];   // 'pqst' might be incomplete (newly created?)
    let chosen = [];
    rmin = (!rmin || rmin < 0           ? 0           : rmin);
    rmax = (!rmax || rmax > pqst.qround ? pqst.qround : rmax);
    for (let aidx = 1; aidx < pqst.chosen?.length; aidx++) {
        let c = pqst.chosen[aidx];
        for (let r = rmin; r <= rmax; r++) {
            if (c.astat === `${r}O`) chosen.push(c.aname);
        }
    }
    return chosen;
}

function countChosenAnswers(pqst, rmin=-1, rmax=-1) {
    if (!pqst || !pqst.chosen) return [];   // 'pqst' might be incomplete (newly created?)
    let count = 0;
    let rmin2 = (rmin < 0           ? 0           : rmin);
    let rmax2 = (rmax > pqst.qround ? pqst.qround : rmax);
    for (let aidx = 1; aidx < pqst.chosen.length; aidx++) {
        for (let r = rmin2; r <= rmax2; r++) {
            if (pqst.chosen[aidx].astat === `${r}O`) count++;
        }
    }
    return count;
}

function countValidAnswers(pqst, round) {
    if (!pqst) return 0;
    let nvalid = 0;
    for (let aidx = 1; aidx < pqst.answers.length; aidx++) {
        if (pqst.answers[aidx].astat >= `${round}-`) nvalid++;
    }
    return nvalid;
}

function countDroppedAnswers(pqst, rmin, rmax) {
    if (!pqst) return ndropped;
    let ndropped = 0;
    rmin = (!rmin || rmin < 1           ? 1           : rmin);
    rmax = (!rmax || rmax > pqst.qround ? pqst.qround : rmax);
    for (let aidx = 1; aidx < pqst.answers.length; aidx++) {
        for (let r = rmin; r <= rmax; r++) {
            if (pqst.answers[aidx].astat === `${r}X`) ndropped++;
        }
    }
    return ndropped;
}

function countNextRoundAnswers(pqst, round) {
    if (!pqst) return 0;
    let nnext = 0;
    for (let aidx = 1; aidx < pqst.answers.length; aidx++) {
        let astat = pqst.answers[aidx].astat;
        if  (astat === `${round}-` || astat  >= `${round+1}-`) nnext++;
    }
    return nnext;
}

function getDiffSince(when) {
    switch (typeof when) {
        case 'string': return dayjs().diff(dayjs(when));    // 'when' is a string
        case 'object': 
            if (when)   return dayjs().diff(when);          // 'when' is a dayjs() value
            else        return undefined;                   // 'undefined'
        default: return 0;
    }
}

function getSizeRelative(size,step=-1) {
    switch (size) {
        case '4xl': return (step>0 ? '5xl': step<-1 ? '2xl'  : '3xl');
        case '3xl': return (step>0 ? '4xl': step<-1 ? 'xl'  : '2xl');
        case '2xl': return (step>0 ? '3xl': step<-1 ? 'lg'  : 'xl');
        case 'xl':  return (step>0 ? '2xl': step<-1 ? 'md'  : 'lg');
        case 'lg':  return (step>0 ? 'xl':  step<-1 ? 'sm'  : 'md');
        case 'md':  return (step>0 ? 'lg':  step<-1 ? 'xs'  : 'sm');
        case 'sm':  return (step>0 ? 'md':  step<-1 ? 'xs'  : 'xs');
        default:    return (step>0 ? 'sm':  step<-1 ? 'xs'  : 'xs');
    }
}


export const utils = { 
    ...userConfigSlice.actions,

    // locale message
    selectLocaleMap: selectLocaleMap,           // select the map automatically using user configuration
    selectMapByLocale: selectMapByLocale,       // select the map manually by given locale
    translateCriteria: translateCriteria,
    completeMessageText: completeMessageText,
    completeLocaleMapText: completeLocaleMapText,
    completeTimeText: completeTimeText,
    replaceMessageSubstring: replaceMessageSubstring,
    // time & period
    getTimeInUserTimezone: getTimeInUserTimezone,
    getPeriodInUserTimezone: getPeriodInUserTimezone,
    getTimeRemainingInString: getTimeRemainingInString,
    getDiffSince: getDiffSince,

    // poll summary
    getQstGoalSummary: getQstGoalSummary,
    getQstResultSummary: getQstResultSummary,

    // user input patterns
    isDigitOnly: (text)=>{return rex_digit_only.test(text);},
    isValidBDate: (text)=>{return rex_bdate.test(text);},
    isValidPhone: (text)=>{return rex_phone.test(text);},
    isValidEmail: (text)=>{return rex_email.test(text);},
    isValidVCode4: (text)=>{return rex_vcode4.test(text);},
    isValidVCode6: (text)=>{return rex_vcode6.test(text);},
    isValidPasswd: (text)=>{return rex_passwd.test(text);},
    parseEmailString: parse_email_string,
    parseBDateString: parse_bdate_string,
    parsePhoneString: parse_phone_string,

    useQuery: useQuery,

    // poll result
    getChosenAnswers:       getChosenAnswers,
    countChosenAnswers:     countChosenAnswers,
    countValidAnswers:      countValidAnswers,
    countDroppedAnswers:    countDroppedAnswers,
    countNextRoundAnswers:  countNextRoundAnswers,
    getSizeRelative:        getSizeRelative,

};

