import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useInterval } from "react-use";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import PzDialog from "../common/PzDialog";
import EMWithPzServer from "./EMWithPzServer";
import EMWithSimulator from "./EMWithSimulator";
import EntranceForVoters from "../entrance/EntranceForVoters";
import EMModals from "./EMModals";
import { ReactComponent as IconLBuoy } from "../resource/icons/pz-tool-lifebuoy.svg";
import { ReactComponent as IconVResult } from "../resource/icons/pz-vote-result.svg";

const EMButtonForPoller = {
    Action:     EMButtonForPollerAction,
    HelpMenu:   EMButtonForPollerHelpMenu,
    RsltMenu:   EMButtonForPollerRsltMenu,
};
export default EMButtonForPoller;


function EMButtonForPollerAction({poll, qidx, pqst, setPoll, size='xl'}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const location = useLocation();
    const pstatus = (poll ? poll.pstatus : ' ');
    const qstatus = (pqst ? pqst.qstatus : ' ');
    const [quitting, setQuitting] = React.useState(isQuitting());
    const [disabled, setDisabled] = React.useState(isNextDisabled());
    const [visible, setVisible] = React.useState(false);
    const [open4XPoll, setOpen4XPoll] = React.useState(false);
    const simulating = (location.pathname.startsWith("/y/"));
    let [actionSymbol, actionText] = getActionSymbolText();
    // console.log(actionSymbol, actionText, disabled, visible);

    useInterval(() => {
        if (!poll) return;
        if (quitting !== isQuitting()) setQuitting(isQuitting());
        if (disabled !== isNextDisabled()) setDisabled(isNextDisabled());
        let new_visible = (actionText !== '' && utils.getDiffSince(poll?.updatedat) > 1000);
        if (!visible && new_visible) setVisible(new_visible);
        // console.log("disabled:", disabled, "  invisible:", invisible);
    }, 500);

    function isQuitting() {
        if (!poll) return false;
        let closed_pqst = 0;
        for (let i = 0; i < poll.pqstlist.length; i++) {
            if (poll.pqstlist[i].qstatus >= 'Vx') closed_pqst++;
        }
        return (closed_pqst > 0 && (closed_pqst === poll.pqstlist.length || poll.qactive_selected === -1));
    }

    function isNextDisabled() {
        if (!poll) return false;
        let diff = utils.getDiffSince(poll.updatedat);
        let TOO_EARLY = (!diff || diff < 1000);
        switch (pstatus) { // current status
        case 'Ax': return (TOO_EARLY);
        case 'Oo': return (TOO_EARLY || poll.natt < poll.nmin);
        case 'Os':
            switch (qstatus) {
            case ' ' : return (TOO_EARLY ? true : quitting ? false : (qidx >= 0));
            case 'Vs': return (TOO_EARLY || pqst?.nvcast < poll.natt/2);
            case 'Vt': return (TOO_EARLY);
            case 'Vx': return (TOO_EARLY);
            default:   return false;
            }
        case 'Oz': return (TOO_EARLY);
        default:   return false;
        }
    }

    function getActionSymbolText() {
        if (!poll) return ['',''];
        const last_quest = (poll && (poll.qactive === poll.pqstlist.length-1));
        const last_round = (pqst && (pqst.qround  === pqst.qroundmax || (pqst.chosen.length-1) >= pqst.nwanted));
        const qr_symbol = (pqst && pqst.qround > 1 ? `Q${poll.qactive+1} Round${pqst.qround}` : `Q${poll.qactive+1}`);

        switch (pstatus) { // current status
        case 'Ax': 
            return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.open", [], poll.pkind)];
        case 'Oo': 
            if (poll.natt >= poll.nmin) {
                return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.start", [], poll.pkind)];
            } else {
                return [``, ``];
            }
        case 'Os':
            switch (qstatus) {
            case ' ' : 
                if (quitting) {
                    return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.final", [], poll.pkind)];
                } else if (poll.qactive_selected >= 0) {
                    return [`Q${poll.qactive_selected+1}`, utils.completeLocaleMapText(localeMap, "monitor.action.quest.vstart", [], poll.pkind)];
                } else {
                    return [``, utils.completeLocaleMapText(localeMap, "monitor.action.quest.vstart", [], poll.pkind)];
                }
            case 'Vs': 
                return [qr_symbol, utils.completeLocaleMapText(localeMap, "monitor.action.quest.vstop", [], poll.pkind)];
            case 'Vt': 
                if (!(utils.getDiffSince(poll.updatedat) > 9000)) return ['', ''];
                return [qr_symbol, utils.completeLocaleMapText(localeMap, "monitor.action.quest.vstop", [], poll.pkind)];
            case 'Vx':
                if (last_round) {
                    let new_qr_symbol = `Q${poll.qactive+1}`;
                    return [new_qr_symbol, utils.completeLocaleMapText(localeMap, "monitor.action.quest.close", [], poll.pkind)];
                } else {
                    let new_qr_symbol = (pqst ? `Q${poll.qactive+1} Round${pqst.qround+1}` : `Q${poll.qactive+1}`);
                    return [new_qr_symbol, utils.completeLocaleMapText(localeMap, "monitor.action.quest.nextr", [], poll.pkind)];
                }
            default: return [``, `invalid qstatus (${qstatus})`];
            }
        case 'Oz': return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.close", [], poll.pkind)];
        default:   return [``, `invalid pstatus (${pstatus})`];
        }
    }

    function executeAction() {
        const last_quest = (poll && (poll.qactive === poll.pqstlist.length-1));
        const last_round = (pqst && (pqst.qround === pqst.qroundmax || (pqst.chosen.length-1) >= pqst.nwanted));

        if      (pstatus === 'Ax') { setPollStatus(poll, setPoll, 'Oo'); }
        else if (pstatus === 'Oo') { setPollStatus(poll, setPoll, 'Os'); } // this may start the only question
        else if (pstatus === 'Os') {
            if      (qstatus === ' ') {
                if   (quitting) setPollStatusDone(poll, setPoll);
                else            setPqstStart(poll, setPoll, (poll.qactive_selected ? poll.qactive_selected : 0));
            } 
            else if (qstatus === 'Vs') { setPqstStatus(poll, setPoll, 'Vt'); }
            else if (qstatus === 'Vt') { /* do nothing */ }
            else if (qstatus === 'Vx') { 
                if (last_round && quitting) setPollStatusDone(poll, setPoll);           // next question
                else if (last_round)        setPqstStatusDone(poll, setPoll); 
                else                        setPqstStart(poll, setPoll, poll.qactive);  // next round
            }
        } 
        else if (pstatus === 'Oz') setOpen4XPoll(true);
        else { console.log("pstatus invalid (" + pstatus + ")"); }
        setVisible(false);
    }

    function setPollStatus(poll, setPoll, new_pstatus) {
        if (simulating) EMWithSimulator.setPollStatus(poll, setPoll, new_pstatus); // this may start the only question 
        else             EMWithPzServer.setPollStatus(poll, setPoll, new_pstatus);
    }
    function setPollStatusDone(poll, setPoll) {
        if (simulating) EMWithSimulator.setPollStatusDone(poll, setPoll);
        else             EMWithPzServer.setPollStatusDone(poll, setPoll);
    }
    function setPqstStart(poll, setPoll, new_qactive) {
        if (simulating) EMWithSimulator.setPqstStart(poll, setPoll, new_qactive);
        else             EMWithPzServer.setPqstStart(poll, setPoll, new_qactive);
    }
    function setPqstStatus(poll, setPoll, new_qstatus) {
        if (simulating) EMWithSimulator.setPqstStatus(poll, setPoll, new_qstatus);
        else             EMWithPzServer.setPqstStatus(poll, setPoll, new_qstatus);
    }
    function setPqstStatusDone(poll, setPoll) {
        if (simulating) EMWithSimulator.setPqstStatusDone(poll, setPoll);
        else             EMWithPzServer.setPqstStatusDone(poll, setPoll);
    }
    function onConfirmToClosePoll() {
        if (simulating) EMWithSimulator.setPollStatusClosed(poll, setPoll);
        else             EMWithPzServer.setPollStatusClosed(poll, setPoll);
    }

    return (
        <>
            <PzButton.EMAction symbol={actionSymbol} text={actionText} size={size}
                disabled={disabled} invisible={!visible || actionText===''} 
                onClick={executeAction} />
            { open4XPoll && 
                <PzDialog.Simple title={localeMap["poller.runpoll.xpoll.title"]} 
                    message={localeMap["poller.runpoll.xpoll.msg"]} 
                    onClose={()=>{setOpen4XPoll(false);}}
                    onButtonNo={()=>{setOpen4XPoll(false);}}
                    onButtonYes={()=>{onConfirmToClosePoll(); setOpen4XPoll(false);}} />
            }
        </>
    );

}

function EMButtonForPollerHelpMenu({poll, setPoll, pqst, pale=false, size='xl'}) {
    const location = useLocation();
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const pstatus = (poll ? poll.pstatus : ' ');
    const qstatus = (pqst ? pqst.qstatus : ' ');
    const [open4SList, setOpen4SList] = React.useState(false);
    const [open4VList, setOpen4VList] = React.useState(false);
    const [open4Entrance, setOpen4Entrance] = React.useState(false);
    const [open4PClose, setOpen4PClose] = React.useState(false);
    const [open4QReset, setOpen4QReset] = React.useState(false);
    const simulating = (location.pathname.startsWith("/y/"));
    // console.log(poll.qactive, poll.qactive_selected);

    function onConfirmToEarlyClosePoll() {
        if (simulating) EMWithSimulator.setPollStatusDone(poll, setPoll, true); // closing early
        else             EMWithPzServer.setPollStatusDone(poll, setPoll, true); // closing early
    }

    function onConfirmToResetPqst() {
        if (simulating) EMWithSimulator.setPollPqstReset(poll, setPoll);
        else             EMWithPzServer.setPollPqstReset(poll, setPoll);
    }

    const menu_items = [
        { text:localeMap["monitor.help.ypoll"],    callback:()=>{setOpen4PClose(true);}, hidden:(poll.pstatus != 'Os' || poll.qactive >= 0) },
        { text:localeMap["monitor.help.zpqst"],    callback:()=>{setOpen4QReset(true);}, hidden:(poll.pstatus != 'Os' || poll.qactive < 0) },
        { text:localeMap["monitor.help.stafflist"], callback:()=>{setOpen4SList(true);},  hidden:(poll.nstaff === 0) },
        { text:localeMap["monitor.help.voterlist"], callback:()=>{setOpen4VList(true);} },
        { text:localeMap["monitor.help.qrcode"],    callback:()=>{setOpen4Entrance(true);}, hidden:(pstatus>='Oz') },
        { text:localeMap["monitor.help.help2checkin"],  callback:()=>{}, hidden:(pstatus>='Oz') },
        { text:localeMap["monitor.help.help2vote"],     callback:()=>{}, hidden:(pstatus!=='Os') },
    ];

    return (
        <>
            <PzButton.EMMenu size={size} Icon={IconLBuoy} 
                className={pstatus === 'Ax' || pstatus.startsWith('O') ? '' : 'hidden'}
                items={menu_items}  ha='left'  pale={pale} />

            {open4Entrance && 
                <EntranceForVoters.Dialog poll={poll} onClose={()=>{setOpen4Entrance(false);}} />
            }
            {open4SList && 
                <EMModals.StaffList poll={poll} onClose={()=>{setOpen4SList(false);}} xclose={true} />
            }
            {open4VList && 
                <EMModals.VoterList poll={poll} onClose={()=>{setOpen4VList(false);}} xclose={true} />
            }
            { open4PClose && 
                <PzDialog.Simple title={localeMap["poller.runpoll.ypoll.title"]} 
                    message={localeMap["poller.runpoll.ypoll.msg"]} 
                    onClose={()=>{setOpen4PClose(false);}}
                    onButtonNo={()=>{setOpen4PClose(false);}}
                    onButtonYes={()=>{onConfirmToEarlyClosePoll(); setOpen4PClose(false);}} />
            }
            { open4QReset && 
                <PzDialog.Simple title={localeMap["poller.runpoll.zpqst.title"]} 
                    message={localeMap["poller.runpoll.zpqst.msg"]} 
                    onClose={()=>{setOpen4QReset(false);}}
                    onButtonNo={()=>{setOpen4QReset(false);}}
                    onButtonYes={()=>{onConfirmToResetPqst(); setOpen4QReset(false);}} />
            }

        </>
    );

}

function EMButtonForPollerRsltMenu({poll, size='xl'}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    return (
        <>
            <PzButton.EMMenu size={size} Icon={IconVResult} pale={true} 
                className={poll.pstatus === 'Oz' ? '' : 'hidden'}
                items={[
                    { text:localeMap["monitor.action.copyresult"], callback:()=>{console.log('copied')}, disabled:true },
                ]} />
        </>
    );
}

