import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../../config/appConfig";
import PzArea from "../../common/PzArea";
import PzTable, {PztShortNavigate} from "../../common/PzTable";
import UserSplash from "../UserSplash";

export default function PzAdminUserList() {
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const [pgOpts, setPgOpts] = React.useState({page:0,pageSize:10});
    const [pgData, setPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);

    const fetchUserList = (opts)=>{
        appConfig.getAPI().post("/api/pza/user/list", opts)
        .then((response)=>{
            // console.log("PzAPI 'pza/user/list' fetched : ", response.data);
            let pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            let pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            setPgData(pgdata);
        })
        .catch((error)=>{
            console.log("PzAPI 'pza/user/list' error : ", error);
        })
        .finally(()=>{});
    };

    React.useEffect(() => {
        if (!uauth?.ispza) navigate("/");
        fetchUserList({params:{page:0,pageSize:10}, sorter:null});
    }, []);

    if (!uauth || !uauth.ispza) {
        return (<UserSplash msg={"Unauthorized access"} />);
    }  

    return (
        <PzArea.Container>
            <PzArea.AdminBoard >

                <h2>{"PzA - Users Management"}</h2>

                <PzTable 
                    data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchUserList}
                    rowKey="uguid" searchable={true} configurable={true}
                    columns={[
                        { title: "UGuid", key: "uguid", sorter: false,
                            render: ({uguid}) => { return (<PztShortNavigate value={uguid} stt={8} end={32} to="../userinfo" state={{uguid}} />); } },
                        { title: "Name", key: "uname", sorter: false },
                        { title: "Email", key: "uemail", sorter: false },
                        { title: "CreatedAt", key: "createdat", sorter: false, datetime:'YYYY/MM/DD HH:mm'},
                        { title: "LastLogin", key: "lastlogin", sorter: false, datetime:'YYYY/MM/DD HH:mm'},
                        { title: "BlockUntil",key: "blockuntil",sorter: false, datetime:'YYYY/MM/DD HH:mm', hidden:true },
                        { title: "ErrCnt",    key: "errcnt",    sorter: false, hidden:true },
                        { title: "LastIP",    key: "lastip",    sorter: false, hidden:true },
                        { title: "Country",   key: "country",   sorter: false, hidden:true },
                        { title: "Locale",    key: "locale",    sorter: false, hidden:true },
                        { title: "Timezone",  key: "timezone",  sorter: false, hidden:true },
                    ]}
                />

            </PzArea.AdminBoard>
        </PzArea.Container>
    );
}
