import React from 'react';
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { motion } from "framer-motion"

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "../common/PzBasicModal"
import PzButton from "../common/PzButton"

const TrialAsVoter = {
    Dialog:     TrialAsVoterDialog,
    Button:     TrialAsVoterButton,
};

export default TrialAsVoter;


function TrialAsVoterDialog({poll, onClose=()=>{}}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [cookies, removeCookie] = useCookies(['vauth']);
    const ttext = localeMap['entrance.trial.asvoter.title'];
    const title = utils.completeMessageText((ttext ? ttext : ""), [poll?.ptitle]);
    const [step, setStep] = React.useState(0);
    const CONCEPT_SRC = appConfig.getBaseUrlForAPI() + '/static/images/c_voters_checkin.png';
    const QRCODE_SRC  = `${appConfig.getBaseUrlForAPI()}/api/entrance/event?p=${poll?.pguid}&m=${'yvoter'}`;
    const variants = {
        small: { opacity: 0, width:'40%', top:35, right:70 },
        large: { opacity: 1, width:'100%', top:0, right:0 },
    };
    const [variant, setVariant] = React.useState('small');

    function onClickForQR() {
        if (step === 0) {
            setStep(1); 
            setVariant('large');
        } else {
            removeCookie("vauth", null);
            const url = appConfig.getBaseUrlForAPI() + '/y/vote?p=' + poll?.pguid;
            const win = window.open(url.replace(":80", ":3000"), '_blank');
            if (win != null) win.focus();
            if (typeof onClose === 'function') onClose();
        }
    }

    React.useEffect(() => {
        setTimeout(()=>{ setStep(1); setVariant('large'); }, 2000); // wait 2 seconds
    }, []);


    return (
        <PzBasicModal onClose={onClose}>
            <div className="w-full min-w-[24rem] max-w-[32rem] flex flex-col justify-start items-center bg-[#4182d2] p-10 gap-10" >
                <div className="w-full flex flex-col justify-start items-center gap-5">
                    <div className="w-full flex flex-col justify-start items-center gap-1 text-xl  text-white">
                        {title.split("\n").map((part,idx)=>{return (<h2 key={idx}>{part}</h2>);})}
                    </div>
                    <label className={"text-lg text-white font-bold" + (step===0 ? "invisible" : "")}>
                        {localeMap["entrance.qrcode.instruct.user"]}
                    </label>
                </div>
                <div className="w-full aspect-square border border-white rounded-[3rem] overflow-clip relative">
                        <motion.div className="w-full absolute" 
                            initial={{opacity:0,scale:0}} animate={{opacity:1,scale:1}} transition={{ duration: 0.1 }} >
                            <img src={CONCEPT_SRC} className="object-contain object-center" alt="" 
                                onClick={()=>{setStep(1); setVariant('large');}} />
                        </motion.div>
                        <motion.div 
                            className="w-full absolute top-0 rounded-[2rem] ring-slate-200 ring-inset ring-1 pz-qr-shadow"
                            initial='small' animate={variant} variants={variants} transition={{ duration: 0.3 }} >
                            <img src={QRCODE_SRC} className="object-contain object-center" alt="" 
                                onClick={onClickForQR} />
                        </motion.div>
                </div>
                <div className="w-full text-center text-slate-400">
                    <div className="text-xl text-white">Hosted by <em className="font-bold">Pollerz.net</em></div>
                </div>
            </div>
        </PzBasicModal>
    )

}

function TrialAsVoterButton({poll, size='md', className='', disabled=false}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <PzButton.Std 
                text={utils.completeLocaleMapText(localeMap, "home.try.asvoter", [], poll.pkind)} 
                size={size} onClick={()=>{setOpen(true);}} disabled={disabled} className={className} />
            { open &&
                <TrialAsVoterDialog poll={poll} onClose={()=>setOpen(false)} />
            }
        </>
    );
}

