import { createSlice } from '@reduxjs/toolkit'

export const voterConfigSlice = createSlice({
    name: 'vcfg',
    initialState: {
        vrec: { pguid:'', vid:'', vname:'', history:[] },
    },
    reducers: {
        setVRec: (state, action) => {
            if (!action.payload) return;
            state.vrec = action.payload;
        },
    }
})

function pushSelection(vrec, qguid, qround, selection) {
    if (!vrec) return vrec;
    let new_selection = [ ...selection ];
    let new_history = (Array.isArray(vrec.history) ? [ ...vrec.history ] : []);
    let found = false;
    for (let i = 0; i < new_history.length; i++) {
        if (new_history[i].qguid === qguid && new_history[i].qround === qround) {
            new_history[i].selection = new_selection;
            found = true;
            break;
        }
    } 
    if (!found) {
        new_history = [ ...new_history, { qguid: qguid, qround: qround, selection: new_selection } ];
    }
    return { ...vrec, history: new_history };
}

function popSelection(vrec, qguid, qround) {
    if (!vrec || !Array.isArray(vrec.history)) return null;
    for (let i = 0; i < vrec.history.length; i++) {
        let hdata = vrec.history[i];
        if (hdata.qguid === qguid && hdata.qround === qround) {
            return (Array.isArray(hdata.selection) ? [ ...hdata.selection ] : []);
        }
    } 
    return null;
}

export const vutils = { 
    ...voterConfigSlice.actions,

    pushSelection:  pushSelection,
    popSelection:   popSelection,
};
