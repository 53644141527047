import React from "react";
import { useSelector } from "react-redux";
import { useInterval } from "react-use";
import dayjs from "dayjs";

import { utils } from "../config/userConfig";
import PzPop from "../common/PzPop";
import PzTime from "../common/PzTime";
import { ReactComponent as IconTimeClock } from "../resource/icons/pz-time-clock.svg";
import { ReactComponent as IconTimeHourglass } from "../resource/icons/pz-time-hourglass.svg";
import { ReactComponent as IconTimeStopwatch } from "../resource/icons/pz-time-stopwatch.svg";
import { ReactComponent as IconDoorOpen } from "../resource/icons/pz-door-open.svg";
import { ReactComponent as IconDoorClose } from "../resource/icons/pz-door-close.svg";
import { ReactComponent as IconStepStart } from "../resource/icons/pz-step-start.svg";
import { ReactComponent as IconStepStop } from "../resource/icons/pz-step-stop.svg";

const EventTimer = {
    getEventDateAndTime:    getEventDateAndTime,
    isOpenable:             isOpenable,
    WallClock:              EventTimerWallClock,
    CardClock:              EventTimerCardClock,
};
export default EventTimer;


function getEventDateAndTime(poll, utzone) {
    let ewhen = dayjs(poll.pwhen ? poll.pwhen : poll.openedat).tz(utzone); // it might be NOW (dayjs(''))
    let edate = ewhen.format("YYYY/MM/DD");
    let etime = ewhen.format("h:mm A");
    // let edate = (poll.locale === 'ko' ? ewhen.format("YYYY년 MM월 DD일") : ewhen.format("MMM D, YYYY"));
    // let etime = (poll.locale === 'ko' ? ewhen.format("h:mm A") : ewhen.format("h:mm A"));
    return [edate, etime];
}

function isOpenable(poll) {
    if (!poll || poll.pstatus !== 'Ax') return false;
    if (!poll.pwhen) return true;
    let diff = dayjs(poll.pwhen).diff(dayjs(), 'minute');
    return (diff <= +60);
}

function EventTimerWallClock({poll, size='xl', className=''}) {
    const BORDER = " border border-slate-800 bg-slate-200 rounded-lg px-6 py-3";

    return (
        <PzPop.Popover 
            base={
                <EventTimerCardClock poll={poll} size={size} className={BORDER + " " + className} />
            }
            popover={ null } 
            ha="center" offset="10px" upward={true} />
    );
}

function EventTimerCardClock({poll, size='xl', className=''}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const utzone = useSelector((state)=>state.ucfg.timezone); // timezone from user settings
    const [remaining, setRemaining] = React.useState('');
    const [elapsed, setElapsed] = React.useState('');

    useInterval(() => {
        if (!poll) return;
        if (poll.pstatus < 'Oo') {
            let deadline  = (poll?.pwhen ? dayjs(poll.pwhen) : dayjs(poll.createdat).add(1, 'hour'));
            let newremain = PzTime.getTimeRemainingInString(deadline);
            setRemaining( newremain );
            if (remaining === '0:00:00' && poll.pwhen) {
                let newelapsed = PzTime.getElapsedTimeInString(dayjs(poll.pwhen));
                setElapsed( newelapsed );
            } else {
                setElapsed('');
            }
        } else if (poll.pstatus === 'Oo') {
            if (poll.pwhen && dayjs(poll.openedat).isBefore(dayjs(poll.pwhen))) {
                let newremain = PzTime.getTimeRemainingInString(dayjs(poll.pwhen));
                setRemaining( newremain );
                setElapsed('');
            } else {
                let newelapsed = PzTime.getElapsedTimeInString(dayjs(poll.openedat));
                setRemaining('');
                setElapsed( newelapsed );
            }
        } else if (poll.pstatus >= 'Os' && poll.pstatus < 'Oz') {
            let newelapsed = PzTime.getElapsedTimeInString(dayjs(poll.startedat));
            setRemaining('');
            setElapsed( newelapsed );
        } 
    }, 1000);


    let TEXTSIZE = 'text-'+size, iconsize = " ", ssize = size, sssize = size, px = " ", py = " ", gap = " gap-0 ";
    if      (size === '3xl') { iconsize = "1.87rem "; ssize="xl"; sssize="lg"; px = " px-4 "; py = " py-4 "; gap=" gap-3 "; }
    else if (size === '2xl') { iconsize = "1.50rem "; ssize="xl"; sssize="lg"; px = " px-4 "; py = " py-4 "; gap=" gap-3 "; }
    else if (size ===  'xl') { iconsize = "1.25rem "; sssize="md"; px = " px-4 "; py = " py-4 "; gap=" gap-3 "; }
    else if (size ===  'lg') { iconsize = "1.12rem "; ssize="md"; sssize="sm"; px = " px-3 "; py = " py-3 "; gap=" gap-2 "; }
    else if (size ===  'md') { iconsize = "1.00rem "; ssize="sm"; sssize="xs"; px = " px-2 "; py = " py-2 "; gap=" gap-2 "; }
    else if (size ===  'sm') { iconsize = "0.87rem "; ssize="xs"; sssize="xs"; px = " px-1 "; py = " py-1 "; gap=" gap-1 "; }
    else                     { iconsize = "0.87rem "; ssize="xs"; sssize="xs"; px = " px-1 "; py = " py-1 "; gap=" gap-1 "; }

    return (
        <div className={"w-fit flex flex-col justify-start items-stretch gap-2 " + className} >
            <div className={"grid grid-cols-[auto_auto_auto] justify-center items-center gap-x-4 gap-y-2 cursor-pointer "} >
                { poll.pstatus === 'Ax' ?
                    ( (remaining === '0:00:00' && poll.pwhen) ? 
                        <>
                        <IconTimeClock width={iconsize} height={iconsize} />
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.pwhen, 'MM/DD', utzone)}</label>
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.pwhen, 'h:mm:ss A', utzone)}</label>
                        <IconTimeStopwatch width={iconsize} height={iconsize} />
                        <label>&nbsp;</label>
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{elapsed} &nbsp;&nbsp;</label>
                        </>
                    : poll.pwhen ?
                        <>
                        <IconTimeClock width={iconsize} height={iconsize} />
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.pwhen, 'MM/DD', utzone)}</label>
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.pwhen, 'h:mm:ss A', utzone)}</label>
                        <IconTimeHourglass width={iconsize} height={iconsize} />
                        <label>&nbsp;</label>
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{remaining} &nbsp;&nbsp;</label>
                        </>
                    :
                        <>
                        <IconTimeHourglass width={iconsize} height={iconsize} />
                        <label>&nbsp;</label>
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{remaining} &nbsp;&nbsp;</label>
                        </>
                    )
                : poll.pstatus === 'Oo' ?
                    ( poll.pwhen && remaining ?
                        <>
                        <IconDoorOpen width={iconsize} height={iconsize} />
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.openedat, 'MM/DD', utzone)}</label>
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.openedat, 'h:mm:ss A', utzone)}</label>
                        <IconTimeClock width={iconsize} height={iconsize} />
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.pwhen, 'MM/DD', utzone)}</label>
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.pwhen, 'h:mm:ss A', utzone)}</label>
                        <IconTimeHourglass width={iconsize} height={iconsize} />
                        <label>&nbsp;</label>
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{remaining} &nbsp;&nbsp;</label>
                        </>
                    :
                        <>
                        { (poll.pwhen && poll.pwhen < poll.openedat) &&
                            <>
                            <IconTimeClock width={iconsize} height={iconsize} />
                            <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.pwhen, 'MM/DD', utzone)}</label>
                            <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.pwhen, 'h:mm:ss A', utzone)}</label>
                            </>
                        }
                        <IconDoorOpen width={iconsize} height={iconsize} />
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.openedat, 'MM/DD', utzone)}</label>
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.openedat, 'h:mm:ss A', utzone)}</label>
                        <IconTimeStopwatch width={iconsize} height={iconsize} />
                        <label>&nbsp;</label>
                        <label className={TEXTSIZE+" font-mono font-bold text-right"}>{elapsed} &nbsp;&nbsp;</label>
                        </>
                    )
                : (poll.pstatus >= 'Os' && poll.pstatus < 'Oz') ?
                    <>
                    <IconDoorOpen width={iconsize} height={iconsize} />
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.openedat, 'MM/DD', utzone)}</label>
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.openedat, 'h:mm:ss A', utzone)}</label>
                    <IconStepStart width={iconsize} height={iconsize} />
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.startedat, 'MM/DD', utzone)}</label>
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.startedat, 'h:mm:ss A', utzone)}</label>
                    <IconTimeStopwatch width={iconsize} height={iconsize} />
                    <label>&nbsp;</label>
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{elapsed} &nbsp;&nbsp;</label>
                    </>
                :
                    <>
                    <IconDoorOpen width={iconsize} height={iconsize} />
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.openedat, 'MM/DD', utzone)}</label>
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.openedat, 'h:mm:ss A', utzone)}</label>
                    <IconStepStart width={iconsize} height={iconsize} />
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.startedat, 'MM/DD', utzone)}</label>
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.startedat, 'h:mm:ss A', utzone)}</label>
                    <IconStepStop width={iconsize} height={iconsize} />
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.stoppedat, 'MM/DD', utzone)}</label>
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.stoppedat, 'h:mm:ss A', utzone)}</label>
                    <IconDoorClose width={iconsize} height={iconsize} />
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.closedat, 'MM/DD', utzone)}</label>
                    <label className={TEXTSIZE+" font-mono font-bold text-right"}>{utils.getTimeInUserTimezone(poll.closedat, 'h:mm:ss A', utzone)}</label>
                    </>
            }
            </div>
            { (poll && poll.pwhen === '' && poll.pstatus < 'Oo') ?
                <label className={'text-'+ssize}>{localeMap["poll01.pwhen.rightnow.constraint"]}</label>
            : (remaining === '0:00:00' && elapsed !== '') ?
                <label className={'text-'+ssize}>{localeMap["poller.runpoll.deadline.broken"]}</label>
            :
                <></>
            }
        </div>
    );
}

