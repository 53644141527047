import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useInterval } from "react-use";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PzDialog from "../../common/PzDialog";
import EMAreaHeader from "../../eventmonitor/EMAreaHeader";
import EMAreaBodyAsVoter from "../../eventmonitor/EMAreaBodyAsVoter";
import EMButtonForVoter from "../../eventmonitor/EMButtonForVoter";
import EMWithPzServer  from "../../eventmonitor/EMWithPzServer";
import EMWithSimulator from "../../eventmonitor/EMWithSimulator";
import EMSplash from "../../eventmonitor/EMSplash";
import { ReactComponent as IconToolMagnifier } from "../../resource/icons/pz-tool-magnifier.svg";


export default function TryVote() {
    const [poll, setPoll] = React.useState(null);
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const [cookies, removeCookie] = useCookies(['vauth']);
    const [vauth, setVAuth] = React.useState(cookies && cookies.vauth ? jwtDecode(cookies.vauth) : null);
    const location = useLocation();
    const navigate = useNavigate();
    const [qparams] = useSearchParams();
    const pguid = qparams.get("p");
    const pqst = (poll?.qactive >= 0 && poll?.pqstlist ? poll?.pqstlist[poll?.qactive] : null);
    const [svcStatus, setSvcStatus] = React.useState('');
    const [paused, setPaused] = React.useState(false);
    const [magnified, setMagnified] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    // 
    const [current, setCurrent] = React.useState({qguid:'', qround:0});
    const [selection, setSelection] = React.useState([]);
    const [voted, setVoted] = React.useState(false);

    if (pqst) {  
        // reset 'selection' and 'voted', if it's new pqst or qround
        if (current.qguid != pqst.qguid || current.qround !== pqst.qround) {    // if it's new round
            // console.log("set current: ");
            setCurrent({qguid:pqst.qguid, qround:pqst.qround});     // remember current qguid and qround
            setSelection([]);   // we have to reset, if it's new pqst or qround
            setVoted(false);    // we have to reset, if it's new pqst or qround
        }
    }

    React.useEffect(() => {
        // removeCookie("vauth", null);
        let checkedin = (vauth != null);
        if (vauth && vauth.pguid !== pguid) {
            removeCookie('vauth', null);
            setVAuth(null);
            checkedin = false;
        }
        // dispatch(vutils.setChoices([]));
        if (pguid?.length !== 48) { 
            setSvcStatus('badaddr')
        } else if (!checkedin) { 
            console.log("/y/vote navigate to /y/vcheckin");
            navigate("/y/vcheckin?p=" + pguid);
        }
    }, []);
    
    useInterval(() => {
        if (!paused) updateEventInfo();
    }, 1000);

    function updateEventInfo() {
        if (svcStatus === 'badaddr' || svcStatus === 'checkin') return;
        else if (!poll) EMWithPzServer.updateEventInfo(pguid, poll, setPoll2, '/api/xpv/event', 'V',  setSvcStatus);
        else           EMWithSimulator.updateEventInfo(pguid, poll, setPoll2, true, setSvcStatus);
    }

    function setPoll2(new_poll) {
        // This function is called on poll update is SUCCESS & CHANGED 
        if (!poll) {    // first time
            setPoll({ ...new_poll, pstatus:'Oo', nmax:60, nmax:120, xrole:'V' });
        } else {
            setPoll(new_poll);
        }
        const qactive = (new_poll ? new_poll.qactive : null);
        const qguid  = (qactive >= 0 && new_poll?.pqstlist ? new_poll.pqstlist[qactive].qguid : '')
        const qround = (qactive >= 0 && new_poll?.pqstlist ? new_poll?.pqstlist[qactive].qround : null);
        if (qactive >= 0 && (qguid !== current.qguid || qround !== current.qround)) {
            // the current question & round has been changed
            setCurrent({qguid: qguid, qround: qround});
            setSelection([]);
            setVoted(false);
        }
    }

    function callToCastVote() {
        if (!poll) { console.log("poll invalid"); return; }
        if (!pqst) { console.log("pqst invalid"); return; }
        if (pqst.qround <= 0) { console.log("qround invalid"); return; }
        const simulate = (location.pathname.startsWith("/y/"));
        const onSuccess = ()=>{
            setErrMsg('');
            setLoading(false);
            setVoted(true);
        };
        const onError = (respmsg, respval)=>{
            setLoading(false);
            if (respmsg && respmsg.includes('duplicated')) {
                setErrMsg(localeMap["voter.vcast.err.duplicate"]);
            } else {
                setErrMsg(respmsg);
            }
        };
        setErrMsg('');
        setLoading(true);
        if (simulate) EMWithSimulator.castVoteAsVoter(vauth.vid, poll, setPoll, pqst, pqst.qround, selection, onSuccess, onError);
        else           EMWithPzServer.castVoteAsVoter(vauth.vid, poll, setPoll, pqst, pqst.qround, selection, onSuccess, onError);
    }

    if (pguid === 'THANKYOU') {
        return (<EMSplash msg={localeMap["monitor.splash.thankyou"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'badaddr') {
        return (<EMSplash msg={localeMap["monitor.splash.badaddr"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'checkin') {
        return (<EMSplash msg={localeMap["monitor.splash.checkin"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'closed') {
        return (<EMSplash msg={localeMap["monitor.splash.closed"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'loading') {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    } else if (svcStatus  != '') {
        return (<EMSplash msg={svcStatus} />);
    } else if (!poll) {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    }

    let  SIZE = (magnified ? '4xl' : '3xl');
    let SSIZE = utils.getSizeRelative(SIZE, -1);

    return (
        <PzArea.Container>
            <PzArea.MwhBoard className="relative  px-0 py-10 select-none bg-white gap-10">

                <div className="w-full px-5 ">
                    <EMAreaHeader poll={poll} asVoter={true} size={SIZE} />
                </div>
                <div className="w-full px-5 h-[calc(100dvh-8rem)] flex flex-col justify-start gap-10 overflow-y-scroll">
                    <EMAreaBodyAsVoter poll={poll} qidx={poll.qactive} pqst={pqst} size={SIZE} 
                        selection={selection} setSelection={setSelection} votedAlready={voted} />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>

                {/* Magnification Button */}
                <div className="absolute bottom-[2.5rem] left-[2rem] ">
                    <PzButton.EMToggle Icon={IconToolMagnifier} size={'2xl'} toggle={magnified} setToggle={setMagnified} className="pz-fab-shadow" />
                </div>

                {/* VOTE ACTION Button */}
                <div className="absolute bottom-[2.5rem] left-1/2 -translate-x-1/2 ">
                    <EMButtonForVoter.Action poll={poll} setPoll={setPoll} pqst={pqst} size={'2xl'}
                        selection={selection} callToCastVote={callToCastVote} votedAlready={voted} />
                </div>

                {/* RESULT MENU Button */}
                <div className="absolute bottom-[2.5rem] right-[2rem] ">
                    <EMButtonForVoter.RsltMenu  poll={poll} setPoll={setPoll} pqst={pqst} size={'2xl'} />
                </div>

                {/* 'PAUSE' button */}
                <div className="absolute top-0 left-0  w-auto ">
                    <div className="hover:cursor-pointer" onClick={()=>{setPaused(!paused);}}>&emsp;&emsp;</div>
                </div>
                {/* simulation notice */}
                <div className="absolute bottom-0 left-0  w-full text-center" >
                    <h5 className="text-slate-400">{localeMap["entrance.simulated"]}</h5>
                </div>

            </PzArea.MwhBoard >
        </PzArea.Container>
    );

}

