import React from "react";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzProgress from "../common/PzProgress";
import EMComQstList from "./EMComQstList";
import EMComQstHeader from "./EMComQstHeader";
import EMComQstAnswers from "./EMComQstAnswers";
import EMEventPoster from "./EMEventPoster";
import PollPqstGoal from "../event/PollPqstGoal";
import PollPqstResult from "../event/PollPqstResult";

export default function EMAreaBodyAsPoller({poll, qidx, pqst, setPoll, setSvcStatus=()=>{}, size='xl'}) {
    const pstatus = (poll ? poll.pstatus : '');
    const qstatus = (pqst ? pqst.qstatus : '');
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const ranswers = (poll && pqst && pstatus[0] === 'O' ? PollPqstResult.getRoundAnswers(pqst, pqst.qround) : []);
    const tanswers = (poll && pqst && pqst.qstatus >= 'Vx' ? PollPqstResult.getTopAnswers(ranswers, 0) : []);
    const [qFolded, setQFolded] = React.useState(false);
    const [topView, setTopView] = React.useState(true);
    const [selection, setSelection] = React.useState([]);
    const first = isFirstTime();

    function isFirstTime() {
        if (!poll) return false;
        let closed_pqst = 0;
        for (let i = 0; i < poll?.pqstlist?.length; i++) {
            if (poll.pqstlist[i].qstatus >= 'Vx') closed_pqst++;
        }
        return (closed_pqst === 0);
    }

    if (!poll) {
        return (<h3>poll is null</h3>);
    }
    
    const TEXTSIZE = 'text-' + size;
    const SSIZE = utils.getSizeRelative(size,-1), STEXTSIZE = 'text-' + utils.getSizeRelative(size,-1);
    // console.log(SSIZE);

    return (
        <div className="w-full">
            { pstatus === 'Ax' ?    // 
                <div className="w-full h-[60dvh] flex flex-col justify-center items-center">
                    <EMEventPoster.ForPoller intro={poll} />
                </div>
            : pstatus === 'Oo' ?    // open for participants
                <div className="w-full pt-20 flex flex-col justify-start items-center">
                    <div className="w-full flex flex-col justify-start items-center gap-5">
                        <label className={TEXTSIZE+" font-bold"}>
                            {localeMap["monitor.progress.checkin"]}
                        </label>
                        <PzProgress className="w-[20rem]" size={size} bold={true} count={poll.natt} total={poll.nmax} />
                        { poll.natt < poll.nmin ? 
                            <label className={STEXTSIZE+" font-bold mt-10 "}>
                                {localeMap["monitor.waiting.event.checkin"]}
                            </label>
                        :
                            <label className={STEXTSIZE+" font-bold mt-10 "}>
                                {utils.completeMessageText(localeMap["monitor.progress.checked"], [poll.nmin])}
                            </label>
                        }
                    </div>
                </div>
            : pstatus === 'Os' ?    // 
                ( !pqst ?
                    <div className="w-full flex flex-col justify-start items-stretch gap-5">
                        <label className={TEXTSIZE+" font-bold"}>
                            {first ? localeMap["monitor.quest.selectfirst"] : localeMap["monitor.quest.selectnext"]}
                        </label>
                        <EMComQstList poll={poll} setPoll={setPoll} size={SSIZE} selectable={true} deselectable={!first} />
                    </div>
                : qstatus === 'Vs' ?
                    <div className="w-full flex flex-col justify-start items-stretch gap-5">
                        <label className={TEXTSIZE+" font-bold"}>{localeMap["monitor.quest.voting.asked"]}</label>
                        <div className="w-full ring-slate-800 ring-inset ring-1 rounded-2xl flex flex-col justify-start items-stretch gap-4">  
                            <EMComQstHeader  poll={poll} pqst={pqst} size={size} bold={true} className="pt-4 px-4" 
                                folded={qFolded} setFolded={setQFolded} />
                            { !qFolded && 
                            <>
                                <div className="w-full h-[1px] bg-slate-200"></div>
                                <EMComQstAnswers pqst={pqst} answers={ranswers} size={SSIZE} preview={true} className="px-8" 
                                    selection={selection} setSelection={setSelection} />
                            </>
                            }
                            <div className="w-full h-[1px] bg-slate-800"></div>
                            <PollPqstGoal.Footer poll={poll} qidx={qidx} ranswers={ranswers} size={SSIZE} className="pb-4 px-4" initFolded={true} />
                        </div>
                        <div className="w-full pt-2 flex flex-col justify-center items-center gap-1">
                            <label className={STEXTSIZE + " font-bold"}>{localeMap["monitor.progress.voting"]}</label>
                            <PzProgress className="w-[50%]" size="xl" bold={true} count={pqst.nvcast} total={poll.natt} />    
                        </div>
                    </div>
                : qstatus === 'Vt' || qstatus === 'Vx' ?
                    <div className="w-full flex flex-col justify-start items-stretch gap-5">
                        <label className="text-3xl font-bold">
                            { qstatus === 'Vt' ? localeMap["monitor.quest.tally.count"] : localeMap["monitor.quest.tally.result"]}
                        </label>
                        <div className="w-full ring-slate-800 ring-inset ring-1 rounded-2xl flex flex-col justify-start items-stretch gap-4">  
                            <EMComQstHeader poll={poll} pqst={pqst} size={SSIZE} bold={true} className="pt-4 px-4" 
                                folded={qFolded} setFolded={setQFolded} topView={topView} setTopView={setTopView} topN={tanswers.length} />
                            <div className="w-full h-[1px] bg-slate-200"></div>
                            <PollPqstResult.RRank pqst={pqst} qround={pqst.qround} size={SSIZE} className="px-10" topView={topView} />
                            <div className="w-full h-[1px] bg-slate-800"></div>
                            <PollPqstGoal.Footer poll={poll} qidx={qidx} ranswers={ranswers} size={SSIZE} className="pb-4 px-4" initFolded={true} />
                        </div>
                    </div>
                : 
                    <></>
                    // <label className={TEXTSIZE+" "}>invalid status &emsp; pstatus={pstatus} &emsp; qstatus={qstatus}</label>
                )
            : pstatus >= 'Oz' && pstatus  <= 'Xc' ?
                <div className="w-full flex flex-col justify-start items-stretch gap-8">
                    <label className={TEXTSIZE+" w-full font-bold text-center opacity-50"}>
                        {localeMap["monitor.result.title"]}
                    </label>
                    <PollPqstResult.List poll={poll} size={size} initSelected={poll.pqstlist[0].qguid} />
                </div>
            : 
                <div>&nbsp;</div>
            }
        </div>
    );
}

