import React from "react";
import { useNavigate } from "react-router-dom";

export default function AzButtonToNavigate({ label, button, destination='/', onClick=undefined,
    withNewTab=false, funnel=null, wrapperStyle="", style="w-fit text-blue-600 hover:underline",
    }) {
    const navigate = useNavigate();

    const onClickToButton = ()=>{
        if (typeof onClick === 'function') {
            onClick();
        } else if (withNewTab) {
            const win = window.open(destination, '_blank');
            if (win != null) win.focus();
        } else if (funnel && typeof funnel === 'string') {
            navigate(destination, { state: { funnel: funnel } });
        } else {
            navigate(destination);
        }
    };

    return (
        <div className="text-lg flex flex-row justify-start items-center gap-2">
            <label className="text-md">{label}</label>
            <button type="button" onClick={onClickToButton} >
                <label className="text-md text-blue-600 hover:underline">{button}</label>
            </button>
        </div>
    );
}
