import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useInterval } from "react-use";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzArea from "../../common/PzArea";
import EMAreaHeader from "../../eventmonitor/EMAreaHeader";
import EMAreaBodyAsPoller from "../../eventmonitor/EMAreaBodyAsPoller";
import EMButtonForStaff from "../../eventmonitor/EMButtonForStaff";
import EMWithPzServer from "../../eventmonitor/EMWithPzServer";
import EMSplash from "../../eventmonitor/EMSplash";

export default function DoSupport() {
    const [cookies, removeCookie] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = utils.selectMapByLocale(upoll?.locale);
    const pqst = (upoll?.qactive >= 0 && upoll.pqstlist ? upoll.pqstlist[upoll?.qactive] : null);
    const [qparams] = useSearchParams();
    const pguid = qparams.get("p");
    const [errMsg, setErrMsg] = React.useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const query = utils.useQuery();
    const dispatch = useDispatch();
    // console.log(upoll);

    React.useEffect(() => {
        if (!uauth) {
            let pguid = (upoll ? upoll.pguid : query.get("p"))
            navigate("/auth/login", {state:{referer:location.pathname, pguid:pguid}});
        }
    }, [cookies]);

    useInterval(() => {
        if (!upoll || !setPoll2 || errMsg != '') return;
        if (upoll.xrole !== 'M' && upoll.xrole !== 'S') return;
        // console.log("/api/s/event  xrole:", upoll.xrole);
        EMWithPzServer.updateEventInfo(upoll.pguid, upoll, setPoll2, '/api/s/event', upoll.xrole, setErrMsg);
    }, 1000);

    function setPoll2(new_poll) {
        dispatch(setUPoll(new_poll));       // update the upoll in Redux storage
    }

    if (pguid === 'THANKYOU') {
        return (<EMSplash msg={localeMap["monitor.splash.thankyou"]} gohome={true} xclose={true} />);
    } else if (errMsg === 'badaddr') {
        return (<EMSplash msg={localeMap["monitor.splash.badaddr"]} gohome={true} xclose={true} />);
    } else if (errMsg === 'checkin') {
        return (<EMSplash msg={localeMap["monitor.splash.checkin"]} gohome={true} xclose={true} />);
    } else if (errMsg === 'closed') {
        return (<EMSplash msg={localeMap["monitor.splash.closed"]} gohome={true} xclose={true} />);
    } else if (errMsg === 'loading') {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    } else if (errMsg  != '') {
        return (<EMSplash msg={errMsg} />);
    } else if (!upoll) {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    }

    return (
        <PzArea.Container>
            <PzArea.MwhBoard  className="relative px-0 py-10 select-none bg-white gap-10">

                <div className="w-full px-5 ">
                    <EMAreaHeader poll={upoll} asVoter={false} size='3xl' />
                </div>
                <div className="w-full px-5 h-[calc(100dvh-8rem)] flex flex-col justify-start gap-10 overflow-y-scroll">
                    <EMAreaBodyAsPoller   poll={upoll} qidx={upoll.qactive} pqst={pqst} setPoll={setPoll2} size='3xl' />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>

                {/* HELP MENU Button */}
                <div className="absolute bottom-[2.5rem] left-[2rem] ">
                    <EMButtonForStaff.HelpMenu poll={upoll} setPoll={setPoll2} pqst={pqst} size='2xl' />
                </div>

                {/* RESULT MENU Button */}
                <div className="absolute bottom-[2.5rem] right-[2rem] ">
                    <EMButtonForStaff.RsltMenu poll={upoll} setPoll={setPoll2} pqst={pqst} size='2xl' />
                </div>

            </PzArea.MwhBoard >
        </PzArea.Container>
    );
}

