import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { SHA256, enc } from "crypto-js";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
// import { getTimeInUserTimezone } from "../../config/userConfig";
import PzBasicModal from "../../common/PzBasicModal";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import AzInputForName from "../../auth/azcomp/AzInputForName";
import AzInputForEmail from "../../auth/azcomp/AzInputForEmail";
import AzInputForPWord from "../../auth/azcomp/AzInputForPWord";
import PzTable, {PztShortNavigate} from "../../common/PzTable";
import UserSplash from "../UserSplash";

export default function PzAdminUserInfo() {
    const location = useLocation();
    const [uinfo, setUInfo] = React.useState({uguid: location?.state?.uguid, polls:[]});
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const utzone = useSelector((state)=>state.ucfg.timezone);  // timezone from user settings
    const [open4ChPW, setOpen4ChPW] = React.useState(false);

    const fetchUserInfo = ()=>{
        if (!uauth?.ispza) return;
        appConfig.getAPI().post("/api/pza/user/info", {uguid:location?.state?.uguid, balance:true})
        .then((response)=>{
            // console.log("PzAPI 'pza/user/info' fetched : ", response.data);
            let pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            setUInfo(pzdata.resource);
        })
        .catch((error)=>{
            console.log("PzAPI 'pza/user/info' error : ", error);
        })
        .finally(()=>{});
    };
    
    React.useEffect(() => {
        if (!uauth?.ispza) { navigate("/"); return; }
        fetchUserInfo();
    }, []);

    return (
        <PzArea.Container>
            <PzArea.AdminBoard >

                <h2>{"PzA - User Info"}</h2>

                <div className="grid grid-cols-[100px_1fr] gap-x-5 gap-y-1 text-sm">
                    <h5>UGuid</h5>      <p>{uinfo.uguid || ""}</p>
                    <h5>Name</h5>       <p>{uinfo.uname || ""}</p>
                    <h5>Email</h5>      
                    <div className="w-full flex flex-row justify-start items-center gap-4">
                        <p>{uinfo.uemail || ""}</p>
                        <PzButton.Std text="Reset PW" size='sm' onClick={()=>{setOpen4ChPW(true);}} />
                    </div>
                    <h5>Login (First)</h5>  
                    <p>{utils.getTimeInUserTimezone(uinfo.createdat, "YYYY/MM/DD HH:mm:ss", utzone, true)}</p>
                    <h5>Login (Last)</h5>  
                    <p>{utils.getTimeInUserTimezone(uinfo.lastlogin, "YYYY/MM/DD HH:mm:ss", utzone, true)}</p>
                    <h5>Last IP</h5> <p>{uinfo.lastip} &emsp; ({uinfo.location})</p>
                    <h5>Country</h5> <p>{uinfo.country}</p>
                    <h5>Locale</h5> <p>{uinfo.locale}</p>
                    <h5>Timezone</h5> <p>{uinfo.timezone}</p>
                    <h5>Free Quota</h5> <p>{uinfo.fq?.qtrem} / {uinfo.fq?.qtmax}</p>  
                    <h5>Polls</h5> 
                    <PzTable data={uinfo.polls} rowKey='pguid' configurable={true}
                        columns={[
                            { title: 'PGuid', key: 'pguid', 
                                render: ({pguid}) => { return (<PztShortNavigate value={pguid} stt={12} to="../pollinfo" state={{pguid:pguid}} />); } },
                            { title: 'Kind', key: 'pkind' },
                            { title: 'Status', key: 'pstatus', },
                            { title: 'When', key: 'pwhen', sorter: false, datetime:'YYYY/MM/DD HH:mm'},
                            { title: 'Title', key: 'ptitle' },
                        ]} />
                </div>
                <div className="flex flex-row justify-between">
                    <PzButton.Std text='UInfo' size='md' onClick={()=>console.log(uinfo)} />
                </div>

            </PzArea.AdminBoard>

            { open4ChPW &&
                <ModalToResetUserPWord uemail={uinfo.uemail || ""} onClose={()=>{setOpen4ChPW(false);}} />
            }
        
        </PzArea.Container>
    );
}


function ModalToResetUserPWord({uemail, onClose}) {
    const [ucookies] = useCookies(['uauth']);
    const uauth = (ucookies?.uauth ? jwtDecode(ucookies.uauth) : null);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [pword, setPword] = React.useState({value:'', errmsg:''});
    const [errMsg, setErrMsg] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    function postResetPword() {
        setLoading(true);
        const pwhash64 = SHA256(pword.value).toString(enc.Hex);
        appConfig.getAPI().post("/api/pza/user/resetpw", {uemail: uemail, pword64:pwhash64 })
        .then((response)=>{
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI 'pza/user/resetpw' success : ", pzdata);
            onClose();
        })
        .catch((error)=>{
            // console.log("error:", error);
            if (error.code === 'ERR_NETWORK') {
                setErrMsg(localeMap["auth.errmsg.network"]); 
                console.log("PzAPI 'pza/user/resetpw' failure - status:", error);
            } else if (error.response && error.response.status === 400) { // BAD_REQUEST
                const pzdata = error.response.data;     // {respcode:0, respmsg:'success', resource:{...}}
                setErrMsg(pzdata.respmsg);
                console.log("PzAPI 'pza/user/resetpw' failure - error:", error);
            } else {
                setErrMsg(localeMap["auth.errmsg.unknown"] + ` (${error.code})`); 
                console.log("PzAPI 'pza/user/resetpw' failure - status:", error.response?.status, ", error:", error);
            }
        })
        .finally(()=>{
            setLoading(false);
        });
    };
    
    return (
        <PzBasicModal onClose={onClose} >

            <div className="w-full flex flex-col justify-start items-center p-10 gap-4" >
                <div className="w-full flex flex-col justify-start items-center" >
                    <h1>Reset User Password</h1>
                </div>
                <div className="w-[16rem] flex flex-col justify-start items-stretch gap-3">
                <p>&nbsp;</p>
                    <div className="flex flex-col justify-start items-stretch gap-3">
                        <AzInputForEmail v={{value:uemail,errmsg:''}} setV={()=>{}} placeholder="User Email" disabled={true} className="w-full" />
                        <AzInputForPWord v={pword} setV={setPword} placeholder="User Password" className="w-full" onChange={()=>{if (errMsg!=='') setErrMsg('');}} />
                    </div>
                    <h5 className="text-xs text-center text-slate-500">&nbsp;{errMsg}&nbsp;</h5>
                </div>
                <div className="w-full flex flex-col justify-start items-center gap-2">
                    <PzButton.Std text='Reset' size='xl' className="w-[16rem] flex justify-center" 
                        disabled={pword.value === '' || pword.errmsg!=='' || errMsg!=='' || loading} 
                        onClick={postResetPword} loading={loading} />
                </div>
            </div>

        </PzBasicModal>
    );
}
