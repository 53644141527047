const localeStrMap = {
    "about.login.required": "로그인 하신 후에 사용하실 수 있는 기능입니다.",

    "about.feedback.fab": "피드백 보내기",
    "about.feedback.title": "본 서비스에 대한 의견을 남겨주세요",
    "about.feedback.desc": "당신의 의견을 남겨주세요 - 서비스 개선에 큰 도움이 됩니다.",
    "about.feedback.type0": "단순 코멘트",
    "about.feedback.type1": "버그 신고",
    "about.feedback.type2": "신규기능 요청",
    "about.feedback.newsletter": "이메일 뉴스레터에 가입하시겠습니까?",
    "about.feedback.newsletter.subscribe": "가입",
    "about.feedback.newsletter.desc": "서비스 및 블로그 업데이트 소식 등을 포함하며, 최대 월 1회 전송됩니다.",
    "about.feedback.send": "피드백 보내기",

    "about.pricing.sp.title": "서비스 가격",
    "about.pricing.sp.basic": "기본 가격",
    "about.pricing.sp.extra": "추가 가격 (온라인 초대)",
    "about.pricing.sp.mfactor": "비용증가 비율 (질문 수 기반)",
    "about.pricing.sp.formula": "서비스 비용 계산식",
    "about.pricing.fq.title": "무료이용 쿼타",
    "about.pricing.fq.forall": "매달 모든 사용자에게 주어지는 무료쿼타",
    "about.pricing.fq.qtrem": "내 무료쿼타 현재 잔여량",
    "about.pricing.fq.qtmax": "내 무료쿼타 최대 충전량",
    "about.pricing.fq.refill": "무료쿼타 다음 충전 예정일시",
    "about.pricing.cc.title": "예상비용 계산기",
    "about.pricing.cc.online": "온라인 초대 사용함",
    "about.pricing.cc.offline": "온라인 초대 사용 안함",
    "about.pricing.cc.voter": "투표자",
    "about.pricing.cc.quest": "질문",
    "about.pricing.cc.ested": "예상 총 비용",
    "about.pricing.cc.usefq": "무료쿼타 적용 여부",
    "about.pricing.cc.final": "최종 지불하실 비용",
    "about.pricing.cm.title": "기타 다른 전자투표서비스들과 비교를 원하신다면...",

    "forgotten.msg1": "그동안 저희 서비스를 이용해주셔서 감사합니다.\n 당신의 계정 및 이벤트정보가 모두 안전하게 삭제되었습니다.",
    "forgotten.msg2": "가까운 미래에 새로운 관계를 통해, \n 더욱 발전된 서비스를 당신에게 제공할 수 있기를 희망합니다.",
    "notfound.request": "요청하신 페이지가 존재하지 않습니다.",
    "notfound.nav.home": "홈화면으로 이동",
    "notfound.nav.login": "로그인으로 이동",

    "comm.etype.p": "투표",         // this will replace '{etype}' in other locale strings
    "comm.etype.s": "설문",         // this will replace '{etype}' in other locale strings
    "comm.etype.q": "퀴즈",         // this will replace '{etype}' in other locale strings
    "comm.voters.p": "투표자들",       // this will replace '{voters}' in other locale strings
    "comm.voters.s": "참가자들",       // this will replace '{voters}' in other locale strings
    "comm.voters.q": "참가자들",       // this will replace '{voters}' in other locale strings
    "comm.voter.p": "투표자",         // this will replace '{voter}' in other locale strings
    "comm.voter.s": "참가자",         // this will replace '{voter}' in other locale strings
    "comm.voter.q": "참가자",         // this will replace '{voter}' in other locale strings
    "comm.button.ok": "확인",
    "comm.button.cancel": "취소",
    "comm.button.yes": "예",
    "comm.button.no": "아니오",
    "comm.button.close": "닫기",
    "comm.underdev": "개발중",    // 
    
    "errmsg.network": "네트워크 설정을 확인 후 다시 시도해주세요",
    "errmsg.unknown": "알 수 없는 오류가 발생했습니다",

    "auth.login.title": "환영합니다",
    "auth.login.subtitle": "",
    "auth.login.input.email": "이메일 주소",
    "auth.login.input.password": "비밀번호",
    "auth.login.button": "로그인",
    "auth.login.nav1.label": "아직 계정이 없으신가요?",
    "auth.login.nav1.button": "회원가입",
    "auth.login.nav2.label": "비밀번호를 잊으셨나요?",
    "auth.login.nav2.button": "재설정",
    "auth.login.errmsg.missed": "이메일 혹은 비밀번호가 올바르지 않습니다. ({1}/{2})",
    "auth.login.errmsg.missxx": "로그인 시도 횟수를 초과했습니다. 5분 후 시도해주세요.",
    "auth.login.errmsg.nouser": "존재하지 않는 계정입니다.",
    "auth.login.errmsg.server": "로그인에 실패했습니다. 잠시 후 다시 시도해주세요.",
    "auth.resetpw.step1.title": "분실한 비밀번호 재설정",
    "auth.resetpw.step1.subtitle": "당신의 이메일 주소로 6자리 인증코드를 보내드립니다.",
    "auth.resetpw.step1.input.email": "이메일 주소",
    "auth.resetpw.step1.button": "재설정용 인증코드 받기",
    "auth.resetpw.step2.title": "인증코드 확인 및 비밀번호 재설정",
    "auth.resetpw.step2.subtitle": "당신의 이메일 주소 '{1}'로 6자리 인증코드를 보냈습니다. 받지 못했다면 당신의 이메일 주소와 메일함의 스팸 폴더를 확인해주세요.",
    "auth.resetpw.step2.input.vcode": "인증코드",
    "auth.resetpw.step2.input.pword1": "새 비밀번호",
    "auth.resetpw.step2.input.pword2": "새 비밀번호 재입력",
    "auth.resetpw.step2.button": "비밀번호 재설정",
    "auth.resetpw.errmsg.notfound": "등록되지 않은 이메일 주소입니다",
    "auth.signup.step1.title": "회원가입",
    "auth.signup.step1.subtitle": "우리의 집단적 의사결정 과정을 지원하고 혁신합니다.",
    "auth.signup.step1.input.uname": "성명",
    "auth.signup.step1.input.email": "이메일 주소",
    "auth.signup.step1.button": "회원가입용 인증코드 받기",
    "auth.signup.step1.nav1.label": "회원가입 시, 다음 조건에 동의합니다:",
    "auth.signup.step1.nav1.button": "사용약관 및 개인정보 보호정책",
    "auth.signup.step1.nav2.label": "이미 계정이 있으신가요?",
    "auth.signup.step1.nav2.button": "로그인",
    "auth.signup.step2.title": "인증코드 확인 및 비밀번호 설정",
    "auth.signup.step2.subtitle": "당신의 이메일 주소 '{1}'로 6자리 인증코드를 보냈습니다. 찾지 못했다면 당신의 이메일 주소와 메일함의 스팸 폴더를 확인해주세요.",
    "auth.signup.step2.input.vcode": "인증코드",
    "auth.signup.step2.input.pword1": "비밀번호",
    "auth.signup.step2.input.pword2": "비밀번호 재입력",
    "auth.signup.step2.button": "내 계정 생성",
    "auth.chpword.title": "비밀번호 변경",
    "auth.chpword.subtitle": "",
    "auth.chpword.input.pword0": "기존 비밀번호",
    "auth.chpword.input.pword1": "새 비밀번호",
    "auth.chpword.input.pword2": "새 비밀번호 재입력",
    "auth.chpword.button": "비밀번호 변경",
    "auth.chpword.errmsg.notfound": "등록되지 않은 이메일 주소입니다",
    "auth.chpword.errmsg.oldinvalid": "기존 비밀번호가 일치하지 않습니다",
    "auth.chpword.errmsg.newinvalid": "새 비밀번호가 유효하지 않습니다",
    "auth.chemail.title": "이메일 주소 변경",
    "auth.chemail.subtitle.step1": "당신의 새 이메일 주소로 인증코드를 보내드립니다.",
    "auth.chemail.subtitle.step2": "새로 받은 6자리 인증코드를 입력해주세요.",
    "auth.chemail.input.oldemail": "기존 이메일 주소",
    "auth.chemail.input.newemail": "새 이메일 주소",
    "auth.chemail.input.vcode": "인증코드",
    "auth.chemail.button.step1": "새 이메일 주소로 인증코드 받기",
    "auth.chemail.button.step2": "새 이메일 주소로 변경",
    "auth.chemail.errmsg.emailtaken": "이미 사용 중인 이메일 주소입니다",
    "auth.vcode.errmsg.notfound": "등록되지 않은 이메일 주소입니다",
    "auth.vcode.errmsg.emailtaken": "이미 사용 중인 이메일 주소입니다",
    "auth.vcode.errmsg.emailerror": "이메일 전송실패 - 잠시 후 다시 시도해주세요",
    "auth.vcode.errmsg.nomatch": "인증코드가 일치하지 않습니다",
    "auth.vcode.retry.label": "아직도 이메일을 받지 못하셨나요?",
    "auth.vcode.retry.button": "다시 시도",
    "auth.errmsg.vcode.invalid": "인증코드는 6자리 숫자들로 구성됩니다",
    "auth.errmsg.email.invalid": "이메일 주소가 유효하지 않습니다",
    "auth.errmsg.uname.invalid": "성명이 유효하지 않습니다",
    "auth.errmsg.pword.invalid": "비밀번호가 유효하지 않습니다",
    "auth.errmsg.pword.mismatch": "비밀번호가 일치하지 않습니다",
    "auth.errmsg.pword.length": "길이는 8 부터 25 사이로 입력해주세요",
    "auth.errmsg.pword.uchar": "최소 하나의 대문자를 포함해야 합니다",
    "auth.errmsg.pword.digit": "최소 하나의 숫자를 포함해야 합니다",
    "auth.errmsg.pword.schar": "최소 하나의 특수문자를 포함해야 합니다",
    "auth.errmsg.network": "네트워크 설정을 확인 후 다시 시도해주세요",
    "auth.errmsg.unknown": "알 수 없는 오류가 발생했습니다",
    "auth.extsession.title": "사용 시간 연장",
    "auth.extsession.direction": "하단의 '연장하기' 버튼을 클릭하거나 \n 키보드 SPACE 키를 누르면 연장됩니다.",
    "auth.extsession.remaining": "{1} 초 후에 자동 로그아웃합니다",
    "auth.extsession.button": "연장하기",
    "auth.extsession.toast": "로그인이 연장되었습니다.",

    "home.intro.title": "여러 사람들의 의견을 모으는\n가장 빠르고 간편하고 안전한 방법",
    "home.intro.subtitle": "누구나 쉽게 이용할 수 있는 온라인 투표 서비스로서,\n자동화된 실시간 다회차 투표를 통해\n 재투표를 포함한 의사결정을 1분 안에 완료할 수 있습니다.",
    "home.intro.serving": "{3}개 국가의 {1}개 이벤트에 {2}명이 참가하였습니다.",
    "home.intro.diff.title": "기존 전자투표서비스들과 다른 점",
    "home.intro.diff.claim": "1분 안에 결론을 내릴 수 있는 전자투표서비스",
    "home.intro.why.title": "당신에게 Pollerz.net이 필요한 이유",
    "home.why.label0": "빠른 의사결정",
    "home.why.label1": "낮은 비용",
    "home.why.label2": "간편",
    "home.why.label3": "안전",
    "home.why.label4": "스태프 지원",
    "home.why.desc0": "1회 투표의 결과집계만으로 끝나는 기존의 전자투표 서비스들과 달리, Pollerz.net은 최종결론에 이르기까지 의사결정의 전 과정을 자동화하는 온라인 투표서비스 입니다. 첫 투표에서 결정을 내릴 수 없는 경우에도 재투표 여부를 즉시 결정하고 실행함으로써, 내 그룹 혹은 조직의 중요한 의사결정을 가장 빠르게 완료할 수 있습니다.",
    "home.why.desc1": "투표를 통한 의사결정에서 발생하는 가장 큰 비용은, 투표 이벤트 자체의 진행비용이 아니라, 많은 사람들을 하나로 모으는 데 드는 노력과 모든 참가자들이 소모해야했던 시간들의 총 합입니다. Pollerz.net은 집단적 의사결정 과정을 실시간 다회차 투표를 통해 자동화함으로써, 처음부터 다시 반복해야만하는 재투표로 인해 발생하는 사회적 비용을 제거합니다.",
    "home.why.desc2": "자유롭게 투표를 열어 다른 사람들의 의견을 묻고 그 결과를 집계하는 일은, 누구나 할 수 있는 아주 쉬운 일이어야 합니다. 이를 위해 필요한 모든 기술을 우리는 이미 손 안에 가지고 있습니다. 참가자들이 투표에 참여하기 위해 어떠한 앱설치 또는 가입절차도 필요하지 않으며, 주최자와 참가자들은 투표의 진행상황 및 결과를 실시간으로 쉽게 공유합니다.",
    "home.why.desc3": "모든 투표는 안전하게 집계되고, 무기명 투표의 비밀주의 원칙은 철저하게 지켜져야 합니다. 또한 투표 참가자들의 개인정보는 투표 자체의 실행 목적 외의 다른 어떠한 목적으로도 이용되거나 제3자에게 제공되지 않으며, 투표결과의 최종 분석 후에는 영구히 삭제됩니다.",
    "home.why.desc4": "이벤트의 주최자로서 당신은, 당신을 도와줄 스태프 멤버들을 임명하고 필요한 권한을 부여할 수 있습니다. 당신이 지정한 스태프 멤버들은 실시간 이벤트의 진행을 보조하고 사용자 참가자격을 확인하며 오프라인에서 참가자들을 도움으로써, 이벤트 행사 중 발생할 수 있는 여러 문제들을 해결할 수 있습니다.",
    "home.goal.boostdm": "Boost our collective decision making \n for democracy.",
    "home.goal.servepp": "Serve people, \n and never try to influence or lock people in.",
    "home.goal.staysml": "Stay small, light, and agile, \n without compromising security.",
    "home.goal.desc.boostdm": "1인 1표의 익명투표는 민주주의의 근간이며, 나와는 다른 사람들에 대한 존중이자, 내 의견과 다를지라도 정당한 절차로 결정된 다수의 의견을 인정하겠다는 적극적인 선언과 노력을 의미한다고 믿습니다. Pollerz.net은 이러한 의견수렴 과정이 빠르고 간편하고 안전하게 실행되도록 지원함으로써, 민주적인 의사결정 과정에 조금이나마 도움이 될 수 있기를 희망합니다.",
    "home.goal.desc.support": "Pollerz.net의 목적은 사람들의 자유로운 의사표현과 의견수렴을 지원하는 것이며, 사람들의 의견에 영향을 끼치려는 어떠한 시도에도 반대합니다. Pollerz.net은 사용자 프로파일을 추출하거나 사용자 락인효과를 추구하지 않습니다. 또한 이벤트 참가자들에 대한 개인정보를 수집하거나 이용하지 않으며, 해당 정보는 이벤트 최종보고서 작성 후 데이터베이스에서 모두 삭제됩니다.",
    "home.goal.desc.staysml": "사용자 가치에서 출발한 테크 기업들이 성장하는 과정에서 초기의 모습을 잃고, 자기 자신의 규모와 매출과 이익의 증대를 최우선으로 추구하는 모습으로 변질되는 사례들을 우리는 많이 보아왔습니다. Pollerz.net은 '성장'을 추구하지 않으며, 작고 가볍고 효율적인 조직으로 남아 사용자가 신뢰할 수 있는 서비스를 지속적으로 제공하는 것을 목표로 합니다.",
    "home.button.try": "서비스 체험하기",
    "home.button.try.asvoter": "참가자로서 체험하기",
    "home.button.try.aspoller": "주최자로서 체험하기",

    "home.page.mission.title": "Our Mission",
    "home.page.features.title": "서비스 안내",
    "home.page.pricing.title": "가격 정책",
    "home.page.privacy.title": "개인정보 처리방침",

    "menu.auth.login": "로그인",
    "menu.auth.logout": "로그아웃",
    "menu.auth.signup": "회원가입",
    "menu.home.features": "서비스안내",
    "menu.home.pricing": "가격정책",
    "menu.myaccount" : "나의 회원정보", 
    "menu.myevents": "나의 이벤트",
    "menu.poller": "주최자 메뉴",
    "menu.poller.editevent": "이벤트 편집 및 테스트",
    "menu.poller.getready": "이벤트 개최준비",
    "menu.poller.voterlist": "참가대상자 명단",
    "menu.poller.stafflist": "스태프멤버 명단",
    "menu.poller.invite": "이벤트 공지 및 초대",
    "menu.poller.runpoll": "이벤트 개최",
    "menu.poller.xresult": "이벤트 최종결과",
    "menu.poller.archive": "기록보관함에 저장",
    "menu.staff": "스태프 메뉴",
    "menu.staff.prepare": "이벤트 사전준비 지원",
    "menu.staff.support": "이벤트 실시간진행 지원",
    "menu.staff.sresult": "이벤트 최종결과",
    "menu.xresult.monitor": "최종결과 공유화면",
    "menu.voter": "참가자 메뉴",
    "menu.voter.vote": "투표하기",

    "modal.button.cancel": "취소",
    "modal.button.more": "고급 설정",
    "modal.button.save": "저장",

    "myaccount.title" : "나의 회원정보", 
    "myaccount.user.label" : "계정 정보", 
    "myaccount.username.label" : "이름", 
    "myaccount.username.change" : "변경", 
    "myaccount.userid.label" : "사용자 ID", 
    "myaccount.userid.change" : "변경", 
    "myaccount.userpw.label" : "사용자 비밀번호", 
    "myaccount.userpw.change" : "변경", 
    "myaccount.env.label" : "사용 환경", 
    "myaccount.env.country.label" : "거주중인 국가", 
    "myaccount.env.language.label" : "사용중인 언어", 
    "myaccount.env.timezone.label" : "사용중인 시간대", 
    "myaccount.quota.label" : "무료 쿼타", 
    "myaccount.quota.remain.label" : "현재 잔여량", 
    "myaccount.quota.monthly.label" : "월 최대 충전량", 
    "myaccount.quota.notice" : "무료 쿼타는 매월(UTC) 1일에 재충전됩니다.", 
    "myaccount.quota.nextrefill.label" : "다음 충전 예정일시", 
    "myaccount.paym.label" : "지불 수단", 
    "myaccount.rtbf.label" : "잊혀질 권리", 
    "myaccount.rtbf.warning" : "경고: 본 기능들은 실행 후 다시 되돌릴 수 없습니다.", 
    "myaccount.rtbf.exception" : "서비스 구매를 위한 지불내역은 삭제 후에도 예외적으로 보존됩니다. 해당 지불내역 정보는 법에 의해 기록보존 대상으로 지정된 최소한의 데이터만을 포함하며, 3년 경과 후 자동 삭제됩니다.", 
    "myaccount.rtbf.data.label" : "내가 생성한 모든 이벤트들과 관련 데이터를 삭제", 
    "myaccount.rtbf.data.direction" : "아래에 '{1}'이라고 정확히 입력한 후, 삭제 버튼을 눌러주세요.", 
    "myaccount.rtbf.data.keyword" : "모든 이벤트들", 
    "myaccount.rtbf.data.button" : "모든 이벤트 삭제", 
    "myaccount.rtbf.acct.label" : "내 계정을 삭제하고 모든 기록에서 내 정보를 제거", 
    "myaccount.rtbf.acct.direction" : "아래에 '{1}'라고 정확히 입력한 후, 삭제 버튼을 눌러주세요.", 
    "myaccount.rtbf.acct.keyword" : "계정 및 모든 정보", 
    "myaccount.rtbf.acct.button" : "내 계정 삭제", 

    "myevents.needtologin" : "로그인 후 이용하실 수 있습니다", 
    "myevents.planned" : "예정된 이벤트",
    "myevents.archived" : "기록보관된 이벤트", 
    "myevents.sysevents" : "시스템 이벤트",
    "myevents.none.planned" : "현재 예정된 이벤트가 존재하지 않습니다.",
    "myevents.none.archived" : "현재 기록보관된 이벤트가 존재하지 않습니다.",
    "myevents.none.sysevent" : "현재 시스템 이벤트가 존재하지 않습니다.",
    "myevents.none.archmsg" : "종료된 이벤트는 1주 후 자동으로 이 곳에 이동/저장됩니다.",

    "poll.summary.label" : "요약 설명", 
    "poll.summary.expire.notopened" : "기한 내에 개최되지 않아, {YYYY/MM/DD HH:mm}에 삭제될 예정입니다.", 
    "poll.summary.expire.notclosed" : "기한 내에 종결되지 않아, {YYYY/MM/DD HH:mm}에 삭제될 예정입니다.", 
    "poll.summary.status.A0" : "계획수립 중", 
    "poll.summary.status.Ac" : "계획수립 중", 
    "poll.summary.status.Ar" : "계획수립 후 구매 대기 중", 
    "poll.summary.status.Ax" : "개최준비 완료", 
    "poll.summary.status.Oo" : "개최 전 참가자 입장 중", 
    "poll.summary.status.Os" : "개최 중", 
    "poll.summary.status.Oz" : "완료 후 검토 중", 
    "poll.summary.status.Xc" : "종료 됨", 
    "poll.summary.status.Xx" : "기록보관 됨", 
    "poll.card.questions.label" : "질문 목록", 
    "poll.card.voters.label" : "{Voter} 명단", 
    "poll.card.voters.elg.planned" : "등록된 {1} 명의 대상자 중 최대 {2} 명 참가 가능", 
    "poll.card.voters.elg.closed" : "등록된 {1} 명의 대상자 중 {2} 명 참가", 
    "poll.card.voters.any.planned" : "자격조건 없이 최대 {1} 명 참가 가능", 
    "poll.card.voters.any.closed" : "자격조건 없이 {1} 명 참가", 
    "poll.card.staff.label" : "스태프 명단", 
    "poll.card.staff.summary" : "주최자는 '{1}'이며, {2} 명의 스태프멤버가 지원", 
    "poll.card.staff.myrole.p" : "주최자", 
    "poll.card.staff.myrole.m" : "매니저", 
    "poll.card.staff.myrole.s" : "스태프", 
    "poll.card.button.edit" : "편집", 
    "poll.card.button.getready" : "개최 준비", 
    "poll.card.button.invite" : "초대하기", 
    "poll.card.button.run" : "개최하기", 
    "poll.card.button.prepare" : "스태프 사전 준비", 
    "poll.card.button.support" : "스태프 개최 지원", 
    "poll.card.button.xresult" : "결과 보기", 

    "poll.create_event" : "새로운 이벤트 만들기", 
    "poll.create.voters.direction" : "아래 입력창에 {voter} 명단을 입력한 후, '{1}' 버튼을 눌러주세요.", 
    "poll.create.voters.extract" : "명단 추출", 
    "poll.create.voters.errmsg" : "인식되지 않은 문자열이 포함되어 있습니다.", 
    "poll.create.voters.none" : "등록할 참가대상자가 없습니다", 
    "poll.create.voters.some" : "{1} 명의 참가대상자들이 등록될 예정입니다", 
    "poll.create.button.prev": "이전 단계로",
    "poll.create.button.next": "다음 단계로",
    "poll.create.button.create": "이벤트 생성",
    "poll.criteria.Majority" : "과반득표", 
    "poll.criteria.Most" : "최다득표", 
    "poll.criteria.Zero" : "무득표", 
    "poll.criteria.Least" : "최저득표", 
    "poll.criteria.Top2x" : "상위 2배수 미포함", 
    "poll.criteria.Top3x" : "상위 3배수 미포함", 
    "poll.criteria.Top4x" : "상위 4배수 미포함", 
    "poll.edit.simtest": "이벤트 사전 테스트",
    "poll.edit.save": "이벤트 저장",
    "poll.edit.savemsg": "본 이벤트의 수정내용을 클라우드에 저장합니다.",
    "poll.edit.delete": "이벤트 삭제",
    "poll.edit.deletemsg": "본 이벤트와 관련된 모든 데이터를 삭제합니다.",
    "poll.edit.archive": "아카이브 저장",
    "poll.edit.archivemsg": "본 이벤트와 관련된 모든 데이터를 아카이브로 이동,저장합니다.",
    
    "poll.xresult.plabel.tnk" : "제목 및 종류",
    "poll.xresult.plabel.qna" : "질문 및 답변",
    "poll.xresult.plabel.gnc" : "목표 및 기준",
    "poll.xresult.plabel.xns" : "결과 및 통계",
    "poll.xresult.label.residual" : "무효 투표 수",
    "poll.xresult.label.total" : "총 투표 수",

    "poll01.pkind.label" : "종류", 
    "poll01.ptitle.label" : "제목", 
    "poll01.ptitle.example" : "제0회 정기 공동의회", 
    "poll01.ptitle.placeholder" : "이벤트 제목을 입력해주세요", 
    "poll01.uname.placeholder" : "주최자 이름을 입력해주세요", 
    "poll01.pwhen.label" : "일시", 
    "poll01.pwhen.rightnow" : "바로 지금", 
    "poll01.pwhen.rightnow.constraint" : "(생성 후 1시간 이내 개최 필요)", 
    "poll01.pwhen.rightnow.expire" : "{hh:mm:ss}까지 개최되지 않으면 자동 삭제됩니다.", 
    "poll01.pwhen.scheduled" : "시작일시 지정", 
    "poll01.pearly.label" : "사전투표", 
    "poll01.pearly.yes" : "허용", 
    "poll01.pearly.no" : "허용안함", 
    "poll01.locale.label" : "사용 언어", 
    "poll01.poller.label" : "주최자", 
    
    "poll02.qkind.label" : "질문 종류", 
    "poll02.qkind.A" : "가/부 결정", 
    "poll02.qkind.S" : "대안 선택", 
    "poll02.qkind.E" : "후보 선출", 
    "poll02.qkind.C" : "의견수렴", 
    "poll02.question.example" : "{1}번째 질문 ?", 
    "poll02.question.example.A" : "예산안을 승인하시겠습니까 ?", 
    "poll02.question.example.S" : "대안들 중 어느 안을 선호하십니까 ?", 
    "poll02.question.example.E" : "대표로 어느 후보를 선택하시겠습니까 ?", 
    "poll02.question.label" : "질문 내용", 
    "poll02.question.placeholder" : "질문내용을 입력해주세요", 
    "poll02.answers.clabel" : "답변 목록", 
    "poll02.answers.slabel" : "답변 목록", 
    "poll02.answers.elabel" : "후보 목록", 
    "poll02.answers.placeholder" : "한 라인마다 하나씩 선택가능한 후보/대안들을 입력해주세요", 
    "poll02.answers.A" : "예\n아니오", 
    "poll02.answers.S" : "대안1\n대안2\n대안3\n대안4\n대안5\n대안6", 
    "poll02.answers.E" : "후보1\n후보2\n후보3", 
    "poll02.vgoal.label" : "실행 목표", 
    "poll02.vgoal.maxrounds" : "재투표 최대 회차", 
    "poll02.xresult.label" : "실행 결과", 
    "poll02.xresult.A" : "미결/가결/부결", 
    "poll02.xresult.S" : "미결/'{1}' 선택/'{1}' (+{2}안) 선택", 
    "poll02.xresult.E" : "미결/'{1}' 선출/'{1}' (+{2}인) 선출", 
    "poll02.xresult.C" : "미결/의견 수집 {1} 건", 
    "poll02.wcriteria.label.A" : "승인 기준", 
    "poll02.wcriteria.label.S" : "선택 기준", 
    "poll02.wcriteria.label.E" : "선출 기준", 
    "poll02.dcriteria.label" : "탈락 기준", 
    "poll02.button.appendnew" : "다음 질문 추가", 
    "poll02.button.removelast" : "마지막 질문 삭제", 
    "poll02.button.showdetail" : "자세히 보기", 
    "poll02.button.save" : "편집내용 저장", 
    "poll02.button.delete" : "질문 삭제", 
    "poll02.completed.ready" : "새로운 이벤트가 준비되었습니다.", 
    "poll02.completed.todo" : "작성하신 이벤트를 저장하기 위해서는\n 로그인 또는 회원가입을 하셔야합니다.", 

    "poll03.vlist.title" : "참가대상자 명단", 
    "poll03.vlist.clear": "참가대상자 명단 전체 삭제",
    "poll03.vlist.clearmsg": "본 이벤트에 등록된 참가대상자 명단을 초기화합니다.",
    "poll03.voter.direction1" : "아래 입력창에서 전체 명단을 편집 후, '저장하기' 버튼을 눌러주세요.", 
    "poll03.voter.direction2" : "각 라인의 예 : '홍길동, 20041230, any@example.com' (성명,생년월일,전화번호,이메일)", 
    "poll03.voter.errmsg.no_email" : "참가대상자의 이메일이 필요합니다.", 
    "poll03.voter.errmsg.no_phone" : "참가대상자의 전화번호가 필요합니다.", 
    "poll03.voter.errmsg.no_name" : "참가대상자의 성명이 필요합니다.", 
    "poll03.voter.errmsg.empty_list" : "참가대상자 명단이 비어었습니다.", 
    "poll03.voter.action.add" : "참가대상자 추가", 
    "poll03.voter.action.edit" : "참가대상자 수정", 
    "poll03.voter.action.text" : "텍스트로 전체 명단 편집", 
    "poll03.voter.action.clear" : "전체 명단 삭제", 
    "poll03.voter.button.save" : "저장", 
    "poll03.voter.button.delete" : "삭제", 
    "poll03.voter.label.vid" : "아이디", 
    "poll03.voter.label.vname" : "성명", 
    "poll03.voter.label.vcode" : "식별코드", 
    "poll03.voter.label.vbdate" : "생년월일", 
    "poll03.voter.label.vemail" : "이메일", 
    "poll03.voter.label.vphone" : "휴대전화", 

    "poll04.slist.title" : "스태프멤버 명단", 
    "poll04.slist.clear": "스태프 명단 전체 삭제",
    "poll04.slist.clearmsg": "본 이벤트에 등록된 스태프멤버 명단을 초기화합니다.",
    "poll04.staff.direction1" : "아래 입력창에 스태프멤버 전체 명단을 입력/편집 후, '저장하기' 버튼을 눌러주세요.", 
    "poll04.staff.direction2" : "각 라인의 예: '홍길동, someone@example.com, S' (성명,이메일,역할)", 
    "poll04.staff.errmsg.no_email" : "스태프 멤버의 이메일이 필요합니다.", 
    "poll04.staff.errmsg.no_name" : "스태프 멤버의 성명이 필요합니다.", 
    "poll04.staff.errmsg.empty_list" : "스태프 멤버 명단이 비어었습니다.", 
    "poll04.staff.action.add" : "스태프멤버 추가", 
    "poll04.staff.action.edit" : "스태프멤버 수정", 
    "poll04.staff.action.text" : "텍스트로 전체 명단 편집", 
    "poll04.staff.action.clear" : "전체 명단 삭제", 
    "poll04.staff.button.save" : "저장", 
    "poll04.staff.button.delete" : "삭제", 
    "poll04.staff.label.name" : "성명", 
    "poll04.staff.label.email" : "이메일", 
    "poll04.staff.label.role" : "역할", 
    "poll04.staff.label.role.manager" : "매니저", 
    "poll04.staff.label.role.staff" : "스태프", 

    "poller.editevent.event" : "이벤트 정보",
    "poller.editevent.qlist" : "질문 목록",
    "poller.editevent.quest" : "질문 상세정보",

    "poller.getready.voters" : "참가대상자",
    "poller.getready.voters.sum0" : "누구나 참여할 수 있는 이벤트입니다",
    "poller.getready.voters.sum1" : "{1}명의 참가대상자가 등록되었습니다",
    "poller.getready.voters.sumo" : "{1}명 중 {2}명이 참가중입니다",
    "poller.getready.voters.sumx" : "{1}명 중 {2}명이 참가하였습니다",
    "poller.getready.voters.nt" : "등록된 참가대상자 수",
    "poller.getready.voters.nt0" : "누구나 참여할 수 있습니다.",
    "poller.getready.voters.nt1" : "등록된 참가대상자들만 참여할 수 있습니다.",
    "poller.getready.voters.ne" : "이메일 등록자 수",
    "poller.getready.voters.np" : "전화번호 등록자 수",
    "poller.getready.voters.na" : "이메일/전화번호 등록자 수",
    "poller.getready.voters.na0" : "'온라인 초대' 기능은 사용할 수 없습니다.",
    "poller.getready.voters.na1" : "{1}명의 참가자 정보가 부족합니다.",
    "poller.getready.voters.na2" : "'온라인 초대' 기능을 사용할 수 있습니다.",
    "poller.getready.voters.nmax" : "최대 참가자 수",
    "poller.getready.voters.natt" : "실제 참가자 수",
    "poller.getready.voters.list" : "참가대상자 명단",
    "poller.getready.voters.example" : "예를 들어 명단에 5명 추가",

    "poller.getready.staffs" : "스태프멤버",
    "poller.getready.staffs.sum0" : "스태프 지원 없이 스스로 이벤트를 진행합니다",
    "poller.getready.staffs.sum1" : "{1}명의 스태프멤버가 이벤트 진행을 지원합니다",
    "poller.getready.staffs.nm" : "등록된 매니저 수",
    "poller.getready.staffs.nm0" : "주최자를 대신하여 이벤트를 진행할 수 있습니다.",
    "poller.getready.staffs.ns" : "등록된 일반 스태프 수",
    "poller.getready.staffs.ns0" : "오프라인에서 참가자들을 지원합니다.",
    "poller.getready.staffs.list" : "스태프멤버 명단",
    "poller.getready.staffs.example" : "예를 들어 명단에 3명 추가",

    "poller.getready.pca.title" : "참가자 통제 및 인증",
    "poller.getready.pca.sum0" : "인증방식을 선택하셔야 합니다",
    "poller.getready.pca.sum1" : "서비스 비용을 지불하셔야 합니다",
    "poller.getready.pca.sum2" : "개최를 위한 모든 준비가 완료되었습니다",
    "poller.getready.pca.tbd" : "미정",
    "poller.getready.pca.n.label" : "참여인원수",
    "poller.getready.pca.n.min" : "최소 참여인원수",
    "poller.getready.pca.n.max" : "최대 참여인원수",
    "poller.getready.pca.n.elg" : "등록된 인원수",
    "poller.getready.pca.inv" : "참가자 초대방식",
    "poller.getready.pca.offline" : "오프라인 (QR코드를 게시 또는 배포)",
    "poller.getready.pca.online" : "온라인 (이메일/메시지로 초대장 전송)",
    "poller.getready.pca.nmin" : "최소 참여인원수",
    "poller.getready.pca.nmax" : "최대 참여인원수",
    "poller.getready.pca.nmin.desc" : "이벤트가 유효하게 성립하기 위한 최소 조건입니다",
    "poller.getready.pca.nmax.desc" : "초과하는 경우, 참가자의 입장이 제한됩니다",
    "poller.getready.pca.nmax.cost" : "(참가자 당 {1} 기본비용)",
    "poller.getready.pca.comm.cost" : "(참가자 당 {1} 추가비용)",
    "poller.getready.pca.auth" : "참가자 인증방식",
    "poller.getready.pca.auth.qr" : "QR",
    "poller.getready.pca.auth.allow" : "참가대상",
    "poller.getready.pca.auth.check" : "확인정보",
    "poller.getready.pca.auth.simplicity" : "편의성",
    "poller.getready.pca.auth.security" : "보안성",
    "poller.getready.pca.auth.A" : "누구나",
    "poller.getready.pca.auth.E" : "등록된 사람만",
    "poller.getready.pca.auth.1" : "공용",
    "poller.getready.pca.auth.9" : "개인별",
    "poller.getready.pca.auth.__" : "없음",
    "poller.getready.pca.auth.n_" : "성명",
    "poller.getready.pca.auth.nc" : "성명,식별코드",
    "poller.getready.pca.pcode" : "식별코드 생성방식",
    "poller.getready.pca.pcode.c" : "공통 식별코드 사용",
    "poller.getready.pca.pcode.i" : "개인 {1}의 마지막 4자리 숫자/문자",
    "poller.getready.pca.pcode.r" : "개인별 임의생성 (4자리 숫자) 후 온라인 전송",
    "poller.getready.pca.price.label" : "서비스 비용",
    "poller.getready.pca.price.total" : "서비스 가격",
    "poller.getready.pca.price.topay" : "실제 지불하실 비용",
    "poller.getready.pca.fq.rem" : "잔여 무료쿼타",
    "poller.getready.pca.fq.after" : "지불 후 잔여 무료쿼타",
    "poller.getready.pca.fq.payable" : "무료쿼타 사용가능 여부",
    "poller.getready.pca.fq.yes" : "사용 가능",
    "poller.getready.pca.fq.no" : "사용 불가",
    "poller.getready.pca.button.edit" : "선택사항 편집",
    "poller.getready.pca.paid" : "결재가 완료되었습니다",
    
    "poller.getready.payfq.title" : "무료쿼타로 구매하기",
    "poller.getready.payfq.remaining" : "잔여 무료쿼타",
    "poller.getready.payfq.tobeused" : "사용할 무료쿼타",
    "poller.getready.payfq.newprice" : "지불할 금액",
    "poller.getready.payfq.button" : "구매",

    "poller.getready.paymn.title" : "Payment",

    "poller.invite.boardtitle" : "이벤트 공지 및 초대",

    "poller.runpoll.open.event" : "이벤트 참가자 입장 시작",
    "poller.runpoll.open.runner" : "이벤트 개최화면 열기",
    "poller.runpoll.xpoll.title" : "이벤트 종료",
    "poller.runpoll.xpoll.msg" : "이벤트를 종료하고, 모든 참가자들의 접속을 즉시 차단합니다.\n 계속하시겠습니까?",
    "poller.runpoll.ypoll.title" : "이벤트 조기종료 준비",
    "poller.runpoll.ypoll.msg" : "남은 질문들은 무시하고,\n 이벤트를 조기종료하고자 최종결과를 준비합니다.\n 계속하시겠습니까?",
    "poller.runpoll.zpqst.title" : "이벤트 현재질문 초기화",
    "poller.runpoll.zpqst.msg" : "현재 진행중인 질문에 대하여 \n 투표내용을 초기화하고 투표를 다시 시작합니다. \n 계속하시겠습니까?",
    "poller.runpoll.deadline.broken" : "이벤트 예정시간을 초과하였습니다",

    "poller.xreport.boardtitle" : "최종보고서 확인",

    "staff.entrance.boardtitle" : "선거 입장 안내",
    "staff.eligibility.boardtitle" : "투표권 확인",
    "staff.votehelper.boardtitle" : "투표 안내",
    "staff.watchpoll.boardtitle" : "선거 실시간 시청",
    "staff.view.entrance.qr" : "입장안내용 QR코드 보기",
    "staff.view.entrance.png" : "입장안내용 이미지 다운로드",
    "staff.view.entrance.pdf" : "입장안내 출력용 PDF파일 다운로드",
    "staff.open.runner.as.manager" : "매니저용 이벤트개최 화면 열기",
    "staff.open.support.as.staff" : "스태프용 참가자지원 화면 열기",
    "staff.xresult.boardtitle" : "최종결과 확인",

    "monitor.event.poller.label": "주최자",
    "monitor.event.eligibles.label": "참가대상자",
    "monitor.event.attendee.label": "참가자",
    "monitor.event.myname.label": "내 성명",
    "monitor.quest.selectfirst": "첫번째 질문을 선택해주세요",
    "monitor.quest.selectnext": "다음 질문을 선택해주세요",
    "monitor.quest.planned": "다음 질문들에 대하여 투표할 예정입니다",
    "monitor.quest.voting.asked": "아래 질문에 대하여 투표가 진행중입니다",
    "monitor.quest.voting.now": "지금 투표해주세요",
    "monitor.quest.chosen.A": "현재까지 선택된 답변",
    "monitor.quest.chosen.S": "현재까지 선택된 답변",
    "monitor.quest.chosen.E": "현재까지 선출된 후보",
    "monitor.quest.goal.label" : "선출 목표",
    "monitor.quest.goal.A" : "승인여부(가/부) 결정",
    "monitor.quest.goal.S" : "{2}개 중 {1}개 선택",
    "monitor.quest.goal.E" : "{2}명 중 {1}명 선택",
    "monitor.quest.goal.roundmax" : "재투표 최대 회차",
    "monitor.quest.goal.total" : "전체",
    "monitor.quest.goal.selected" : "선출",
    "monitor.quest.goal.dropped" : "탈락",
    "monitor.quest.goal.vcheck.S" : "{1}개만 선택하였으며 나머지는 기권처리됩니다.\n그대로 투표하시겠습니까?",
    "monitor.quest.goal.vcheck.E" : "{1}명만 선택하였으며 나머지는 기권처리됩니다.\n그대로 투표하시겠습니까?",
    "monitor.quest.criteria.var" : "변동값 기준",
    "monitor.quest.criteria.abs" : "고정값 기준",
    "monitor.quest.criteria.natt" : "(참가자 수)",
    "monitor.quest.tally.count" : "투표 결과를 집계하고 있습니다",
    "monitor.quest.tally.result" : "투표 집계 결과는 아래와 같습니다",
    "monitor.quest.result.chosen" : "선출 결과",
    "monitor.quest.result.todo" : "평가 결과",
    "monitor.quest.todo.done" : "의사결정이 완료되었습니다",
    "monitor.quest.todo.fail0" : "미결정 상태로 완료되었습니다",
    "monitor.quest.todo.fail1" : "일부({1}) 미결정 상태로 완료되었습니다",
    "monitor.quest.todo.A" : "재투표로 다시 결정해야 합니다",
    "monitor.quest.todo.S" : "재투표로 {2}개 중 {1}개를 선택해야 합니다",
    "monitor.quest.todo.E" : "재투표로 {2}명 중 {1}명을 선출해야 합니다",
    "monitor.progress.checkin": "참가자 입장(체크인) 진행상황",
    "monitor.progress.checked": "참가자 수 최소조건({1})이 만족되었습니다",
    "monitor.progress.voting": "투표 진행상황",
    "monitor.progress.rresult.ends": "Round {1} : {2} 선택완료",
    "monitor.progress.rresult.endf": "Round {1} : {2} 선택완료, {3} 미결",
    "monitor.progress.rresult.next": "Round {1} : {2} 선택완료, {3} 재투표",
    "monitor.result.title": "최종 결과",
    "monitor.result.finalresult": "최종결과",
    "monitor.result.votecounts": "집계결과",
    "monitor.result.nochosen.A": "결정되지 않았습니다",
    "monitor.result.nochosen.S": "선택된 답변이 없습니다",
    "monitor.result.nochosen.E": "선출된 후보가 없습니다",
    "monitor.action.event.open" : "{voter} 입장허용 시작",
    "monitor.action.event.start" : "이벤트 시작",
    "monitor.action.event.close" : "이벤트 종료",
    "monitor.action.event.final" : "최종결과 확인",
    "monitor.action.quest.close" : "질문 종료",
    "monitor.action.quest.vstart" : "투표 시작",
    "monitor.action.quest.vstop" : "투표 종료",
    "monitor.action.quest.nextr" : "재투표",
    "monitor.action.quest.nextq" : "다음 질문",
    "monitor.action.castvote" : "투표하기",
    "monitor.action.checkout" : "체크아웃",
    "monitor.action.copyresult" : "최종결과 텍스트 복사",
    "monitor.help.ypoll" : "이벤트 조기종료 준비",
    "monitor.help.zpqst" : "이벤트 현재질문 초기화",
    "monitor.help.stafflist" : "스태프 명단 확인",
    "monitor.help.voterlist" : "참가자 명단 확인",
    "monitor.help.qrcode" : "참가자 입장용 QR코드",
    "monitor.help.help2checkin" : "참가자 체크인 문제 해결하기",
    "monitor.help.help2vote" : "참가자 투표 문제 해결하기",
    "monitor.help.qsummary" : "{1} 요약 정보",
    "monitor.help.rhistory" : "{1} 라운드 이력",
    "monitor.waiting.event.checkin" : "참가자들의 체크인을 기다리는 중입니다",
    "monitor.waiting.event.checkin2" : "다른 참가자들의 체크인을 기다리는 중입니다",
    "monitor.waiting.event.start" : "주최자의 이벤트 시작을 기다리는 중입니다",
    "monitor.waiting.quest.select" : "주최자의 질문 선택을 기다리는 중입니다",
    "monitor.waiting.quest.close": "질문의 결과확인 및 종료를 기다리는 중입니다",
    "monitor.waiting.quest.vstart" : "투표 시작을 기다리는 중입니다",
    "monitor.waiting.quest.vstop" : "다른 참가자들의 투표완료를 기다리는 중입니다",
    "monitor.waiting.quest.nextr" : "재투표 결정을 기다리는 중입니다",
    "monitor.splash.loading": "이벤트 조회 중 ...",
    "monitor.splash.badaddr": "유효한 주소가 아닙니다.",
    "monitor.splash.checkin": "체크인 후 이용하실 수 있습니다.",
    "monitor.splash.closed": "이벤트가 종료되었습니다.",
    "monitor.splash.thankyou": "참여해주셔서 감사합니다.",

    "viewer.button.qst.next" : "다음",
    "viewer.button.qst.prev" : "이전",

    "entrance.qrcode.for.transfer" : "다른 기기에서 열기",
    "entrance.qrcode.instruct.user" : "QR코드를 터치하거나 다른 기기의 카메라앱으로 스캔하세요.",
    "entrance.qrcode.instruction" : "휴대하신 기기의 카메라앱으로 스캔하세요.",

    "voter.schedule.day-1": "어제",
    "voter.schedule.day+0": "오늘",
    "voter.schedule.day+1": "내일",
    "voter.schedule.soon": "잠시 후",
    "voter.schedule.wait": "참가하시려면 이벤트가 오픈될 때까지 기다려주세요.",
    "voter.checkin.invitation": "'{1}'님이 당신을\n'{2}'\n이벤트에 초대하였습니다.",
    "voter.checkin.direction.nc": "참가자격 확인을 위해 성명 및 패스코드를 입력해주세요",
    "voter.checkin.direction.n": "참가자격 확인을 위해 성명을 입력해주세요",
    "voter.checkin.label.name": "성명",
    "voter.checkin.label.code": "식별코드",
    "voter.checkin.button": "이벤트 참가하기",
    "voter.checkin.err.pnotfound": "해당 이벤트를 찾을 수 없습니다.",
    "voter.checkin.err.pnotopen": "해당 이벤트는 아직 열리지 않았습니다.",
    "voter.checkin.err.pclosed": "해당 이벤트는 이미 종료되었습니다.",
    "voter.checkin.err.vnotfound": "해당 이름이 등록되어 있지 않습니다.",
    "voter.checkin.err.vexlimit": "최대 참가 인원수({1})를 초과하여 입장이 불가합니다.",
    "voter.checkin.nav.label": "Pollerz.net 소개가 필요하신가요?",
    "voter.checkin.nav.button": "홈페이지 방문",
    "voter.vcast.err.duplicate": "이미 투표하셨습니다.",

    "entrance.poller.title": "이벤트의 주최자로서,\n'{1}'\n이벤트를 개최합니다\n",
    "entrance.poller.title2": "휴대하신 다른 기기를 이용하여,\n'{1}'\n 이벤트를 개최합니다",
    "entrance.manager.title": "주최자를 대리하는 이벤트 매니저로서,\n'{1}'\n이벤트를 개최합니다\n",
    "entrance.rehearsal.title": "안건 및 평가기준의 테스트를 위해,\n'{1}'\n 이벤트를 가상으로 개최합니다.",
    "entrance.rehearsal.poller": "주최자",
    "entrance.rehearsal.nvoters": "투표 참가자 수",
    "entrance.rehearsal.vmethod": "투표 시뮬레이션",
    "entrance.staff.title": "주최자가 임명한 일반 스태프로서,\n'{1}'\n이벤트의 참가자들을 현장지원합니다\n",
    "entrance.staff.title2": "휴대하신 다른 기기를 이용하여,\n'{1}'\n이벤트의 참가자들을 현장지원합니다",
    "entrance.voteself.title": "투표권자들 중 한 명으로서,\n'{1}'\n이벤트의 투표에 참여합니다",
    "entrance.voteself.title2": "휴대하신 다른 기기를 이용하여,\n'{1}'\n이벤트의 투표에 참여합니다",
    "entrance.trial.asvoter.title": "서비스 체험을 위한\n\"{1}\"\n이벤트에 당신을 초대합니다.",
    "entrance.trial.aspoller.title": "서비스 체험을 위한\n\"{1}\"\n이벤트를 가상으로 주최합니다.",
    "entrance.trial.aspoller.instr0": "이벤트를 계획하고 \n 안건들을 준비하였습니다.",
    "entrance.trial.aspoller.instr1": "참가자들을 초대하였습니다.",
    "entrance.trial.aspoller.instr2": "이벤트를 시작합니다.",
    "entrance.trial.example.name": "홍길동",
    "entrance.trial.title": "\"{1}\"\n이벤트와 안건들이 준비되었습니다.\n이제 이벤트를 시작합니다.",
    "entrance.trial.label": "이벤트 주최자로서 당신의 이름을 입력해주세요",
    "entrance.button.openevent": "이벤트 개최하기",
    "entrance.button.startevent": "이벤트 시작하기",
    "entrance.button.assistvoters": "참가자 지원 화면열기",
    "entrance.button.voteself": "투표하기",
    "entrance.simulated": "본 이벤트의 투표결과는 가상 시뮬레이션으로서, 저장되지 않습니다.",

};

export default localeStrMap;
