import React from "react";
import { useSelector } from "react-redux";
import dayjs, { locale } from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import PzProgress from "../common/PzProgress";
import PzSymbol from "../common/PzSymbol";
import PzTabs from "../common/PzTabs";
import PzTable from "../common/PzTable";
import PzText from "../common/PzText";
import PollPqstAnswer from "./PollPqstAnswer";
import PollPqstGoal from "./PollPqstGoal";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";
import { ReactComponent as IconUserVoter } from "../resource/icons/pz-user-voter.svg";
import { ReactComponent as IconToolTarget } from "../resource/icons/pz-tool-target.svg";
import { ReactComponent as IconTimeClock } from "../resource/icons/pz-time-clock.svg";

const PollPqstResult = {
    List:       PollPqstResultList,
    Card:       PollPqstResultCard,

    Final:      PollPqstResultFinal,
    RRank:      PollPqstResultRoundRank,
    VTable:     PollPqstResultVoteTable,

    getRoundAnswers:    getRoundAnswers,
    getTopAnswers:      getTopAnswers,
};
export default PollPqstResult;


function PollPqstResultList({ poll, size='xl', className='', initSelected='' }) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [qSelected, setQSelected] = React.useState(initSelected);   // active 'qguid'
    if (qSelected === '' && poll?.pqstlist?.length === 1) {
        setQSelected(poll.pqstlist[0].qguid);
    }

    function callForPqstToSelect(qguid) {
        if (!qguid) {
            setQSelected('');
        } else if (qguid === qSelected) {
            setQSelected('');
        } else {
            setQSelected(qguid);
        }
    }

    return (
        <>
            { poll && poll.pqstlist && poll.pqstlist.map((pqst, qidx) => (
                <PollPqstResultCard key={qidx} poll={poll} qidx={qidx} size={size} className={className}
                    qSelected={qSelected} setQSelected={setQSelected} callToSelect={callForPqstToSelect} />
            )) }
        </>
    );
}

function PollPqstResultCard({ poll, qidx, size='xl', className='', qSelected='', callToSelect=undefined }) {

    const localeMap = utils.selectMapByLocale(poll?.locale);
    const pqst = (poll.pqstlist && qidx >= 0 && qidx < poll.pqstlist.length ? poll.pqstlist[qidx] : undefined);
    const q_selected = (pqst?.qguid === qSelected);
    const [fold4Pqst, setFold4Pqst] = React.useState(true);
    const nRounds = (pqst?.rresult ? pqst.rresult.length-1 : 0);

    function onClickToCard(e) {
        e.stopPropagation();
        if (typeof callToSelect === 'function') callToSelect(pqst.qguid);
    }

    function onClickToSymbol(e) {
        console.log(pqst);
    }

    function getTabLabels(pqs) {
        let labels = [
            localeMap["monitor.result.finalresult"], 
            localeMap["monitor.result.votecounts"],
        ];
        for (let i = 1; i <= nRounds; i++) {
            labels.push( `R${i}` );
        }
        return labels;
    }

    function getTabPanels(pqs) {
        let panels = [
            <PollPqstResultFinal pqst={pqst} size={SSIZE} />,
            <PollPqstResultVoteTable pqst={pqst} size={SSIZE} className="w-full m-0" />,
        ];
        for (let i = 1; i <= nRounds; i++) {
            panels.push( <PollPqstResultRoundRank pqst={pqst} qround={i} size={SSIZE} topNonZero={true} withExtra={true} /> );
        }
        return panels;
    }

    let px = " ", py = " ", gap = " gap-0 ";
    if      (size === '4xl') { px = " px-4 "; py = " py-4 "; gap=" gap-4 "; }
    else if (size === '3xl') { px = " px-3 "; py = " py-3 "; gap=" gap-3 "; }
    else if (size === '2xl') { px = " px-3 "; py = " py-3 "; gap=" gap-3 "; }
    else if (size ===  'xl') { px = " px-3 "; py = " py-3 "; gap=" gap-3 "; }
    else if (size ===  'lg') { px = " px-2 "; py = " py-2 "; gap=" gap-2 "; }
    else if (size ===  'md') { px = " px-2 "; py = " py-2 "; gap=" gap-2 "; }
    else if (size ===  'sm') { px = " px-1 "; py = " py-1 "; gap=" gap-1 "; }
    else                     { px = " px-1 "; py = " py-1 "; gap=" gap-1 "; }
    let CLASSNAME = " w-full bg-white border border-slate-800 rounded-2xl flex flex-col justify-start items-start ";
    const SSIZE = utils.getSizeRelative(size, -1), SSSIZE = utils.getSizeRelative(size, -2);

    if (!pqst) return (<div>Undefined pqst</div>);

    return (
        <div className={CLASSNAME + py + gap + className} onClick={onClickToCard} >  

            <div className={"w-full flex flex-row justify-between items-center hover:cursor-pointer " + px + gap} >  
                <div className={"w-full flex flex-row justify-start items-center " + gap} >  
                    <PzSymbol.Text text={'Q'+(qidx+1)} size={SSIZE} 
                        className="!bg-purple-900 !text-white hover:cursor-pointer"
                        onClick={onClickToSymbol} />
                    <PzText.Touch text={pqst.question} size={size} truncate={!q_selected} />
                </div>
                { q_selected &&
                    <div className={"w-auto flex flex-row justify-end items-center " + gap} >  
                        <PzButton.Folder folded={fold4Pqst} setFolded={setFold4Pqst} size={size} />
                    </div>
                }
            </div>

            { q_selected && !fold4Pqst &&
            <>
                <div className="w-full h-[1px] bg-slate-200"></div>
                <PollPqstAnswer.List poll={poll} qidx={qidx} preview={true} size={SSIZE}
                    selectable={true} selection={[]} className="w-full px-10 "
                    onClick={(e)=>{e.stopPropagation();}} />
            </>
            }

            { q_selected && 
            <>
                <div className="w-full h-[1px] bg-slate-800"></div>
                <PollPqstGoal.Footer   poll={poll} qidx={qidx} initFolded={true} size={SSIZE}
                    ranswers={pqst.answers.slice(1)} className={'pl-10 pr-3'} onClick={(e)=>{e.stopPropagation();}} />
            </>
            }

            { q_selected &&
            <>
                <div className="w-full h-[1px] bg-slate-800"></div>
                <div className="w-full pl-10 pr-5 flex flex-col justify-start items-start" onClick={(e)=>{e.stopPropagation();}} >
                    <PzTabs.Custom size={SSSIZE} className="w-full" 
                        labels={getTabLabels(pqst)} panels={getTabPanels(pqst)} />
                </div>
            </>
            }

        </div>
    )
}

function PollPqstResultFinal({pqst, size='xl', withExtra=true, className=''}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const utzone = useSelector((state)=>state.ucfg.timezone); // user's current timezone selection
    const TEXTSIZE = 'text-' + size;

    function getNVoters(a) { return (a.astat ? pqst.rresult[Number(a.astat.charAt(0))].natt : 0); }
    function getRVCount(a) { return (a.astat ? a.rvcount[Number(a.astat.charAt(0))] : 0); }
    function getPercent(a) { return (getRVCount(a) * 100 / getNVoters(a)).toFixed(1); }

    let px = " ", py = " ", gap = " gap-0 ";
    if      (size === '4xl') { px = " px-5 "; py = " py-5 "; gap=" gap-4 "; }
    else if (size === '3xl') { px = " px-5 "; py = " py-5 "; gap=" gap-4 "; }
    else if (size === '2xl') { px = " px-4 "; py = " py-4 "; gap=" gap-3 "; }
    else if (size ===  'xl') { px = " px-4 "; py = " py-4 "; gap=" gap-3 "; }
    else if (size ===  'lg') { px = " px-3 "; py = " py-3 "; gap=" gap-2 "; }
    else if (size ===  'md') { px = " px-2 "; py = " py-2 "; gap=" gap-2 "; }
    else if (size ===  'sm') { px = " px-1 "; py = " py-1 "; gap=" gap-1 "; }
    else                     { px = " px-1 "; py = " py-1 "; gap=" gap-1 "; }

    const SSIZE = utils.getSizeRelative(size,-1), SSSIZE = utils.getSizeRelative(size,-2);
    const PWIDTH  = (size==='4xl' || size==='3xl' ? "6rem" : "4rem")
    const GAPSIZE = (size==='4xl' || size==='3xl' ? " gap-4 " : " gap-2 ")

    return (
        pqst.chosen.length > 1 ?
            <div className={"w-full flex flex-col justify-start items-stretch " + GAPSIZE + className}>
                { pqst.chosen.slice(1).map((a)=>{ return (
                    <div key={a.aidx} className={"w-full flex flex-row justify-between items-center "}>
                        <label className={TEXTSIZE+" text-left font-bold"}>{a.aname}</label>
                        <div key={a.aidx} className={"flex flex-row justify-end items-center gap-4 "}>
                            <label className={TEXTSIZE+" text-right"}>{getRVCount(a)} / {getNVoters(a)}</label>
                            <label className={TEXTSIZE+" text-right w-[" + PWIDTH + "]"}>{getPercent(a)}%</label>
                            <div className="w-auto flex flex-row justify-end items-center">
                                <PzSymbol.Text text={'R'+a.astat[0]} size={SSIZE} op50={true} className="!bg-purple-900 !text-white" />
                            </div>
                        </div>
                    </div>
                )}) }
                { withExtra && 
                    <div className="w-full flex flex-row justify-end items-center gap-5">
                        <div className="w-auto flex flex-row justify-end items-center gap-2">
                            <PzButton.Icon Icon={IconTimeClock} size={SSIZE} forProperty={true} noBackground={true} noBorder={true} />
                            <label className={'text-' + size}>
                                {utils.getTimeInUserTimezone(pqst.startedat, "h:mm:ss A", utzone)} 
                                &nbsp; ~ &nbsp; 
                                {utils.getTimeInUserTimezone(pqst.stoppedat, "h:mm:ss A", utzone)}
                            </label>
                        </div>
                    </div>
                }
            </div>
        :
            <h4 className="text-slate-900">{localeMap["monitor.result.nochosen."+pqst.qkind]}</h4>
    );
}

function PollPqstResultVoteTable({pqst, size='xl', withExtra=true, className=''}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const utzone = useSelector((state)=>state.ucfg.timezone); // user's current timezone selection
    const [data, columns] = getResultTableDataAndColumns(pqst);
    const TEXTSIZE = 'text-' + size;

    function getResultTableDataAndColumns(pqst) {
        let columns = [], data = [];
        if (!pqst || !pqst.answers) return [data, columns];
        let nRounds = pqst.rresult.length-1;

        // create 'columns' array
        columns.push({title:'Answer', key: 'answer', render:({answer,chosen})=>{return (<p className={chosen ? 'font-bold' : ''}>{answer}</p>)}});
        columns.push({title:'AStat', key: 'astat', hidden:true});
        for (let r = 1; r <= nRounds; r++) {
            columns.push({
                title:`R${r}`, key:`r${r}`, align:'right', hidden:false, 
                onClick:(row,key)=>{},
            });
        }
        // columns.push({title:'✓', key:'chosen', align:'center', checksign:true, hidden:false});

        // append each candidate into 'data' array
        for (let i = 1; i < pqst.answers.length; i++) { // 0-th answer is 'WAIVER'
            let a = pqst.answers[i];
            let adata = { answer: a.aname, astat:a.astat };
            for (let r = 1; r <= nRounds; r++) {   // Round 0 is reserved
                if (a.rvcount && r < a.rvcount.length) {
                    adata[`r${r}`] = a.rvcount[r];
                } else {
                    adata[`r${r}`] = undefined;
                }
            }
            let chosen = false;
            for (let c = 0; c < pqst.chosen.length; c++) {
                if (a.aname === pqst.chosen[c].aname) { chosen = true; break; }
            }
            adata['chosen'] = chosen;
            if (chosen) {
                adata['emphasized_column'] = `r${a.rvcount.length-1}` // the last round
            }
            data.push(adata);
        }
        // append WAIVER into 'data' array
        if (pqst.answers && pqst.answers.length >= 1) {
            let wdata = { answer: localeMap['poll.xresult.label.residual'], astat:'', footer: true };
            for (let r = 1; r <= nRounds; r++) {   // Round 0 is reserved
                wdata[`r${r}`] = pqst.rresult[r].nblank;
            }
            data.push(wdata);
        }
        // append TOTAL into 'data' array
        if (pqst.rresult && pqst.rresult.length >= 1) {
            let tdata = { answer: localeMap['poll.xresult.label.total'], astat:'', footer: true };
            for (let r = 1; r <= nRounds; r++) {   // Round 0 is reserved
                let rr = pqst.rresult[r];
                tdata[`r${r}`] = (rr.nc2s === 1 ? `${rr.natt}` : `${rr.nc2s} × ${rr.natt}`);
            }
            data.push(tdata);
        }
        return [data, columns];
    }

    const SSIZE = utils.getSizeRelative(size,-1), SSSIZE = utils.getSizeRelative(size,-2);

    return (
        <div className={"w-full flex flex-col justify-start items-stretch gap-2 " + className}>

            <PzTable data={data} columns={columns} className={className} />

            { withExtra && 
                <div className="w-full mt-2 flex flex-row justify-end items-center gap-5">
                    <div className="w-auto flex flex-row justify-end items-center gap-2">
                        <PzButton.Icon Icon={IconTimeClock} size={SSSIZE} forProperty={true} />
                        <label className={'text-'+SSIZE}>
                            {utils.getTimeInUserTimezone(pqst.startedat, "HH:mm:ss", utzone) + ' ~ ' + utils.getTimeInUserTimezone(pqst.stoppedat, "HH:mm:ss", utzone)}
                        </label>
                    </div>
                </div>
            }

        </div>
    );

}

function PollPqstResultRoundRank({pqst, qround, size='xl', className='', topView=true, topNonZero=false, withExtra=false}) {
    const utzone = useSelector((state)=>state.ucfg.timezone); // user's current timezone selection
    const nc2s = (pqst ? pqst.nwanted - (pqst.chosen?.length - 1) : 0);
    const pchosen = get_chosen_in_previous_rounds();
    const rr = (pqst && qround > 0 && qround < pqst.rresult.length ? pqst.rresult[qround] : null);  // round result
    const rAnswers = getRoundAnswers(pqst, qround);
    const tAnswers = getTopAnswers(rAnswers, (topNonZero ? rAnswers.length : 0));  // NON-ZERO only
    const TEXTSIZE = 'text-' + size;

    function get_chosen_in_previous_rounds() {
        let chosen = [];
        for (let i = 1; pqst && i < pqst.chosen.length; i++) {
            let a = pqst.chosen[i]; // { aidx:1, aname:'', adesc:null, astat:'1-', rvcount:[...], rcount:99 }
            if (a.astat[0] < String(pqst.qround) && a.astat[1] === 'O') chosen.push(a.aname);
        }
        return chosen;
    }

    function get_class_for_answer(answer) {
        if      (answer.astat === `${qround}O`) return " text-blue-800 font-extrabold ";
        else if (answer.astat === `${qround}X`) return " text-slate-400 ";
        else return " ";
    }

    let ssize = size, sssize = size, px = " ", py = " ", gap = " gap-0 ";
    if      (size === '2xl') { ssize="xl"; sssize="md"; px = " px-4 "; py = " py-4 "; gap=" gap-3 "; }
    else if (size ===  'xl') { ssize="lg"; sssize="sm"; px = " px-4 "; py = " py-4 "; gap=" gap-3 "; }
    else if (size ===  'lg') { ssize="md"; sssize="xs"; px = " px-3 "; py = " py-3 "; gap=" gap-2 "; }
    else if (size ===  'md') { ssize="sm"; sssize="xs"; px = " px-2 "; py = " py-2 "; gap=" gap-2 "; }
    else if (size ===  'sm') { ssize="xs"; sssize="xs"; px = " px-1 "; py = " py-1 "; gap=" gap-1 "; }
    else                     { ssize="xs"; sssize="xs"; px = " px-1 "; py = " py-1 "; gap=" gap-1 "; }

    if (!pqst) {
        return (<h3>pqst is null</h3>);
    } else if (!rr) {
        return (<div>&nbsp;</div>);
    }

    const SSIZE = utils.getSizeRelative(size, -1);
    
    return (
        <div className={"w-full max-h-[20rem] overflow-y-scroll " + className}>
            <div className="w-full flex flex-col justify-start items-stretch gap-2 ">
                { topView && pqst.qkind != 'A' ?
                    tAnswers.map((a)=>{ return (
                        <div key={a.aidx} className="flex flex-row justify-between items-center">
                            <label className={TEXTSIZE + get_class_for_answer(a)}>{a.aname}</label>
                            <PzProgress className="w-[30%]" size={SSIZE}
                                count={a.rcount} total={rr.natt} result={a.astat.substring(1,2)} />
                        </div>
                    ); })
                :
                    rAnswers.map((a)=>{ return (
                        <div key={a.aidx} className="flex flex-row justify-between items-center">
                            <label className={TEXTSIZE + get_class_for_answer(a)}>{a.aname}</label>
                            <PzProgress className="w-[30%]" size={SSIZE}
                                count={a.rcount} total={rr.natt} result={a.astat.substring(1,2)} />
                        </div>
                    ); })
                }
                { withExtra && 
                    <div className="w-full mt-2 flex flex-row justify-end items-center gap-5">
                        <div className="w-auto flex flex-row justify-end items-center gap-2">
                            <PzButton.Icon Icon={IconUserVoter} size={sssize} forProperty={true} />
                            <label className={'text-'+ssize}>{rr.natt}</label>
                        </div>
                        <div className="w-auto flex flex-row justify-end items-center gap-2">
                            <PzButton.Icon Icon={IconToolTarget} size={sssize} forProperty={true} />
                            <label className={'text-'+ssize}>{rr.nc2s + ' / ' + rr.nc4s}</label>
                        </div>
                        <div className="w-auto flex flex-row justify-end items-center gap-2">
                            <PzButton.Icon Icon={IconTimeClock} size={sssize} forProperty={true} />
                            <label className={'text-'+ssize}>
                                {utils.getTimeInUserTimezone(rr.startedat, "HH:mm:ss", utzone) + ' ~ ' + utils.getTimeInUserTimezone(rr.stoppedat, "HH:mm:ss", utzone)}
                            </label>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

function getRoundAnswers(pqst, qround) {
    let answers = []; // list of selectable answers for the current round
    for (let i = 1; pqst && i < pqst.answers?.length; i++) {
        let a = pqst.answers[i];  // 'aidx', 'aname', 'adesc', 'astat', and 'rvcount'
        let rcount = (a.rvcount?.length > qround ? a.rvcount[qround] : 0);
        if (a.astat.charAt(0) >= qround.toString()) answers.push({ ...a, rcount: rcount });
    }
    return answers;
}

function getTopAnswers(ranswers, count=0) {
    let nonzeros = [];
    for (let i = 0; ranswers && i < ranswers.length; i++) {
        let a = ranswers[i]; // { aidx:1, aname:'', adesc:null, astat:'1-', rvcount:[...], rcount:99 }
        if (a.rcount > 0) nonzeros.push(a);
    }
    if (nonzeros.length === 0) return nonzeros;
    nonzeros.sort((a,b)=>{return -(a.rcount - b.rcount);})
    if (nonzeros.length > 3 && nonzeros[nonzeros.length-1].rcount < nonzeros[2].rcount / 5) {
        // if (LEAST < 3RD / 5), then remove LEAST answers from the list
        let pos = -1;
        for (let i = nonzeros.length-1; i >= 2; i--) {
            if (nonzeros[i].rcount < nonzeros[2].rcount / 5) pos = i;
            else break;  // note that 'a.rvcount' might be null (when 'qstatus' === 'Vt')
        }
        if (pos > 0) nonzeros = nonzeros.slice(0, pos);
    }
    let DESIRED_LEN = (count > 0 ? count : 5);
    if (nonzeros.length > DESIRED_LEN) {
        // if len(NONZEROS) > MAX_LIMIT, then remove answers with smaller counts
        let pos = -1, last_rcount = nonzeros[0].rcount;
        for (let i = 0; i < nonzeros.length; i++) {
            if (i >= DESIRED_LEN && nonzeros[i].rcount < last_rcount) { pos = i; break; }
            last_rcount = nonzeros[i].rcount;
        }
        if (pos > 0) nonzeros = nonzeros.slice(0, pos);
    }
    return nonzeros;
}

