import React from 'react';
import { useSelector } from "react-redux";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "../common/PzBasicModal"
import PzButton from "../common/PzButton"
import { ReactComponent as IconQRCode } from "../resource/icons/pz-qrcode.svg";

const EntranceToVoteSelf = {
    Dialog:      EntranceToVoteSelfDialog,
    Module:      EntranceToVoteSelfModule,
};

export default EntranceToVoteSelf;


function EntranceToVoteSelfDialog({poll, onClose=()=>{}}) {
    return (
        <PzBasicModal onClose={onClose}>
            <EntranceToVoteSelfModule poll={poll} onClose={onClose} />
        </PzBasicModal>
    )
}

function EntranceToVoteSelfModule({poll, onClose=()=>{}}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [showQR, setShowQR] = React.useState(false);
    const ttext = localeMap[ (showQR ? 'entrance.voteself.title2' : 'entrance.voteself.title') ];
    const title = utils.completeMessageText((ttext ? ttext : ""), [poll?.ptitle]);

    function onClickForButton() {
        const url = appConfig.getBaseUrlForAPI() + '/p/run?p=' + poll?.pguid ;
        const win = window.open(url.replace(":80", ":3000"), '_blank');
        if (win != null) win.focus();
        if (typeof onClose === 'function') onClose();
    }

    return (
        <div className="w-full min-w-[24rem] max-w-[32rem] flex flex-col justify-start items-center bg-[#4182d2] p-10 gap-10" >
            <div className="w-full h-[6rem] flex flex-col justify-start items-center gap-5">
                <div className="w-full flex flex-col justify-start items-center gap-1 text-white">
                    { title.split("\n").map((part,idx)=>{return (<h2 key={idx}>{part}</h2>);}) }
                </div>
            </div>
            { !showQR ? 
                <div className="w-[28rem] aspect-square overflow-clip">
                    <img src={appConfig.getBaseUrlForAPI() + '/static/images/c_voter_vote.png'} alt="" 
                        onClick={onClickForButton} width={600} height={600} className="object-contain object-center"/>
                </div>
            :
                <div className="w-[28rem] aspect-square border border-white rounded-[3rem] overflow-clip ">
                    <img src={`${appConfig.getBaseUrlForAPI()}/api/entrance/event?p=${poll?.pguid}&m=poller`} 
                        alt="" width={600} height={600} className="object-contain object-center"/>
                </div>
            }
            <div className={"w-full flex flex-row items-center gap-5 relative " + (showQR ? "justify-between" : "justify-center")}>
                <PzButton.Std 
                    text={showQR ? '_' : localeMap["entrance.button.voteself"]} 
                    size='xl' onClick={onClickForButton} className={(showQR ? "invisible" : "")} transparent={true} />
                { showQR && <label className="text-md">{localeMap["entrance.qrcode.instruction"]}</label> }
                <PzButton.Icon Icon={IconQRCode} size='xl' 
                    className="absolute left-0 bottom-0" transparent={true} 
                    toggle={true} onClick={setShowQR} />
            </div>
        </div>
    )
}

