import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PollPqstResult from "../../event/PollPqstResult";
import UserSplash from "../UserSplash";

export default function PzAdminQstInfo() {
    const location = useLocation();
    const qguid    = location?.state?.qguid;
    const archived = Boolean(location?.state?.archived);
    const [pinfo, setPInfo] = React.useState({});
    const [qidx, setQidx] = React.useState(-1);
    const [qinfo, setQInfo] = React.useState({qguid:qguid});
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const utzone = useSelector((state)=>state.ucfg.timezone);  // timezone from user settings

    const fetchQstInfo = ()=>{
        if (!uauth?.ispza) return;
        appConfig.getAPI().post("/api/pza/e/qinfo", {qguid:qguid, archived:archived})
        .then((response)=>{
            // console.log("PzAPI 'pza/e/qinfo' fetched : ", response.data);
            let pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            setPInfo(pzdata.resource.poll);
            setQInfo(pzdata.resource.pqst);
            setQidx(pzdata.resource.qidx);
            // console.log("QInfo:", pzdata.resource);
        })
        .catch((error)=>{
            console.log("PzAPI 'pza/e/qinfo' error : ", error);
        })
        .finally(()=>{});
    };
    
    React.useEffect(() => {
        if (!uauth?.ispza) { navigate("/"); return; }
        fetchQstInfo();
    }, []);

    if (!uauth || !uauth.ispza) {
        return (<UserSplash msg={"Unauthorized access"} />);
    }  

    return (
        <PzArea.Container>
            <PzArea.AdminBoard >

                <h2>{`PzA - ${archived ? 'Archived ' : ''}Question Info`}</h2>

                <div className="grid grid-cols-[100px_1fr] gap-x-5 gap-1">
                    <h5>QSeq</h5>      {qinfo.qseq ? <p>{qinfo.qseq}</p> : <p>{''}</p>}
                    <h5>QGuid</h5>     {qinfo.qguid ? <p>{qinfo.qguid.slice(0,32)} {qinfo.qguid.slice(32)}</p> : <p>{''}</p>}
                    <h5>Type</h5>  
                    <div className="grid grid-cols-[45px_1fr_45px_1fr] gap-1">
                        <p>Kind:</p> <p>{qinfo.qkind}</p>
                        <p>Status:</p> <p>{qinfo.qstatus}</p>
                    </div>
                    <h5>Question</h5>   <p>{qinfo.question || ""}</p>
                    <h5>Options</h5>    <p className="truncate">{JSON.stringify(qinfo.qoption)}</p>
                    <h5>Plan Time</h5>  
                    <div className="grid grid-cols-[60px_1fr_60px_1fr] gap-1">
                        <p>Created:</p>  <p>{utils.getTimeInUserTimezone(qinfo.createdat, "MM/DD HH:mm:ss", utzone, true)}</p>
                        <p>Updated:</p>  <p>{utils.getTimeInUserTimezone(qinfo.updatedat, "MM/DD HH:mm:ss", utzone, true)}</p>
                    </div>
                    <h5>Run Time</h5>  
                    <div className="grid grid-cols-[60px_1fr_60px_1fr] gap-1">
                        <p>Opened:</p>  <p>{utils.getTimeInUserTimezone(qinfo.openedat, "MM/DD HH:mm:ss", utzone, true)}</p>
                        <p>Closed:</p>  <p>{utils.getTimeInUserTimezone(qinfo.closedat, "MM/DD HH:mm:ss", utzone, true)}</p>
                    </div>
                    { qinfo.answers &&
                        <>
                            <h5>Answers</h5> 
                            <PollPqstResult.Card poll={pinfo} qidx={qidx} size='lg' qSelected={location?.state?.qguid} />
                        </>
                    }
                </div>
                <div className="flex flex-row justify-between">
                    <PzButton.Std text='qinfo' size='md' onClick={()=>console.log(qinfo)} />
                </div>

            </PzArea.AdminBoard>
        </PzArea.Container>
    );
}
