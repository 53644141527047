import React from "react";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";

const EMWithPzServer = {
    updateEventInfo:        updateEventInfo,
    isEventChanged:         isEventChanged,

    fetchVoterList:         fetchVoterList,
    setPollStatus:          setPollStatus,
    setPqstStart:           setPqstStart,
    setPqstStatus:          setPqstStatus,
    setNextPqstStart:       setNextPqstStart,
    setPqstStatusDone:      setPqstStatusDone,
    setPollStatusDone:      setPollStatusDone,
    setPollStatusClosed:    setPollStatusClosed,
    setPollPqstReset:       setPollPqstReset,

    checkinAsVoter:         checkinAsVoter,
    checkinAsPoller:        checkinAsPoller,
    castVoteAsVoter:        castVoteAsVoter,
    evaluateRoundResult:    evaluateRoundResult,
};

export default EMWithPzServer;


function updateEventInfo(pguid, poll, setPoll, apipath, xrole, setErrMsg=()=>{}) {
    if (!pguid) return; 
    if (poll && (poll.pstatus >= 'Xc' && poll.pstatus <= 'Xx')) {
        if (typeof setErrMsg === 'function') setErrMsg('closed');
        return;
    }
    // if (!poll || !live) return; 
    appConfig.getAPI().post(apipath, {pguid: pguid, xrole: xrole})
    .then((response) => {
        const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
        const new_poll = pzdata.resource;
        if (isEventChanged(poll, new_poll)) {
            if (setPoll) setPoll(new_poll);
        }
        // console.log("PollViewer", pinfo);
        if (typeof setErrMsg === 'function') setErrMsg('');
    })
    .catch((error) => {
        console.log("PzAPI '" + apipath + "' error : ", error);
        let respmsg = error.response?.data?.respmsg;
        if (respmsg && (respmsg.includes('pguid invalid') || respmsg.includes('pguid not found'))) {
            if (typeof setErrMsg === 'function') setErrMsg('badaddr');
            // setLive(false);
        } else if (respmsg && (respmsg.includes('checkin required'))) {
            if (typeof setErrMsg === 'function') setErrMsg('checkin');
            // setLive(false);
        } else {
            if (typeof setErrMsg === 'function') setErrMsg(respmsg ? respmsg : '');
        }
    })
    .finally(() => {});
}


function isEventChanged(oe, ne) {
    if (!ne) return false;
    if (!oe || ne.changed) { return true; } // PzServer want to enforce the real-time update of everything
    if (oe.pstatus !== ne.pstatus) { return true; }
    if (oe.natt !== ne.natt) { return true; }
    if (oe.qactive !== ne.qactive) { return true; }
    if (oe.answers !== ne.answers || oe.chosen !== ne.chosen) { return true; }
    const oq = (oe.qactive >= 0 && oe.pqstlist ? oe.pqstlist[oe.qactive] : null);
    const nq = (ne.qactive >= 0 && ne.pqstlist ? ne.pqstlist[ne.qactive] : null);
    if ((!oq && nq) || (oq && !nq)) { return true; }
    else if (oq && nq) {
        if (oq.qpguid !== nq.qpguid) { return true; }
        if (oq.qround !== nq.qround) { return true; }
        if (oq.qstatus !== nq.qstatus) { return true; }
        if (oq.nvcast !== nq.nvcast) { return true; }
    }
    return false;
}

// ----------------------------------------------------------------------------
// as Poller / Staff
// ----------------------------------------------------------------------------

function fetchVoterList(poll, opts, setPgData) {
    let api = (poll.pstatus < 'Ax' ? "/api/mp/voter/list" : "/api/xps/voter/list");
    appConfig.getAPI().post(api, {...opts, xrole: poll.xrole})
    .then((response) => {
        const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
        const pgdata = pzdata.resource; // {total:100, page:0, pageSize:10, pageData:[...]}
        if (setPgData) setPgData(pgdata);
    })
    .catch((error) => {
        console.log("PzAPI 'voter/list' error : ", error);
    })
    .finally(() => {
    });
}

function setPollStatus(poll, setPoll, status, setLoading=()=>{}) {
    if (!poll || !(poll.xrole==='P'||poll.xrole==='A'||poll.xrole==='M')) { console.log(`xrole invalid (${poll.xrole})`); return; }
    setLoading(true);
    appConfig.getAPI().post('/api/xpp/pstatus', {pguid: poll.pguid, pstatus: status})
    .then((response) => {
        const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
        const pinfo = pzdata.resource;
        console.log("pstatus return : ", pinfo);
        if (setPoll) setPoll(pinfo);
        console.log(`PzAPI 'xpp/pstatus' (${poll.pstatus}=>${status}) success `);
    })
    .catch((error) => {
        console.log(`PzAPI 'xpp/pstatus' (${poll.pstatus}=>${status}) error : `, error.response?.data);
    })
    .finally(() => { setLoading(false); });
}

function setPqstStart(poll, setPoll, qidx, setLoading=()=>{}) {
    if (!poll || !(poll.xrole==='P'||poll.xrole==='A')) { console.log(`xrole invalid (${poll.xrole})`); return; }
    if (qidx < 0 || qidx >= poll.pqstlist.length) {
        console.log('setPqstStart() qidx invalid : ', qidx);
        return;
    }
    let qguid = poll.pqstlist[qidx].qguid;
    setLoading(true);
    appConfig.getAPI().post('/api/xpp/qstart', {pguid: poll.pguid, qguid: qguid})
    .then((response) => {
        const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
        const qinfo = pzdata.resource;
        let pqstlist = [ ...poll.pqstlist ];
        pqstlist[qidx] = qinfo;
        if (setPoll) setPoll({ ...poll, pqstlist:pqstlist, qactive:qidx });
        console.log(`PzAPI 'xpp/qstart' success `);
        // console.log(new_poll);
    })
    .catch((error) => {
        console.log(`PzAPI 'xpp/qstart' error : `, error);
    })
    .finally(() => { setLoading(false); });
}

function setPqstStatus(poll, setPoll, qstatus, setLoading=()=>{}) {
    if (!poll || !(poll.xrole==='P'||poll.xrole==='A')) { console.log(`xrole invalid (${poll.xrole})`); return; }
    if (poll.qactive < 0 || poll.qactive >= poll.pqstlist.length) {
        console.log('qactive invalid : ', poll.qactive);
        return;
    }
    const prev_qstatus = poll.pqstlist[poll.qactive].qstatus;
    const active_qguid = poll.pqstlist[poll.qactive].qguid;
    appConfig.getAPI().post('/api/xpp/qstatus', {qguid: active_qguid, qactive:poll.qactive, qstatus: qstatus})
    .then((response) => {
        const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
        const qinfo = pzdata.resource;
        let new_poll = { ...poll, pqstlist:[...poll.pqstlist] }
        new_poll.pqstlist[poll.Active] = qinfo;
        if (setPoll) setPoll(new_poll);
        console.log(`PzAPI 'xpp/qstatus' (${prev_qstatus}=>${qstatus}) success `);
        // console.log(new_poll);
    })
    .catch((error) => {
        console.log(`PzAPI 'xpp/qstatus' (${prev_qstatus}=>${qstatus}) error : `, error);
    })
    .finally(() => {});
}

function setNextPqstStart(poll, setPoll, setLoading=()=>{}) {
    if (!poll || !(poll.xrole==='P'||poll.xrole==='A')) { console.log(`xrole invalid (${poll.xrole})`); return; }
    let next_qactive = poll.qactive + 1;
    if (next_qactive < 0 || next_qactive >= poll.pqstlist.length) {
        console.log('qactive invalid : ', poll.qactive);
        return;
    }
    const active_qguid = poll.pqstlist[next_qactive].qguid;
    appConfig.getAPI().post('/api/xpp/qstart', {pguid: poll.pguid, qguid: active_qguid})
    .then((response) => {
        const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
        const qinfo = pzdata.resource;
        let new_poll = { ...poll }
        new_poll.pqstlist[poll.Active] = qinfo;
        if (setPoll) setPoll(new_poll);
        console.log(`PzAPI 'xpp/qstart' (${next_qactive}) success `);
        // console.log(new_poll);
    })
    .catch((error) => {
        console.log(`PzAPI 'xpp/qstart' (${next_qactive}) error : `, error);
    })
    .finally(() => {});
}

function setPqstStatusDone(poll, setPoll, setLoading=()=>{}) {
    if (!poll || !(poll.xrole==='P'||poll.xrole==='A')) { console.log(`xrole invalid (${poll.xrole})`); return; }
    if (poll.qactive < 0 || poll.qactive >= poll.pqstlist.length) {
        console.log('setPqstStatusDone() qactive invalid : ', poll.qactive);
        return;
    }
    setLoading(true);
    appConfig.getAPI().post('/api/xpp/qdone', {pguid: poll.pguid})
    .then((response) => {
        const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
        const qinfo = pzdata.resource;
        let new_poll = { ...poll, qactive: -1 }
        if (setPoll) setPoll(new_poll);
        console.log(`PzAPI 'xpp/qdone' success `);
        // console.log(new_poll);
    })
    .catch((error) => {
        console.log(`PzAPI 'xpp/qdone' error : `, error.response?.data);
    })
    .finally(() => { setLoading(false); });
}

function setPollStatusDone(poll, setPoll, closingEarly=false, setLoading=()=>{}) {
    if (!poll || !(poll.xrole==='P'||poll.xrole==='A')) { console.log(`xrole invalid (${poll.xrole})`); return; }
    const pqst = (poll?.qactive >= 0 && poll.pqstlist ? poll.pqstlist[poll?.qactive] : null);
    const last_quest = (poll.qactive === poll.pqstlist.length-1);
    const last_round = (pqst && (pqst.qround === pqst.qroundmax || (pqst.chosen.length-1) >= pqst.nwanted));
    if (!closingEarly && !last_round) {
        console.log('not the last round');
        return;
    } else if (!closingEarly && !last_quest) {
        console.log('not the last question');
        return;
    }
    appConfig.getAPI().post('/api/xpp/pstatus', {pguid: poll.pguid, pstatus: 'Oz'})
    .then((response) => {
        const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
        const pinfo = pzdata.resource;
        if (setPoll) setPoll(pinfo);
        console.log(`PzAPI 'xpp/pstatus' (${poll.pstatus}=>${'Oz'}) success `);
    })
    .catch((error) => {
        console.log(`PzAPI 'xpp/pstatus' (${poll.pstatus}=>${'Oz'}) error : `, error.response?.data);
    })
    .finally(() => {});
}

function setPollStatusClosed(poll, setPoll, closingEarly=false, setLoading=()=>{}) {
    if (!poll || !(poll.xrole==='P'||poll.xrole==='A')) { console.log(`xrole invalid (${poll.xrole})`); return; }
    const pqst = (poll?.qactive >= 0 && poll.pqstlist ? poll.pqstlist[poll?.qactive] : null);
    const last_quest = (poll.qactive === poll.pqstlist.length-1);
    const last_round = (pqst && (pqst.qround === pqst.qroundmax || (pqst.chosen.length-1) >= pqst.nwanted));
    if (!closingEarly && !last_round) {
        console.log('not the last round');
        return;
    } else if (!closingEarly && !last_quest) {
        console.log('not the last question');
        return;
    }
    appConfig.getAPI().post('/api/xpp/pstatus', {pguid: poll.pguid, pstatus: 'Xc'})
    .then((response) => {
        const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
        const pinfo = pzdata.resource;
        if (setPoll) setPoll(pinfo);
        console.log(`PzAPI 'xpp/pstatus' (${poll.pstatus}=>${'Xc'}) success `);
    })
    .catch((error) => {
        console.log(`PzAPI 'xpp/pstatus' (${poll.pstatus}=>${'Xc'})  error : `, error.response?.data);
    })
    .finally(() => {});
}

function setPollPqstReset(poll, setPoll, setLoading=()=>{}) {
    if (!poll || !(poll.xrole==='P'||poll.xrole==='A')) { console.log(`xrole invalid (${poll.xrole})`); return; }
    const pqst = (poll?.qactive >= 0 && poll.pqstlist ? poll.pqstlist[poll?.qactive] : null);
    if (!pqst) {
        console.log('active pqst not found');
        return;
    }
    appConfig.getAPI().post('/api/xpp/qreset', {qguid: pqst.qguid})
    .then((response) => {
        const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
        const pinfo = pzdata.resource;
        if (setPoll) setPoll(pinfo);
        console.log(`PzAPI 'xpp/qreset' success `);
    })
    .catch((error) => {
        console.log(`PzAPI 'xpp/qreset' error : `, error.response?.data);
    })
    .finally(() => {});
}

// ----------------------------------------------------------------------------
// as Voter
// ----------------------------------------------------------------------------

function checkinAsVoter(intro, vid, vname, vcode, onSuccess, onError) {
    let localeMap = utils.selectMapByLocale(intro.locale);
    // console.log("checkin : ", ntro.pguid, vname, vname.length);
    appConfig.getAPI().post("/api/xpv/checkin", {pguid:intro.pguid, vname:vname, vcode:vcode, vid:vid})
    .then((response) => {
        const respval = response.data.resource;
        console.log("PzAPI 'xpv/checkin' returned success", respval.vid);
        if (typeof onSuccess === 'function') onSuccess(respval);
    })
    .catch((error) => {
        console.log("PzAPI 'xpv/checkin' error : ", error);
        let respmsg = error.response?.data?.respmsg;
        let respval = error.response?.data?.resource;
        if (typeof onError === 'function') onError(respmsg, respval);
    })
    .finally(() => {});
}

function checkinAsPoller(intro, onSuccess, onError) {
    let localeMap = utils.selectMapByLocale(intro.locale);
    // console.log("checkin : ", ntro.pguid, vname, vname.length);
    appConfig.getAPI().post("/api/mp/rehearse", {pguid:intro.pguid})
    .then((response) => {
        const vid = response.data.resource;
        console.log("PzAPI 'mp/rehearse' returned success", vid);
        if (typeof onSuccess === 'function') onSuccess(vid);
    })
    .catch((error) => {
        console.log("PzAPI 'mp/rehearse' error : ", error);
        let respmsg = error.response?.data?.respmsg;
        let respval = error.response?.data?.resource;
        if (typeof onError === 'function') onError(respmsg, respval);
    })
    .finally(() => {  });
}

function castVoteAsVoter(vid, poll, setPoll, pqst, qRound, vchoice, onSuccess, onError) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    if (!poll) { console.log("poll invalid"); return; }
    if (!pqst) { console.log("pqst invalid"); return; }
    if (qRound < 0) { console.log("qRound invalid"); return; }
    let payload = {qguid: pqst.qguid, qround: qRound, vid: vid, vchoice: vchoice, requestedat: dayjs().toISOString()};
    // console.log("Vote payload : ", payload);
    appConfig.getAPI().post('/api/xpv/vote', payload)
    .then((response) => {
        console.log("PzAPI '/api/xpv/vote' success ", response.data.resource);
        if (typeof onSuccess === 'function') onSuccess();
    })
    .catch((error) => {
        console.log("PzAPI 'vote' error : ", error);
        let respmsg = error.response?.data?.respmsg;
        let respval = error.response?.data?.resource;
        if (typeof onError === 'function') onError(respmsg, respval);
    })
    .finally(() => { });
}

function evaluateRoundResult(pqst, nvoters, onSuccess, onError) {
    // console.log("qeval : ", ntro.pguid, vname, vname.length);
    let c2win  = (pqst.qoption.c2win ? pqst.qoption.c2win : pqst.c2win);
    let c2drop = (pqst.qoption.c2drop ? pqst.qoption.c2drop : pqst.c2drop);
    let payload = {
        qguid: pqst.qguid, qround: pqst.qround, c2win:c2win, c2drop:c2drop,
        nvcast: pqst.nvcast, nvoters: nvoters,
        answers: pqst.answers, chosen: pqst.chosen, rresult: pqst.rresult,
        startedat: pqst.startedat, stoppedat: pqst.stoppedat,
    };
    // console.log("qeval payload : ", payload);
    appConfig.getAPI().post("/api/xp/qeval", payload)
    .then((response) => {
        const rrinfo = response.data.resource;
        // console.log("PzAPI 'xp/qeval' returned success", rresult);
        if (onSuccess) onSuccess(payload, rrinfo);
    })
    .catch((error) => {
        console.log("PzAPI 'xp/qeval' error : ", error);
        let respmsg = error.response?.data?.respmsg;
        if (respmsg && (respmsg.includes('pguid invalid') || respmsg.includes('pguid not found'))) {
            if (onError) onError(payload, 'badaddr');
        } else if (respmsg && (respmsg.includes('checkin required'))) {
            if (onError) onError(payload, 'checkin');
        } else {
            if (onError) onError(payload, respmsg ? respmsg : '');
        }
    })
    .finally(() => {});
}

