import React from "react";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import EntranceForVoters from "../entrance/EntranceForVoters";
import { ReactComponent as IconQRCode } from "../resource/icons/pz-qrcode.svg";

const StepProgress = {
    Simple: StepProgressSimple,
}

export default StepProgress;


function StepProgressSimple({step=1, nsteps=3, size='xl', className='', onClick=null, invisible=false}) {
    const STEPS = Array.from(Array(nsteps).keys());

    let ICONSIZE = "1rem", ACTSIZE = "2rem", TEXTSIZE = 'text-'+size, GAPSIZE = " gap-4 ";
    if      (size === '3xl') { ICONSIZE="1.8rem"; ACTSIZE="3.6rem"; GAPSIZE = " gap-4"; }
    else if (size === '2xl') { ICONSIZE="1.6rem"; ACTSIZE="3.2rem"; GAPSIZE = " gap-4"; }
    else if (size ===  'xl') { ICONSIZE="1.5rem"; ACTSIZE="3.0rem"; GAPSIZE = " gap-4"; }
    else if (size ===  'lg') { ICONSIZE="1.4rem"; ACTSIZE="2.8rem"; GAPSIZE = " gap-4"; }
    else if (size ===  'md') { ICONSIZE="1.3rem"; ACTSIZE="2.6rem"; GAPSIZE = " gap-4"; }
    else if (size ===  'sm') { ICONSIZE="1.2rem"; ACTSIZE="2.4rem"; GAPSIZE = " gap-4"; }
    else if (size ===  'xs') { ICONSIZE="1.1rem"; ACTSIZE="2.2rem"; GAPSIZE = " gap-4"; }
    else if (size === '2xs') { ICONSIZE="1.0rem"; ACTSIZE="2.0rem"; GAPSIZE = " gap-4"; }
    else                     { ICONSIZE="0.8rem"; ACTSIZE="1.6rem"; GAPSIZE = " gap-4"; }
    console.log(step, STEPS, size, ICONSIZE, ACTSIZE);

    // 65 130 210
    return (
        <>
            <div className={"w-full flex flex-row justify-center items-center gap-4 " + (invisible ? " invisible " : " ") + GAPSIZE + className}>
                { STEPS.map((s, sidx)=>{
                    return ( (sidx === step) ?
                        <div key={sidx} className={`w-[${ACTSIZE}] h-[${ACTSIZE}] border-2 border-white bg-white  hover:cursor-pointer rounded-[3rem]`} 
                            onClick={()=>{ }} />
                    :
                        <div key={sidx} className={`w-[${ICONSIZE}] h-[${ICONSIZE}] border-2 border-gray-900         hover:cursor-pointer rounded-[3rem]`} 
                            onClick={()=>{ }} />
                    ); 
                })}
            </div>
        </>
    );
}

