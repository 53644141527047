import React from "react";
import { useNavigate } from "react-router-dom";
import { useInterval } from "react-use";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import PzDialog from "../common/PzDialog";
import { ReactComponent as IconMagnifier } from "../resource/icons/pz-tool-magnifier.svg";
import { ReactComponent as IconVResult } from "../resource/icons/pz-vote-result.svg";

const EMButtonForVoter = {
    Action:     EMButtonForVoterAction,
    RsltMenu:   EMButtonForVoterRsltMenu,
};
export default EMButtonForVoter;


function EMButtonForVoterAction({poll, setPoll, pqst, selection, callToCastVote, size='xl', disabled=false, votedAlready=false}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const navigate = useNavigate();
    const pstatus = (poll ? poll.pstatus : ' ');
    const qstatus = (pqst ? pqst.qstatus : ' ');
    const [open4VCheck, setOpen4VCheck] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [invisible, setInvisible] = React.useState(!poll.updatedat || utils.getDiffSince(poll.updatedat) < 3000);
    const nextRound = (pqst && pqst.qround < pqst.qroundmax && (pqst.chosen.length-1) < pqst.nwanted);
    const nc2s = (pqst && pqst.qstatus === 'Vs' ? (pqst.nwanted - utils.countChosenAnswers(pqst, 1, pqst?.qround-1)) : 0);
    const goal = (pqst && pqst.qstatus === 'Vs' ? utils.completeLocaleMapText(localeMap, "monitor.quest.goal." + pqst.qkind, [ nc2s, utils.countValidAnswers(pqst, pqst.qround)], poll.pkind) : '');
    // console.log("Action:", selection, pstatus, qstatus, votedAlready);
    // if (pqst && pqst.qstatus === 'Vx') console.log("Round:", pqst.qround, "  chosen:", (pqst.chosen.length-1), "  nextR:", nextRound);

    useInterval(() => {
        let diff = utils.getDiffSince(poll.updatedat);
        let new_invisible = (diff && diff < 3000);
        if (invisible !== new_invisible) setInvisible(new_invisible);
        if (poll.paused) setPoll( { ...poll, updatedat:dayjs() }); 
    }, 500);

    function getActionText() {
        if (!poll) return '';
        switch (pstatus) { // current status
        case 'Os': return localeMap["monitor.action.castvote"];
        case 'Oz': return localeMap["monitor.action.checkout"];
        default:   return '';
        }
    }

    function executeAction(e) {
        e.stopPropagation();
        if (!poll) return '';
        switch (pstatus) { // current status
        case 'Os': 
            if (selection?.length < nc2s) {
                setOpen4VCheck(true);
            } else if (pqst && typeof callToCastVote === 'function') {
                callToCastVote();
            }
            break;
        case 'Oz': 
            navigate('/y/vote?p=THANKYOU'); 
            setPoll(null);
            break;
        default:   
            break;
        }
    }

    return (
        <>
            { pstatus === 'Oo' ?
                ( poll.natt > 2 &&
                    <PzButton.EMWaiting text={localeMap["monitor.waiting.event.start"]} size={size} invisible={invisible} />
                )
            : pstatus === 'Os' ?
                ( !pqst ?
                    <PzButton.EMWaiting text={localeMap["monitor.waiting.quest.vstart"]} size={size} invisible={invisible} />
                : qstatus === 'Vs' && !votedAlready ?

                    <PzButton.EMAction text={getActionText()} size={size} 
                        onClick={executeAction} loading={loading} disabled={disabled}
                        invisible={(selection && selection.length==0)} heartbeat={selection && selection.length>=nc2s} />

                : qstatus === 'Vs' ?
                    <PzButton.EMWaiting text={localeMap["monitor.waiting.quest.vstop"]} size={size} invisible={false} noprogress={true} />
                : qstatus === 'Vt' ?
                    <PzButton.EMWaiting text={' '} size={size} invisible={true} />
                : qstatus === 'Vx' && nextRound ?
                    <PzButton.EMWaiting text={`R${pqst.qround+1} ` + localeMap["monitor.waiting.quest.nextr"]} size={size} invisible={invisible} />
                : qstatus === 'Vx'  ?
                    <PzButton.EMWaiting text={`Q${pqst.qseq} ` + localeMap["monitor.waiting.quest.close"]} size={size} invisible={invisible} />
                :
                    <></>
                )
            : pstatus >= 'Oz' ?
                <PzButton.EMAction text={getActionText()} size={size} 
                    onClick={executeAction} invisible={invisible} />
            :
                <></>
            }

            { open4VCheck && 
                <PzDialog.Simple 
                    title={localeMap["monitor.quest.goal.label"] + " : " + goal} 
                    message={utils.completeMessageText(localeMap["monitor.quest.goal.vcheck."+pqst.qkind], [selection ? selection.length : 0])} 
                    onClose={()=>{setOpen4VCheck(false);}} 
                    onButtonYes={()=>{setOpen4VCheck(false); callToCastVote();}} 
                    onButtonNo={()=>{setOpen4VCheck(false);}}
                />
            }

        </>
    );

}

function EMButtonForVoterRsltMenu({poll, size='xl'}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    return (
        <>
            <PzButton.EMMenu size={size} Icon={IconVResult} pale={true} 
                className={poll.pstatus === 'Oz' ? '' : 'hidden'}
                items={[
                    { text:localeMap["monitor.action.copyresult"], callback:()=>{console.log('copied')}, disabled:true },
                ]} />
        </>
    );
}

