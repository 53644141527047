import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useInterval } from "react-use";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { vutils } from "../../config/voterConfig";
import CheckinSessionHandler from "./CheckinSessionHandler";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import EMAreaHeader from "../../eventmonitor/EMAreaHeader";
import EMAreaBodyAsVoter from "../../eventmonitor/EMAreaBodyAsVoter";
import EMButtonForVoter from "../../eventmonitor/EMButtonForVoter";
import EMWithPzServer from "../../eventmonitor/EMWithPzServer";
import EMSplash from "../../eventmonitor/EMSplash";
import { ReactComponent as IconToolMagnifier } from "../../resource/icons/pz-tool-magnifier.svg";


export default function DoVote() {
    const [poll, setPoll] = React.useState(null);
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const [cookies, removeCookie] = useCookies(['vauth']);
    const [vauth, setVAuth] = React.useState(cookies && cookies.vauth ? jwtDecode(cookies.vauth) : null);
    const navigate = useNavigate();
    const [qparams] = useSearchParams();
    const pguid = qparams.get("p");
    const vid = qparams.get("v");
    const pqst = (poll?.qactive >= 0 && poll?.pqstlist ? poll?.pqstlist[poll?.qactive] : null);
    const [svcStatus, setSvcStatus] = React.useState('');
    const [magnified, setMagnified] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    // 
    const [current, setCurrent] = React.useState({qguid:'', qround:0});
    const [selection, setSelection] = React.useState([]);
    const [voted, setVoted] = React.useState(false);
    //
    const dispatch = useDispatch();
    const savedVRec = useSelector((state)=>state.vcfg.vrec);
    // console.log("current.qguid:", (current.qguid.length > 14 ? current.qguid.substring(0,14)+"..." : current.qguid), "current.qround:", current.qround);

    if (pqst) {  
        // reset 'selection' and 'voted', if it's new pqst or qround
        if (current.qguid != pqst.qguid || current.qround !== pqst.qround) {    // if it's new round
            // console.log("set current: ");
            setCurrent({qguid:pqst.qguid, qround:pqst.qround});     // remember current qguid and qround
            setSelection([]);   // we have to reset, if it's new pqst or qround
            setVoted(false);    // we have to reset, if it's new pqst or qround
        }
        // reload 'selection' and 'voted', if previous selection was already made
        if (savedVRec && savedVRec.pguid === poll.pguid) {
            let saved_selection = vutils.popSelection(savedVRec, pqst.qguid, pqst.qround);
            let isSame = function(s1, s2) {
                if (!Array.isArray(s1) || !Array.isArray(s2)) return false;
                if (s1.length != s2.length) return false;
                for (let i = 0; i < s1.length; i++) { if (s1[i] !== s2[i]) return false; }
                return true;
            };
            // console.log("vrec", (savedVRec ? "found : " : "NotFound : "), "saved_selection:", saved_selection, "curr_selection:", selection);
            if (saved_selection && !isSame(saved_selection, selection) && !voted) {
                console.log("selection reloaded :", selection, " => ", saved_selection, typeof selection, typeof saved_selection);
                setSelection(saved_selection);
                setVoted(true);    // we have to reset, if it's new pqst or qround
            }
        }
    }

    React.useEffect(() => {
        // removeCookie("vauth", null);
        let checkedin = (vauth != null);
        if (vauth && vauth.pguid !== pguid) {
            removeCookie('vauth', null);
            setVAuth(null);
            checkedin = false;
        } 
        if (pguid?.length !== 48) { 
            setSvcStatus('badaddr')
        } else if (!checkedin) { 
            console.log("/v/vote navigate to /v/checkin");
            if (vid) navigate("/v/checkin?p=" + pguid + "&v=" + vid);
            else     navigate("/v/checkin?p=" + pguid);
        }
    }, []);
    
    useInterval(() => {
        updateEventInfo();
    }, 1000);

    function updateEventInfo() {
        if (svcStatus === 'badaddr' || svcStatus === 'checkin') return;
        EMWithPzServer.updateEventInfo(pguid, poll, setPoll2, '/api/xpv/event', 'V', setSvcStatus);
    }

    function setPoll2(new_poll) {
        // This function is called on poll update is SUCCESS & CHANGED 
        setPoll(new_poll);
        const qactive = (new_poll ? new_poll.qactive : null);
        const qguid  = (qactive >= 0 && new_poll?.pqstlist ? new_poll.pqstlist[qactive].qguid : '')
        const qround = (qactive >= 0 && new_poll?.pqstlist ? new_poll.pqstlist[qactive].qround : 0);
        if (qactive >= 0 && (qguid !== current.qguid || qround !== current.qround)) {
            // the current question & round has been changed
            setCurrent({qguid: qguid, qround: qround});
            setSelection([]);
            setVoted(false);
        }
    }

    function callToCastVote() {
        if (!poll) { console.log("poll invalid"); return; }
        if (!pqst) { console.log("pqst invalid"); return; }
        if (pqst.qround <= 0) { console.log("qround invalid"); return; }
        const onSuccess = ()=>{
            if (current.qguid == '' || current.qround <= 0) { console.log("current invalid"); return; }
            // save the selection in React Persist
            let new_vrec = { ...savedVRec, pguid:poll.pguid, vid:vauth.vid, vname:vauth.vname }
            new_vrec = vutils.pushSelection(new_vrec, pqst.qguid, pqst.qround, selection);
            console.log("saving vrec : ", new_vrec);
            dispatch(vutils.setVRec(new_vrec));    // save in React Persist
            setErrMsg('');
            setLoading(false);
            setVoted(true);
        };
        const onError = (respmsg, respval)=>{
            if (respmsg && respmsg.includes('duplicated')) {
                setErrMsg(localeMap["voter.vcast.err.duplicate"]);
            } else {
                setErrMsg(respmsg);
            }
            setLoading(false);
        };
        setLoading(true);
        setErrMsg('');
        // console.log("callToCastVote : ", selection)
        EMWithPzServer.castVoteAsVoter(vauth.vid, poll, setPoll, pqst, pqst.qround, selection, onSuccess, onError);
    }

    if (pguid === 'THANKYOU') {
        return (<EMSplash msg={localeMap["monitor.splash.thankyou"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'badaddr') {
        return (<EMSplash msg={localeMap["monitor.splash.badaddr"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'checkin') {
        return (<EMSplash msg={localeMap["monitor.splash.checkin"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'closed') {
        return (<EMSplash msg={localeMap["monitor.splash.closed"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'loading') {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    } else if (svcStatus  != '') {
        return (<EMSplash msg={svcStatus} />);
    } else if (!poll) {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    }

    let  SIZE = (magnified ? '4xl' : '3xl');
    let SSIZE = utils.getSizeRelative(SIZE, -1);

    return (
        <PzArea.Container>
            <PzArea.MwhBoard  className="relative  px-0 py-10 select-none bg-white gap-10">

                <div className="w-full px-5 ">
                    <EMAreaHeader        poll={poll} asVoter={true} size={SIZE} />
                </div>
                <div className="w-full px-5 h-[calc(100dvh-8rem)] flex flex-col justify-start gap-10 overflow-y-scroll">
                    <EMAreaBodyAsVoter   poll={poll} qidx={poll.qactive} pqst={pqst} size={SIZE} 
                        selection={selection} setSelection={setSelection} votedAlready={voted} />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>

                {/* Magnification Button */}
                <div className="absolute bottom-[2.5rem] left-[2rem] ">
                    <PzButton.EMToggle Icon={IconToolMagnifier} size={'2xl'} toggle={magnified} setToggle={setMagnified} className="pz-fab-shadow" />
                </div>

                {/* VOTE ACTION Button */}
                <div className="absolute bottom-[2.5rem] left-1/2 -translate-x-1/2 ">
                    <EMButtonForVoter.Action poll={poll} setPoll={setPoll} pqst={pqst} size={'2xl'} 
                        selection={selection} callToCastVote={callToCastVote} disabled={loading} votedAlready={voted} />
                </div>

                {/* RESULT Menu Button */}
                <div className="absolute bottom-[2.5rem] right-[2rem] ">
                    <EMButtonForVoter.RsltMenu   poll={poll} setPoll={setPoll} pqst={pqst} size={'2xl'} />
                </div>

            </PzArea.MwhBoard >

            { vauth &&    // this component will automatically terminate the session, or let user extend it
                <CheckinSessionHandler vauth={vauth} />     
            }

        </PzArea.Container>
    );

}

