import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useInterval } from "react-use";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import PzInput from "../common/PzInput";
import PzPop from "../common/PzPop";
import PzText from "../common/PzText";
import PzTime from "../common/PzTime";
import PzDateTimePicker from "../common/PzDateTimePicker";
import { ReactComponent as IconTimeClock } from "../resource/icons/pz-time-clock.svg";
import { ReactComponent as IconTimeHourglass } from "../resource/icons/pz-time-hourglass.svg";
import { ReactComponent as IconDoorOpen } from "../resource/icons/pz-door-open.svg";
import { ReactComponent as IconDoorClose } from "../resource/icons/pz-door-close.svg";
import { ReactComponent as IconStepStart } from "../resource/icons/pz-step-start.svg";
import { ReactComponent as IconStepStop } from "../resource/icons/pz-step-stop.svg";
import { ReactComponent as IconVotePollbox } from "../resource/icons/pz-vote-pollbox.svg";
import { ReactComponent as IconUserPoller } from "../resource/icons/pz-user-b1.svg";
import { ReactComponent as IconToolWorld } from "../resource/icons/pz-tool-world.svg";

const PollEvent = {
    PWhen:      PollEventPWhen,
    Times:      PollEventTimes,
    Remaining:  PollEventRemaining,
    Editor:     PollEventEditor,
};
export default PollEvent;


function PollEventPWhen({poll, size='xl', className=''}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const utzone = useSelector((state)=>state.ucfg.timezone); // timezone from user settings

    // React.useEffect(() => {
    // }, []);

    function describePWhen(pkind, pwhen, pwhenend=undefined) {
        if (!pkind) {
            return '';
        } else if (pkind[1] === 'P') {
            return utils.getPeriodInUserTimezone(pwhen, pwhenend, utzone);
        } else if (pwhen === '') {
            return localeMap["poll01.pwhen.rightnow"];
        } else {
            return utils.getTimeInUserTimezone(pwhen, "YYYY/MM/DD h:mm A", utzone);
        }
    }

    let TEXTSIZE = 'text-'+size, tsize = size, ssize = size, sssize = size, gap = " gap-0 ";
    if      (size === '2xl') { tsize="3xl"; ssize="xl"; sssize="lg"; gap=" gap-3 "; }
    else if (size ===  'xl') { tsize="2xl"; ssize="lg"; sssize="md"; gap=" gap-3 "; }
    else if (size ===  'lg') { tsize="xl";  ssize="md"; sssize="sm"; gap=" gap-2 "; }
    else if (size ===  'md') { tsize="lg";  ssize="sm"; sssize="xs"; gap=" gap-2 "; }
    else if (size ===  'sm') { tsize="md";  ssize="xs"; sssize="xs"; gap=" gap-1 "; }
    else                     { tsize="sm";  ssize="xs"; sssize="xs"; gap=" gap-1 "; }

    return (
        <PzPop.Dropdown 
            base={
                <div className={"flex flex-row justify-end items-center gap-2 cursor-pointer"} >
                    <PzButton.Icon Icon={IconTimeClock} size={ssize} forProperty={true} noBackground={true} noBorder={true} />
                    <p className={"font-bold "+TEXTSIZE}>{describePWhen(poll.pkind, poll.pwhen)}</p>
                </div>
            }
            popover={ 
                poll.pstatus >= 'O' ?
                    <PollEventTimes poll={poll} size={size} />
                : dayjs(poll?.pwhen).diff(dayjs()) > 0 ?
                    <PollEventRemaining poll={poll} size={size} />
                :
                    <></>
            } 
            ha="right" offset="5px" upward={true} />
    );
}

function PollEventTimes({poll, size='xl', className=''}) {
    const utzone = useSelector((state)=>state.ucfg.timezone); // timezone from user settings
    let TEXTSIZE = 'text-'+size, ICONSIZE="1rem", padding = "", gap = " gap-0 ";
    if      (size === '2xl') { ICONSIZE="1rem"; padding=" px-3 py-2 "; gap=" gap-x-3 gap-y-1 "; }
    else if (size ===  'xl') { ICONSIZE="1rem"; padding=" px-3 py-2 "; gap=" gap-x-3 gap-y-1 "; }
    else if (size ===  'lg') { ICONSIZE="1rem"; padding=" px-3 py-2 "; gap=" gap-x-2 gap-y-1 "; }
    else if (size ===  'md') { ICONSIZE="1rem"; padding=" px-3 py-2 "; gap=" gap-x-2 gap-y-1 "; }
    else if (size ===  'sm') { ICONSIZE="1rem"; padding=" px-3 py-2 "; gap=" gap-x-1 gap-y-1 "; }
    else                     { ICONSIZE="1rem"; padding=" px-3 py-2 "; gap=" gap-x-1 gap-y-1 "; }

    return (
        <div className={"flex grid grid-cols-[auto_auto] justify-start items-center " + padding + gap}>
            <PzPop.Popover ha='left' offset='5px'
                base={<IconDoorOpen width={ICONSIZE} height={ICONSIZE} />}
                popover={<label className={TEXTSIZE+padding}>{'Opened at'}</label>} />
            <label className={TEXTSIZE+" text-right"}>{utils.getTimeInUserTimezone(poll.openedat, "h:mm:ss A", utzone)}</label>
            <PzPop.Popover ha='left' offset='5px'
                base={<IconStepStart width={ICONSIZE} height={ICONSIZE} />}
                popover={<label className={TEXTSIZE+padding}>{'Started at'}</label>} />
            <label className={TEXTSIZE+" text-right"}>{utils.getTimeInUserTimezone(poll.startedat, "h:mm:ss A", utzone)}</label>
            <PzPop.Popover ha='left' offset='5px'
                base={<IconStepStop width={ICONSIZE} height={ICONSIZE} />}
                popover={<label className={TEXTSIZE+padding}>{'Stopped at'}</label>} />
            <label className={TEXTSIZE+" text-right"}>{utils.getTimeInUserTimezone(poll.stoppedat, "h:mm:ss A", utzone)}</label>
            <PzPop.Popover ha='left' offset='5px'
                base={<IconDoorClose width={ICONSIZE} height={ICONSIZE} />}
                popover={<label className={TEXTSIZE+padding}>{'Closed at'}</label>} />
            <label className={TEXTSIZE+" text-right"}>{utils.getTimeInUserTimezone(poll.closedat, "h:mm:ss A", utzone)}</label>
        </div>
    );
}

function PollEventRemaining({poll, size='xl', className=''}) {
    const [remaining, setRemaining] = React.useState(getRemainingTime(poll?.pwhen));

    useInterval(() => {
        let remainingTime = getRemainingTime(poll?.pwhen);
        if (remainingTime !== remaining) setRemaining(remainingTime);
    }, 1000);

    function getRemainingTime(pwhen) {
        if (!pwhen) return '';
        let remaining = dayjs(pwhen).diff(dayjs(), 'second');
        if (remaining < 0) return '';
        let hh = 0, mm = 0;
        if (remaining >= 3600) { hh = Math.floor(remaining/3600); remaining -= hh * 3600; }
        if (remaining >=   60) { mm = Math.floor(remaining/60);   remaining -= mm * 60; }
        if      (mm > 0) return `${hh}:${mm}:${remaining}`;
        else             return `${remaining} seconds`;
    }
    
    let TEXTSIZE = 'text-'+size;

    return (
        <div className="flex px-2 py-0 flex-row justify-start items-center gap-2">
            <IconTimeHourglass width="1rem" height="1rem" />
            <label className={TEXTSIZE + " text-bold"}>{remaining}</label>
        </div>
    );
}

function PollEventEditor({ poll, setPoll, size='lg', visibility={k:false, t:true, u:true, w:true, e:true, l:true}, className='' }) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [selK, setSelK] = React.useState([ poll.pkind[0] ]);
    const [selW, setSelW] = React.useState([ poll.pwhen === '' ? 'N' : 'R' ]);
    const [selE, setSelE] = React.useState( poll.pearlystt && poll.pearlystt !== '' ? ['Yes'] : []);
    const utzone = useSelector((state)=>state.ucfg.timezone);  // timezone from user settings
    const [wConst, setWConst] = React.useState('');
    const pollCreating = (poll?.pguid === undefined);
    let SSIZE = "lg", TEXTSIZE = "text-"+size, GAPY=" ", GAPX = " ";
    if      (size === '2xl') { SSIZE="xl";  GAPY=" gap-y-4 "; GAPX = " gap-x-4 "; }
    else if (size ===  'xl') { SSIZE="lg";  GAPY=" gap-y-4 "; GAPX = " gap-x-4 "; }
    else if (size ===  'lg') { SSIZE="md";  GAPY=" gap-y-3 "; GAPX = " gap-x-3 "; }
    else if (size ===  'md') { SSIZE="sm";  GAPY=" gap-y-3 "; GAPX = " gap-x-3 "; }
    else if (size ===  'sm') { SSIZE="xl";  GAPY=" gap-y-2 "; GAPX = " gap-x-2 "; }
    else                     { SSIZE="2xl"; GAPY=" gap-y-1 "; GAPX = " gap-x-1 "; }
    
    // useInterval(() => {
    //     if (!poll.createdat) setWConst('');
    //     else if (poll.pwhen === '') {
    //         let updatedat = (poll.updatedat ? dayjs(poll.updatedat) : dayjs());
    //         setWConst(utils.completeTimeText(localeMap["poll01.pwhen.rightnow.expire"], updatedat.add(1, 'hour'), utzone));
    //     }
    // }, 1000);

    function onChangeToPKind(e, old_selection, new_selection) {
        function replacePkind(s, idx, c) {
            if (!s || s.length === 0 || idx >= s.length) {
                return s
            } else if (idx === s.length - 1) {
                return s.substring(0,idx) + c 
            } else {
                return s.substring(0,idx) + c + s.substring(idx+1)
            }
        }
        switch (new_selection[0]) {
            case 'P': setPoll({ ...poll, pkind: replacePkind(poll.pkind, 0, "P") });  break;
            case 'S': setPoll({ ...poll, pkind: replacePkind(poll.pkind, 0, "S") });  break;
            case 'Q': setPoll({ ...poll, pkind: replacePkind(poll.pkind, 0, "Q") });  break;
            default:  setPoll({ ...poll, pkind: replacePkind(poll.pkind, 0, "P") });  break;
        }
        e.stopPropagation();
    }
    function onChangeToPWhen(e, old_selection, new_selection) {
        function replacePkind(s, idx, c) {
            if (!s || s.length === 0 || idx >= s.length) {
                return s
            } else if (idx === s.length - 1) {
                return s.substring(0,idx) + c 
            } else {
                return s.substring(0,idx) + c + s.substring(idx+1)
            }
        }
        switch (new_selection[0]) {
            case 'R': 
                let pwhen = (poll.pwhen && poll.pwhen !== '' ? dayjs(poll.pwhen) : dayjs(new Date()).add(2, 'hour').startOf('hour'));
                let pearlystt = (selE.includes('Yes') ? pwhen.add(-24, 'hour').toISOString() : '');
                let pearlyend = (selE.includes('Yes') ? pwhen.add(-10, 'minute').toISOString() : '');
                setPoll({ ...poll, pkind: replacePkind(poll.pkind, 1, "R"), pwhen: pwhen.toISOString(), pearlystt: pearlystt, pearlyend: pearlyend });  
                break;
            default:  
                setPoll({ ...poll, pkind: replacePkind(poll.pkind, 1, "R"), pwhen: '', pearlystt: '', pearlyend: '' });  
                break;
        }
        e.stopPropagation();
    }
    function onChangeToPEarly(e, old_selection, new_selection) {
        if (poll.pwhen && new_selection.includes('Yes')) {
            let pwhen = dayjs(poll.pwhen);
            let pearlystt = pwhen.add(-24, 'hour').toISOString();
            let pearlyend = pwhen.add(-10, 'minute').toISOString();
            setPoll({ ...poll, pearlystt: pearlystt, pearlyend: pearlyend })
        } else {
            setPoll({ ...poll, pearlystt: '', pearlyend: '' })
        }
        e.stopPropagation();
    }
    function onAcceptToDatePicker(pwhen) {
        // console.log("DateTimePicker:", pwhen, pwhen.toISOString());
        if (selE.includes('Yes')) {
            let pearlystt = pwhen.add(-24, 'hour').toISOString();
            let pearlyend = pwhen.add(-10, 'minute').toISOString();
            setPoll({ ...poll, pwhen: pwhen.toISOString(), pearlystt: pearlystt, pearlyend: pearlyend });
        } else {
            setPoll({ ...poll, pwhen: pwhen.toISOString(), pearlystt: '', pearlyend: '' });
        }
    } 
        
    return (
        <div className={"w-full flex flex-col justify-start items-start " + TEXTSIZE + GAPY + className} >

            {/* KIND */}
            { visibility.k && 
                <div className={"w-full grid grid-cols-[auto_1fr] justify-start items-center " + GAPX }>
                    {/* <span className={" " + TEXTSIZE}>{localeMap["poll01.pkind.label"]} :</span> */}
                    <PzButton.Icon Icon={IconVotePollbox} size={SSIZE} forProperty={true} />
                    <div className="grid grid-cols-[auto_auto_auto] items-start">
                        <PzInput.Radio2 value="P" selection={selK} setSelection={setSelK} onChange={onChangeToPKind} size={size} disabled={false} text={localeMap["comm.etype.p"]} />
                        <PzInput.Radio2 value="S" selection={selK} setSelection={setSelK} onChange={onChangeToPKind} size={size} disabled={true}  text={localeMap["comm.etype.s"]} />
                        <PzInput.Radio2 value="Q" selection={selK} setSelection={setSelK} onChange={onChangeToPKind} size={size} disabled={true}  text={localeMap["comm.etype.q"]} />
                    </div>
                </div>
            }

            {/* TITLE */}
            { visibility.t &&  
                <div className={"w-full grid grid-cols-[auto_1fr] justify-start items-center " + GAPX}>
                    {/* <span className={" " + TEXTSIZE}>{localeMap["poll01.ptitle.label"]} :</span> */}
                    <PzButton.Icon Icon={IconVotePollbox} size={SSIZE} forProperty={true} />
                    <PzText.Touch name="ptitle" text={poll.ptitle} setText={(txt)=>{setPoll({...poll,ptitle:txt});}} 
                        editable={true} size={size} className="w-full font-bold" tabIndex={-1} 
                        placeholder={localeMap["poll01.ptitle.placeholder"]} setFocusLater={true} />
                </div>
            }

            {/* POLLER */}
            { visibility.u && 
                <div className={"w-full grid grid-cols-[auto_1fr] justify-start items-center " + GAPX}>
                    {/* <span className={" " + TEXTSIZE}>{localeMap["poll01.poller.label"]} :</span> */}
                    <PzButton.Icon Icon={IconUserPoller} size={SSIZE} forProperty={true} />
                    <PzText.Touch name="uname" text={poll.uname} setText={(txt)=>{setPoll({...poll,uname:txt});}} 
                        editable={true} size={size} className="w-full font-bold" tabIndex={-1} 
                        placeholder={localeMap["poll01.uname.placeholder"]} />
                </div>
            }

            {/* WHEN */}
            { visibility.w && 
                <div className={"w-full grid grid-cols-[auto_1fr] justify-start items-center " + GAPX + " gap-y-1 "}>
                    {/* <span className={TEXTSIZE}>{localeMap["poll01.pwhen.label"]} :</span> */}
                    <PzButton.Icon Icon={IconTimeClock} size={SSIZE} forProperty={true} />
                    <div className="w-full grid grid-cols-[1fr_1fr]">
                            <PzInput.Radio2 value="N" selection={selW} setSelection={setSelW} onChange={onChangeToPWhen} 
                                size={size} disabled={false} text={localeMap["poll01.pwhen.rightnow"]} />
                            <PzInput.Radio2 value="R" selection={selW} setSelection={setSelW} onChange={onChangeToPWhen} 
                                size={size} disabled={false} text={localeMap["poll01.pwhen.scheduled"]} />
                    </div>
                    <div>&nbsp;</div>
                    <div className="w-full pl-2 pr-4 flex flex-row justify-between items-center">
                        { selW.includes('N') ?
                            <div className="w-auto flex flex-row justify-start items-center gap-2">
                                <span className={"text-slate-500 text-"+SSIZE}>
                                    {localeMap["poll01.pwhen.rightnow.constraint"]}
                                </span>
                                { wConst && <>
                                    <span className={"ml-8 text-slate-500 text-md"}>{wConst}</span> 
                                    <PzTime.RemainingExp poll={poll} format='hh:mm:ss' />
                                </>}
                            </div>
                        :
                            <div>&nbsp;</div>
                        }
                        { selW.includes('R') ?
                            <div className="min-w-[50%] pl-8 flex flex-row justify-start items-center">
                                <PzDateTimePicker 
                                    format="YYYY/MM/DD HH:mm" size={SSIZE}
                                    defaultValue={poll.pwhen!=='' ? dayjs(poll.pwhen) : dayjs(new Date()).add(2, 'hour').startOf('hour')} 
                                    minDate={dayjs(new Date())} maxDate={dayjs(new Date().setDate(new Date().getDate()+60))}
                                    onAccept={onAcceptToDatePicker} disabled={false} />
                            </div>
                        :
                            <div>&nbsp;</div>
                        }
                    </div>
                </div> 
            }

            {/* EARLY VOTING */}
            { visibility.e && !pollCreating && selW.includes('R') && 
                <div className={"w-full grid grid-cols-[auto_1fr] justify-start items-center " + GAPX}>
                    {/* <span className={TEXTSIZE}>{localeMap["poll01.pearly.label"]} :</span> */}
                    <PzButton.Icon Icon={IconVotePollbox} size={SSIZE} forProperty={true} />
                    <div className="w-full flex flex-row justify-between items-center">
                        <PzInput.Checkbox2 value="Yes" selection={selE} setSelection={setSelE} onChange={onChangeToPEarly} 
                            size={size} disabled={false} text={ localeMap[selE.includes('Yes') ? 'poll01.pearly.yes' : 'poll01.pearly.no']} />
                        { selE.includes('Yes') && 
                        <div className="flex flex-row justify-end gap-3 items-center text-sm">
                            <label className="">{utils.getTimeInUserTimezone(poll.pearlystt, "MM/DD HH:mm", utzone, false)} (-24H)</label>
                            <label className=""> ~ </label>
                            <label className="">{utils.getTimeInUserTimezone(poll.pearlyend, "MM/DD HH:mm", utzone, false)} (-10M)</label>
                        </div> 
                        }
                    </div>
                </div> 
            }

            {/* LANGUAGE */}
            { visibility.l && !pollCreating && 
                <div className={"w-full grid grid-cols-[auto_1fr] justify-start items-center " + GAPX}>
                    {/* <span className={TEXTSIZE}>{localeMap["poll01.locale.label"]} :</span> */}
                    <PzButton.Icon Icon={IconToolWorld} size={SSIZE} forProperty={true} />
                    <label className={TEXTSIZE + " font-bold pl-1"}>{appConfig.getLanguageFromLocale(poll?.locale, true)}</label>
                </div> 
            }

        </div>
    );
}


