import React from "react";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzProgress from "../common/PzProgress";
import EMComQstList from "./EMComQstList";
import EMComQstHeader from "./EMComQstHeader";
import EMComQstAnswers from "./EMComQstAnswers";
import PollPqstGoal from "../event/PollPqstGoal";
import PollPqstResult from "../event/PollPqstResult";

export default function EMAreaBodyAsVoter({poll, qidx, pqst, setPoll, size='xl', selection=[], setSelection=()=>{}, disabled=false, votedAlready=false}) {
    const pstatus = (poll ? poll.pstatus : ' ');
    const qstatus = (pqst ? pqst.qstatus : ' ');
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const ranswers = (poll && pqst && pstatus[0] === 'O' ? PollPqstResult.getRoundAnswers(pqst, pqst.qround) : []);
    const tanswers = (poll && pqst && pqst.qstatus >= 'Vx' ? PollPqstResult.getTopAnswers(ranswers, 0) : []);
    const [qFolded, setQFolded] = React.useState(true);
    const [topView, setTopView] = React.useState(true);

    if (!poll) {
        return (<h3>poll is null</h3>);
    }
    
    const TEXTSIZE = 'text-' + size;
    const SSIZE = utils.getSizeRelative(size,-1), STEXTSIZE = 'text-' + utils.getSizeRelative(size,-1);

    return (
        <div className="w-full">
            { pstatus === 'Oo' ?
                <div className={"w-full pt-20 flex flex-col justify-start items-stretch"}>
                    <div className={"w-full flex flex-col justify-start items-center gap-5"}>
                        <label className={TEXTSIZE+" font-bold"}>
                            {localeMap["monitor.progress.checkin"]}
                        </label>
                        <PzProgress className="w-[20rem]" size={size} bold={true} count={poll.natt} total={poll.nmax} />
                        { poll.natt < poll.nmin ? 
                            <label className={"text-xl font-bold mt-10 "}>
                                {localeMap["monitor.waiting.event.checkin2"]}
                            </label>
                        :
                            <label className={"text-xl font-bold mt-10 "}>
                                {utils.completeMessageText(localeMap["monitor.progress.checked"], [poll.nmin])}
                            </label>
                        }
                    </div>
                </div>
            : pstatus === 'Os' ?
                ( !pqst ?
                    <div className="w-full flex flex-col justify-start items-stretch gap-5">
                        <label className={TEXTSIZE+" font-bold"}>
                            {localeMap["monitor.quest.planned"]}
                        </label>
                        <EMComQstList poll={poll} setPoll={setPoll} selectable={false} size={SSIZE} />
                    </div>
                : qstatus === 'Vs' ?
                    <div className="w-full flex flex-col justify-start items-stretch gap-5 ">
                        <div className="w-full flex flex-row justify-start">
                            <label className={TEXTSIZE + " font-bold " + (!votedAlready ? "heartbeat" : "")}>{
                                !votedAlready ? localeMap["monitor.quest.voting.now"] : localeMap["monitor.waiting.quest.vstop"]
                            }</label>
                        </div>
                        <div className={"w-full ring-slate-800 ring-inset ring-1 rounded-2xl flex flex-col justify-start items-stretch gap-4 "}>  
                            <EMComQstHeader  poll={poll} pqst={pqst} size={size} bold={true} op50={pqst.nvcast > 0} className="pt-4 px-4"
                                folded={qFolded} setFolded={setQFolded} />
                            <div className="w-full h-[1px] bg-slate-200"></div>
                            <EMComQstAnswers pqst={pqst} answers={ranswers} size={SSIZE} className="px-8" 
                                preview={false} disabled={votedAlready}
                                selection={selection} setSelection={setSelection} />
                            <div className="w-full h-[1px] bg-slate-800"></div>
                            <PollPqstGoal.Footer poll={poll} qidx={qidx} ranswers={ranswers} size={SSIZE} className="pb-4 px-4" initFolded={true} />
                        </div>
                        <div className={"w-full flex flex-col justify-start items-center " + (votedAlready ? "" : "invisible")}>
                            <label className={STEXTSIZE + " font-bold"}>{localeMap["monitor.progress.voting"]}</label>
                            <PzProgress className={"w-[50%] "} size={SSIZE} bold={true} count={pqst.nvcast} total={poll.natt} />
                        </div>
                    </div>
                : qstatus === 'Vt' || qstatus === 'Vx' ?

                    <div className="w-full flex flex-col justify-start items-stretch gap-5">
                        <label className={TEXTSIZE+" font-bold"}>
                            { qstatus === 'Vt' ? localeMap["monitor.quest.tally.count"] : localeMap["monitor.quest.tally.result"]}
                        </label>
                        <div className="w-full ring-slate-800 ring-inset ring-1 rounded-2xl flex flex-col justify-start items-stretch gap-4">  
                            <EMComQstHeader poll={poll} pqst={pqst} bold={true} size={SSIZE} className="pt-4 px-4" 
                                folded={qFolded} setFolded={setQFolded} topView={topView} setTopView={setTopView} topN={tanswers.length} />
                            <div className="w-full h-[1px] bg-slate-200"></div>
                            <PollPqstResult.RRank pqst={pqst} qround={pqst.qround} size={SSIZE} className="px-10" topView={topView} />
                            <div className="w-full h-[1px] bg-slate-800"></div>
                            <PollPqstGoal.Footer poll={poll} qidx={qidx} ranswers={ranswers} size={SSIZE} className="pb-4 px-4" initFolded={true} />
                        </div>
                    </div>
                : 
                    <label className={TEXTSIZE+" "}>invalid status &emsp; pstatus={pstatus} &emsp; qstatus={qstatus}</label>
            )
            : pstatus  >= 'Oz' && pstatus <= 'Xc' ?
                <div className="w-full flex flex-col justify-start items-stretch gap-8">
                    <label className={TEXTSIZE+" w-full font-bold text-center opacity-50"}>
                        {localeMap["monitor.result.title"]}
                    </label>

                    <PollPqstResult.List poll={poll} size={size} initSelected={poll.pqstlist[0].qguid} />

                </div>
            : 
                <div>&nbsp;</div>
            }
        </div>
    );
}

