import React from "react";
import { useSelector } from "react-redux";

import { utils } from "../../config/userConfig";

// const rex_passwd = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
const rex_length = /^.{8,25}$/;
const rex_uchar = /^.*[A-Z].*$/;
const rex_digit = /^.*[0-9].*$/;
const rex_schar = /^.*[.,!@#$%^*+=-].*$/;

export default function AzInputForPWord({v={value:'',errmsg:''}, setV=undefined, 
    onChange=(text)=>{}, onChangeDone=(text,enter)=>{}, vref=undefined,
    name=undefined, className='', placeholder='', disabled=false, maxLength=-1, autocomplete='off'}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const id = React.useId();

    function onChangeContinue(text) {
        if (typeof setV === 'function') {
            let new_value = text    // update the text
            let new_errmsg = ''     // clear error message
            setV({ ...v, value:new_value, errmsg:new_errmsg });
        }
        if (typeof onChange === 'function') onChange(text);
    }
    function onChangeFinished(text, enter) {
        if (typeof setV === 'function') {
            let new_value = text    // update the text
            let new_errmsg = ''     // clear error message
            // check the validity of the text
            if (text !== '') {
                if (vref && vref.value !== text) {
                    new_errmsg = localeMap["auth.errmsg.pword.mismatch"];
                } else if (!utils.isValidPasswd(text)) {
                    if     (!rex_length.test(text)) new_errmsg = localeMap["auth.errmsg.pword.length"];
                    else if (!rex_uchar.test(text)) new_errmsg = localeMap["auth.errmsg.pword.uchar"];
                    else if (!rex_digit.test(text)) new_errmsg = localeMap["auth.errmsg.pword.digit"];
                    else if (!rex_schar.test(text)) new_errmsg = localeMap["auth.errmsg.pword.schar"];
                    else new_errmsg = localeMap["auth.errmsg.pword.invalid"];
                }
            }
            setV({ ...v, value:new_value, errmsg:new_errmsg });
        }
        if (typeof onChangeDone === 'function') onChangeDone(text, enter);
    }
    
    return (
        <div className="flex flex-col justify-start items-stretch gap-0">
            <label className="font-bold text-xl text-left">{placeholder}:</label>
            <input 
                className={" px-3 py-3 ring-inset ring-1 rounded-sm text-left text-3xl " + className} 
                id={id} name={name} disabled={disabled ? 'disabled' : ''}
                autoFocus={false} autoComplete={autocomplete} 
                type="password" maxLength={maxLength} placeholder='' 
                value={v?.value} 
                onChange={(e)=>{ onChangeContinue(e.target.value); }}
                onKeyDown={(e)=>{ if (e.key==='Enter') onChangeFinished(e.target.value, true); }}
                onBlur={(e)=>{ onChangeFinished(e.target.value, false); }}
            />
            { v && v.errmsg !== '' && <label className="text-xs text-slate-500 text-right">{v.errmsg}</label> }
        </div>
    );
}
