const localeStrMap = {
    "about.login.required": "Please log in first.",

    "about.feedback.fab": "Feedback",
    "about.feedback.title": "What do you think about this service?",
    "about.feedback.desc": "Please give us your opinion, so that we can improve Pollerz.net.",
    "about.feedback.type0": "just a comment",
    "about.feedback.type1": "bug report",
    "about.feedback.type2": "feature request",
    "about.feedback.newsletter": "Want to subscribe to Email newsletter?",
    "about.feedback.newsletter.subscribe": "Subscribe",
    "about.feedback.newsletter.desc": "News and blog updates are included, being sent at most once in a month.",
    "about.feedback.send": "Send your feedback",

    "about.pricing.sp.title": "Service Price",
    "about.pricing.sp.basic": "Basic price",
    "about.pricing.sp.extra": "Extra price for online invitation",
    "about.pricing.sp.mfactor": "Multiplication factor",
    "about.pricing.sp.formula": "Cost calculation formula",
    "about.pricing.fq.title": "Free Quota",
    "about.pricing.fq.forall": "Monthly free quota for every user",
    "about.pricing.fq.qtrem": "My remaining free quota",
    "about.pricing.fq.qtmax": "My monthly (max.) free quota",
    "about.pricing.fq.refill": "Next datetime for free quota refill",
    "about.pricing.cc.title": "Cost Calculator",
    "about.pricing.cc.online": "with Online Invitation",
    "about.pricing.cc.offline": "without Online Invitation",
    "about.pricing.cc.voter": "voters",
    "about.pricing.cc.quest": "questions",
    "about.pricing.cc.ested": "Estimated total cost",
    "about.pricing.cc.usefq": "Applicability of free quota",
    "about.pricing.cc.final": "Final cost to pay",
    "about.pricing.cm.title": "Just in case you want comparison...",

    "forgotten.msg1": "Thank you for everything,\n your account has been successfully deleted.",
    "forgotten.msg2": "We hope to serve you again in the future,\n with upgraded features in a fresh relationship.",
    "notfound.request": "The requested page does not exist",
    "notfound.nav.home": "Navigate to Home page",
    "notfound.nav.login": "Navigate to log in",

    "comm.etype.p": "poll",             // this will replace '{etype}' in other locale strings
    "comm.etype.s": "survey",           // this will replace '{etype}' in other locale strings
    "comm.etype.q": "quiz",             // this will replace '{etype}' in other locale strings
    "comm.voters.p": "voters",             // this will replace '{voters}' in other locale strings
    "comm.voters.s": "participants",       // this will replace '{voters}' in other locale strings
    "comm.voters.q": "participants",       // this will replace '{voters}' in other locale strings
    "comm.voter.p": "voter",             // this will replace '{voter}' in other locale strings
    "comm.voter.s": "participant",       // this will replace '{voter}' in other locale strings
    "comm.voter.q": "participant",       // this will replace '{voter}' in other locale strings
    "comm.button.ok": "OK",
    "comm.button.cancel": "Cancel",
    "comm.button.yes": "Yes",
    "comm.button.no": "No",
    "comm.button.close": "Close",
    "comm.underdev": "Under Dev",    // 

    "errmsg.network": "Please check the network and try it later",
    "errmsg.unknown": "Unknown error",

    "auth.login.title": "Welcome back",
    "auth.login.subtitle": "",
    "auth.login.input.email": "Email",
    "auth.login.input.password": "Password",
    "auth.login.button": "Log in",
    "auth.login.nav1.label": "Don't have an account yet?",
    "auth.login.nav1.button": "Sign up",
    "auth.login.nav2.label": "Forgot password?",
    "auth.login.nav2.button": "Reset",
    "auth.login.errmsg.missed": "The email or password is incorrect. ({1}/{2})",
    "auth.login.errmsg.missxx": "You have exceeded the maximum number of login attempts. Please try again in 5 minutes.",
    "auth.login.errmsg.nouser": "The account does not exist.",
    "auth.login.errmsg.server": "Failed to sign in. Please try again.",
    "auth.resetpw.step1.title": "Reset forgotten password",
    "auth.resetpw.step1.subtitle": "We will send a 6-digit verification code to your email.",
    "auth.resetpw.step1.input.email": "Email",
    "auth.resetpw.step1.button": "Send me email",
    "auth.resetpw.step2.title": "Verify and reset your password",
    "auth.resetpw.step2.subtitle": "We've sent a 6-digit verification code to '{1}'. If you don't see it, please check your spam folder and make sure your email address is spelled correctly.",
    "auth.resetpw.step2.input.vcode": "Verification code",
    "auth.resetpw.step2.input.pword1": "New password",
    "auth.resetpw.step2.input.pword2": "Confirm password",
    "auth.resetpw.step2.button": "Reset my password",
    "auth.resetpw.errmsg.notfound": "Email address cannot be found",
    "auth.signup.step1.title": "Create your account",
    "auth.signup.step1.subtitle": "We support and innovate our collective decision making.",
    "auth.signup.step1.input.uname": "Name",
    "auth.signup.step1.input.email": "Email",
    "auth.signup.step1.button": "Sign up",
    "auth.signup.step1.nav1.label": "By clicking 'Sign up', you agree to our",
    "auth.signup.step1.nav1.button": "Terms & Privacy Policy",
    "auth.signup.step1.nav2.label": "Already have an account?",
    "auth.signup.step1.nav2.button": "Log in",
    "auth.signup.step2.title": "Verify and set your password",
    "auth.signup.step2.subtitle": "We've sent a 6-digit verification code to '{1}'. If you don't see it, please check your spam folder and make sure your email address is spelled correctly.",
    "auth.signup.step2.input.vcode": "Verification code",
    "auth.signup.step2.input.pword1": "Password",
    "auth.signup.step2.input.pword2": "Confirm password",
    "auth.signup.step2.button": "Create my account",
    "auth.chpword.title": "Change password",
    "auth.chpword.subtitle": "",
    "auth.chpword.input.pword0": "Old password",
    "auth.chpword.input.pword1": "New password",
    "auth.chpword.input.pword2": "Confirm password",
    "auth.chpword.button": "Change password",
    "auth.chpword.errmsg.notfound": "Email address is invalid",
    "auth.chpword.errmsg.oldinvalid": "Old password doesn't match",
    "auth.chpword.errmsg.newinvalid": "New password is invalid",
    "auth.chemail.title": "Change email address",
    "auth.chemail.subtitle.step1": "We will send a verification code to new email address.",
    "auth.chemail.subtitle.step2": "Enter the 6-digit verification code to activate the change",
    "auth.chemail.input.oldemail": "Old email address",
    "auth.chemail.input.newemail": "New email address",
    "auth.chemail.input.vcode": "Verification code",
    "auth.chemail.button.step1": "Send me verification code",
    "auth.chemail.button.step2": "Change email address",
    "auth.chemail.errmsg.emailtaken": "Email address is already taken.",
    "auth.vcode.errmsg.notfound": "Email address cannot be found",
    "auth.vcode.errmsg.emailtaken": "Email address is already taken",
    "auth.vcode.errmsg.emailerror": "Failed to send email - Try it later",
    "auth.vcode.errmsg.nomatch": "Verification code doesn't match",
    "auth.vcode.retry.label": "Haven't still received the email?",
    "auth.vcode.retry.button": "Try again",
    "auth.errmsg.vcode.invalid": "it should be 6 digits",
    "auth.errmsg.email.invalid": "invalid email address",
    "auth.errmsg.uname.invalid": "invalid name",
    "auth.errmsg.pword.invalid": "invalid password",
    "auth.errmsg.pword.mismatch": "password don't match",
    "auth.errmsg.pword.length": "length should be between 8 and 25",
    "auth.errmsg.pword.uchar": "include at least one capital letter",
    "auth.errmsg.pword.digit": "include at least one digit letter",
    "auth.errmsg.pword.schar": "include at least one special character",
    "auth.errmsg.network": "Please check the network and try it later",
    "auth.errmsg.unknown": "Unknown error",
    "auth.extsession.title": "Extend your login session",
    "auth.extsession.direction": "Click the 'Extend' button below \n or press SPACE key to extend.",
    "auth.extsession.remaining": "logging out in {1} seconds",
    "auth.extsession.button": "Extend",
    "auth.extsession.toast": "Your log-in session was extended.",

    "home.intro.title": "The most fast-easy-secure way\nto ask many people's opinion",
    "home.intro.subtitle": "Fully automated online voting,\n where you can make the final decision \n in a single minute",
    "home.intro.serving": "Having served {1} events and {2} voters over {3} countries",
    "home.intro.diff.title": "Difference from other services",
    "home.intro.diff.claim": "Online voting to make your final decision \n in one single minute",
    "home.intro.why.title": "Why you need Pollerz.net",
    "home.why.label0": "Fast",
    "home.why.label1": "Low Cost",
    "home.why.label2": "Easy",
    "home.why.label3": "Secure",
    "home.why.label4": "Staff support",
    "home.why.desc0": "Pollerz.net automates the entire decision making process which includes automatic evaluation and multiple voting rounds due to draw or absence of dominant opinion. You can make the final decision in a single minute, without starting over again for revoting.",
    "home.why.desc1": "The biggest cost of voting is not the cost of executing the event itself, but the cost of inviting people and the total sum of the time spent by all the voters. Pollerz.net eliminates the big social cost of repeating the poll again.",
    "home.why.desc2": "It shouldn’t be hard at all for you to ask and aggregate the opinions of other people, especially with all the technologies we now have in our hands. No installation or subscription should be required for the participants to vote.",
    "home.why.desc3": "Every vote is securely counted, and secrecy of anonymous vote is strictly protected. The voters' personal information will never be used for any purposes other than the poll itself, and it will be permanently deleted after the poll.",
    "home.why.desc4": "You can assign staff members and give them appropriate permissions for the event you created, so that they can help you to execute the event, check eligibility of voters, and assist the voters face-to-face to vote.",
    "home.goal.boostdm": "Boost our collective decision making \n for democracy.",
    "home.goal.servepp": "Serve people, \n and never try to influence or lock people in.",
    "home.goal.staysml": "Stay small, light, and agile, \n without compromising security.",
    "home.goal.desc.boostdm": "Anonymous voting, where each person has one vote, is fundamental to democracy. It signifies respect for others' viewpoints and a commitment to recognizing majority opinion through fair procedures, even when it differ from my own. Pollerz.net aims to support this opinion gathering process to be fast, simple, and secure, hoping to contribute to democratic decision-making process.",
    "home.goal.desc.support": "The aim of Pollerz.net is to support people's free expression of their opinions, and we oppose any attempts to influence them. Pollerz.net does not analyze user profiles or seek to create lock-in effects. Furthermore, we do not collect or utilize personal information of event participants, ensuring that all such data is deleted from the database after the final event report is compiled.",
    "home.goal.desc.staysml": "We have often witnessed tech companies shift from their initial drive for user value and morph into entities prioritizing their own scale, revenue, and profit. We do not pursue growth in this manner. Instead, we aim to remain a small, agile, and efficient organization, dedicated to consistently delivering trusted services to our users.",
    "home.button.try": "Try it yourself",
    "home.button.try.asvoter": "Try it as a voter",
    "home.button.try.aspoller": "Try it as a poller",

    "home.page.mission.title": "Our Mission",
    "home.page.features.title": "Features",
    "home.page.pricing.title": "Pricing Policy",
    "home.page.privacy.title": "Privacy Policy",
    
    "menu.auth.login": "Log in",
    "menu.auth.logout": "Log out",
    "menu.auth.signup": "Sign up",
    "menu.home.features": "Features",
    "menu.home.pricing": "Pricing",
    "menu.myaccount" : "My Account", 
    "menu.myevents": "My Events",
    "menu.poller": "As Poller",
    "menu.poller.editevent": "Edit & Test Event",
    "menu.poller.getready": "Get Ready",
    "menu.poller.voterlist": "List of Eligible Voters",
    "menu.poller.stafflist": "List of Staff Members",
    "menu.poller.invite": "Send Invitation",
    "menu.poller.runpoll": "Run Event",
    "menu.poller.xresult": "Final Result",
    "menu.poller.archive": "Save in the Archive",
    "menu.staff": "As Staff",
    "menu.staff.prepare": "Help Preparing Event",
    "menu.staff.support": "Help Running Event",
    "menu.staff.sresult": "Final Result",
    "menu.xresult.monitor": "Result Monitor",
    "menu.voter": "As Voter",
    "menu.voter.vote": "Vote Myself",

    "modal.button.cancel": "Cancel",
    "modal.button.more": "Advanced Settings",
    "modal.button.save": "Save",

    "myaccount.title" : "My Account", 
    "myaccount.user.label" : "Account", 
    "myaccount.username.label" : "Name", 
    "myaccount.username.change" : "Change name", 
    "myaccount.userid.label" : "User ID", 
    "myaccount.userid.change" : "Change", 
    "myaccount.userpw.label" : "User Password", 
    "myaccount.userpw.change" : "Change", 
    "myaccount.env.label" : "Settings", 
    "myaccount.env.country.label" : "Country (Residence)", 
    "myaccount.env.language.label" : "Selected Language", 
    "myaccount.env.timezone.label" : "Selected Timezone", 
    "myaccount.quota.label" : "Free Quota", 
    "myaccount.quota.remain.label" : "Remaining", 
    "myaccount.quota.monthly.label" : "Monthly Max Refill", 
    "myaccount.quota.notice" : "Free quota will be refilled on the first day of each month (UTC).", 
    "myaccount.quota.nextrefill.label" : "Next Refill", 
    "myaccount.paym.label" : "Payment", 
    "myaccount.rtbf.label" : "Right to be \nForgotten", 
    "myaccount.rtbf.warning" : "Warning: These tasks are irreversable.", 
    "myaccount.rtbf.exception" : "Payment records are the only exception of the deletion, and will remain. The remaining data will be minimal, as required by the law in many countries, and it will be automatically destroyed after three years.", 
    "myaccount.rtbf.data.label" : "Delete all my events and their related data", 
    "myaccount.rtbf.data.direction" : "Please enter '{1}' below, and then click the button.", 
    "myaccount.rtbf.data.keyword" : "ALL MY EVENTS", 
    "myaccount.rtbf.data.button" : "Delete all my events", 
    "myaccount.rtbf.acct.label" : "Delete my account and forget about me", 
    "myaccount.rtbf.acct.direction" : "Please enter '{1}' below, and then click the button.", 
    "myaccount.rtbf.acct.keyword" : "EVERYTHING", 
    "myaccount.rtbf.acct.button" : "Delete my account", 

    "myevents.needtologin" : "You need to login to use this menu", 
    "myevents.planned" : "Planned Events", 
    "myevents.archived" : "Archived Events", 
    "myevents.sysevents" : "System Events",
    "myevents.none.planned" : "You have no events planned at this moment.",
    "myevents.none.archived" : "You have no events archived at this moment.",
    "myevents.none.sysevent" : "You have no system events at this moment.",
    "myevents.none.archmsg" : "All the closed events will be saved/moved here after a week.",

    "poll.summary.label" : "Summary", 
    "poll.summary.expire.notopened" : "기한 내에 개최되지 않아, {YYYY/MM/DD HH:mm}에 삭제될 예정입니다.", 
    "poll.summary.expire.notclosed" : "기한 내에 종결되지 않아, {YYYY/MM/DD HH:mm}에 삭제될 예정입니다.", 
    "poll.summary.status.A0" : "Now under Planning", 
    "poll.summary.status.Ac" : "Now under Planning", 
    "poll.summary.status.Ar" : "Planned and waiting for purchase", 
    "poll.summary.status.Ax" : "Ready to open", 
    "poll.summary.status.Oo" : "Openning", 
    "poll.summary.status.Os" : "Started", 
    "poll.summary.status.Oz" : "Wrapping up", 
    "poll.summary.status.Xc" : "Closed", 
    "poll.summary.status.Xx" : "Archived", 
    "poll.card.questions.label" : "Questions", 
    "poll.card.voters.label" : "{Voters}", 
    "poll.card.voters.elg.planned" : "{1} {voters} registered, and MAX {2} can attend", 
    "poll.card.voters.elg.closed" : "{1} {voters} registered, and {2} attended", 
    "poll.card.voters.any.planned" : "No registered {voters}, and MAX {1} can attend", 
    "poll.card.voters.any.closed" : "No registered {voters}, and {1} attended", 
    "poll.card.staff.label" : "Staff", 
    "poll.card.staff.summary" : "Poller '{1}', supported by {2} staff members", 
    "poll.card.staff.myrole.p" : "Organizer", 
    "poll.card.staff.myrole.m" : "Manager", 
    "poll.card.staff.myrole.s" : "Staff", 
    "poll.card.button.edit" : "Edit", 
    "poll.card.button.getready" : "Get Ready", 
    "poll.card.button.invite" : "Invite", 
    "poll.card.button.run" : "Run", 
    "poll.card.button.prepare" : "Help Preparing", 
    "poll.card.button.support" : "Help Running", 
    "poll.card.button.xresult" : "View Result", 

    "poll.create_event" : "Create a New Event", 
    "poll.create.voters.direction" : "Enter the list of {voters} below, and click '{1}'", 
    "poll.create.voters.extract" : "Extract list", 
    "poll.create.voters.errmsg" : "Unrecognized string was found", 
    "poll.create.voters.none" : "No {voter} being registered.", 
    "poll.create.voters.some" : "{1} {voters} being registered", 
    "poll.create.button.prev": "Previous Step",
    "poll.create.button.next": "Next Step",
    "poll.create.button.create": "Create event",
    "poll.criteria.Majority" : "Majority", 
    "poll.criteria.Most" : "Most votes", 
    "poll.criteria.Zero" : "No Vote",
    "poll.criteria.Least" : "Least votes", 
    "poll.criteria.Top2x" : "Below Top 2x rankers", 
    "poll.criteria.Top3x" : "Below Top 3x rankers", 
    "poll.criteria.Top4x" : "Below Top 4x rankers", 
    "poll.edit.simtest": "Test as a virtual event",
    "poll.edit.save": "Save Event",
    "poll.edit.savemsg": "All the data of this event will be saved in the cloud.",
    "poll.edit.delete": "Delete Event",
    "poll.edit.deletemsg": "All the data of this event will be permanently deleted.",
    "poll.edit.archive": "Archive Event",
    "poll.edit.archivemsg": "All the data of this event will be moved into the archive.",

    "poll.xresult.plabel.tnk" : "Title & Kind",
    "poll.xresult.plabel.qna" : "Question & Candidates",
    "poll.xresult.plabel.gnc" : "Goal & Criteria",
    "poll.xresult.plabel.xns" : "Result & Statistics",
    "poll.xresult.label.residual" : "Residual votes",
    "poll.xresult.label.total" : "Total votes",

    "poll01.pkind.label" : "Type", 
    "poll01.ptitle.label" : "Title", 
    "poll01.ptitle.example" : "The 0-th Council Meeting", 
    "poll01.ptitle.placeholder" : "Enter the event title", 
    "poll01.uname.placeholder" : "Enter organizer name", 
    "poll01.pwhen.label" : "When", 
    "poll01.pwhen.rightnow" : "Right now", 
    "poll01.pwhen.rightnow.constraint" : "(To be open in an hour after creation)", 
    "poll01.pwhen.rightnow.expire" : "It will be expired at {hh:mm:ss} and deleted", 
    "poll01.pwhen.scheduled" : "Scheduled at", 
    "poll01.pearly.label" : "Early voting", 
    "poll01.pearly.yes" : "Allowed", 
    "poll01.pearly.no" : "Not allowed", 
    "poll01.locale.label" : "Locale", 
    "poll01.poller.label" : "Poller", 
    
    "poll02.qkind.label" : "Question Type", 
    "poll02.qkind.A" : "Approval", 
    "poll02.qkind.S" : "Selection", 
    "poll02.qkind.E" : "Election", 
    "poll02.qkind.C" : "Comment", 
    "poll02.question.example" : "The #{1} question ?", 
    "poll02.question.example.A" : "Do you approve the budget plan ?", 
    "poll02.question.example.S" : "Which alternatives do you support ?", 
    "poll02.question.example.E" : "Whom do you like most as our president ?", 
    "poll02.question.label" : "Question", 
    "poll02.question.placeholder" : "Enter the question", 
    "poll02.answers.clabel" : "Answers", 
    "poll02.answers.slabel" : "Alternatives", 
    "poll02.answers.elabel" : "Candidates", 
    "poll02.answers.placeholder" : "Enter one candidate on each line", 
    "poll02.answers.A" : "Yes\nNo", 
    "poll02.answers.S" : "Alternative 1\nAlternative 2\nAlternative 3\nAlternative 4\nAlternative 5\nAlternative 6", 
    "poll02.answers.E" : "Candidate 1\nCandidate 2\nCandidate 3", 
    "poll02.vgoal.label" : "Desired Goal", 
    "poll02.vgoal.maxrounds" : "MaxRounds", 
    "poll02.xresult.label" : "Result:", 
    "poll02.xresult.A" : "Undecided/Approved/Rejected", 
    "poll02.xresult.S" : "Undecided/Selected '{1}'/Selected '{1}' (+{2})", 
    "poll02.xresult.E" : "Undecided/Elected '{1}'/Elected '{1}' (+{2})", 
    "poll02.xresult.C" : "Undecided/Collected {1} comments", 
    "poll02.wcriteria.label.A" : "Criteria to be approved", 
    "poll02.wcriteria.label.S" : "Criteria to be selected", 
    "poll02.wcriteria.label.E" : "Criteria to be elected", 
    "poll02.dcriteria.label" : "Criteria to be dropped", 
    "poll02.button.appendnew" : "Add Next Question", 
    "poll02.button.removelast" : "Remove Last Question", 
    "poll02.button.showdetail" : "Show detail", 
    "poll02.button.save" : "Save Changes", 
    "poll02.button.delete" : "Delete Question", 
    "poll02.completed.ready" : "Your poll event is complete and ready.", 
    "poll02.completed.todo" : "Please log-in or sign-up\n inorder to save the event.", 

    "poll03.vlist.title" : "{Voter} list", 
    "poll03.vlist.clear": "참가대상자 명단 전체 삭제",
    "poll03.vlist.clearmsg": "본 이벤트에 등록된 참가대상자 명단을 초기화합니다.",
    "poll03.voter.direction1" : "Edit the entire list of participants, and click 'Save List' button.", 
    "poll03.voter.direction2" : "Line example : 'John Doe, 20041230, any@example.com' (Name,BDate,Phone,Email)", 
    "poll03.voter.errmsg.no_email" : "No email provided for a participant.", 
    "poll03.voter.errmsg.no_phone" : "No phone number provided for a participant.", 
    "poll03.voter.errmsg.no_name" : "No name provided for a participant.", 
    "poll03.voter.errmsg.empty_list" : "Empty list of participants.", 
    "poll03.voter.action.add" : "Add participant", 
    "poll03.voter.action.edit" : "Edit participant", 
    "poll03.voter.action.text" : "Edit entire list as text", 
    "poll03.voter.action.clear" : "Clear entire list", 
    "poll03.voter.button.save" : "Save", 
    "poll03.voter.button.delete" : "Delete", 
    "poll03.voter.label.vid" : "ID", 
    "poll03.voter.label.vname" : "Name", 
    "poll03.voter.label.vcode" : "Code", 
    "poll03.voter.label.vbdate" : "BDate", 
    "poll03.voter.label.vemail" : "Email", 
    "poll03.voter.label.vphone" : "Phone", 

    "poll04.slist.title" : "Staff member list", 
    "poll04.slist.clear": "스태프 명단 전체 삭제",
    "poll04.slist.clearmsg": "본 이벤트에 등록된 스태프멤버 명단을 초기화합니다.",
    "poll04.staff.direction1" : "Enter the list of staff members, and click 'Save List' button.", 
    "poll04.staff.direction2" : "Line example : 'John Doe, someone@example.com, S' (Name,Email,Role)", 
    "poll04.staff.errmsg.no_email" : "No email provided for a staff member.", 
    "poll04.staff.errmsg.no_name" : "No name provided for a staff member.", 
    "poll04.staff.errmsg.empty_list" : "Empty list of staff members.", 
    "poll04.staff.action.add" : "Add staff member", 
    "poll04.staff.action.edit" : "Edit staff member", 
    "poll04.staff.action.text" : "Edit entire list as text", 
    "poll04.staff.action.clear" : "Clear entire list", 
    "poll04.staff.button.save" : "Save", 
    "poll04.staff.button.delete" : "Delete", 
    "poll04.staff.label.name" : "Name", 
    "poll04.staff.label.email" : "Email", 
    "poll04.staff.label.role" : "Role", 
    "poll04.staff.label.role.manager" : "Manager", 
    "poll04.staff.label.role.staff" : "Staff", 

    "poller.editevent.event" : "Event info",
    "poller.editevent.qlist" : "Question list",
    "poller.editevent.quest" : "Question detail",

    "poller.getready.voters" : "Registered voters",
    "poller.getready.voters.sum0" : "누구나 참여할 수 있는 이벤트입니다",
    "poller.getready.voters.sum1" : "{1}명의 참가대상자가 등록되었습니다",
    "poller.getready.voters.sumo" : "{1}명 중 {2}명이 참가중입니다",
    "poller.getready.voters.sumx" : "{1}명 중 {2}명이 참가하였습니다",
    "poller.getready.voters.nt" : "Registered total",
    "poller.getready.voters.nt0" : "누구나 참여할 수 있습니다.",
    "poller.getready.voters.nt1" : "등록된 참가대상자들만 참여할 수 있습니다.",
    "poller.getready.voters.ne" : "With email address",
    "poller.getready.voters.np" : "With phone numbers",
    "poller.getready.voters.na" : "With email/phone",
    "poller.getready.voters.na0" : "'온라인 초대' 기능은 사용할 수 없습니다.",
    "poller.getready.voters.na1" : "{1}명의 참가자 정보가 부족합니다.",
    "poller.getready.voters.na2" : "'온라인 초대' 기능을 사용할 수 있습니다.",
    "poller.getready.voters.nmax" : "최대 참가자 수",
    "poller.getready.voters.natt" : "실제 참가자 수",
    "poller.getready.voters.list" : "List of registred voters",
    "poller.getready.voters.example" : "Add 5 examples to the list",

    "poller.getready.staffs" : "Staff members",
    "poller.getready.staffs.sum0" : "스태프 지원 없이 스스로 이벤트를 진행합니다",
    "poller.getready.staffs.sum1" : "{1}명의 스태프멤버가 이벤트 진행을 지원합니다",
    "poller.getready.staffs.nm" : "등록된 매니저 수",
    "poller.getready.staffs.nm0" : "주최자를 대신하여 이벤트를 진행할 수 있습니다.",
    "poller.getready.staffs.ns" : "등록된 일반 스태프 수",
    "poller.getready.staffs.ns0" : "오프라인에서 참가자들을 지원합니다.",
    "poller.getready.staffs.list" : "스태프멤버 명단",
    "poller.getready.staffs.example" : "Add 3 examples to the list",

    "poller.getready.pca.title" : "Participant Control & Authentication",
    "poller.getready.pca.sum0" : "인증방식을 선택하셔야 합니다",
    "poller.getready.pca.sum1" : "서비스를 구매하셔야 합니다",
    "poller.getready.pca.sum2" : "개최를 위한 모든 준비가 완료되었습니다",
    "poller.getready.pca.tbd" : "To be determined",
    "poller.getready.pca.n.label" : "참여인원수",
    "poller.getready.pca.n.min" : "최소 참여인원수",
    "poller.getready.pca.n.max" : "최대 참여인원수",
    "poller.getready.pca.n.elg" : "등록된 인원수",
    "poller.getready.pca.inv" : "Invitation",
    "poller.getready.pca.offline" : "오프라인 (QR코드를 게시 또는 배포)",
    "poller.getready.pca.online" : "온라인 (이메일/메시지로 초대장 전송)",
    "poller.getready.pca.nmin" : "Min. participants",
    "poller.getready.pca.nmax" : "Max. participants",
    "poller.getready.pca.nmin.desc" : "이벤트가 유효하게 성립하기 위한 최소 조건입니다",
    "poller.getready.pca.nmax.desc" : "초과하는 경우, 참가자의 입장이 제한됩니다",
    "poller.getready.pca.nmax.cost" : "(참가자 당 {1} 기본비용)",
    "poller.getready.pca.comm.cost" : "(참가자 당 {1} 추가비용)",
    "poller.getready.pca.auth" : "Authentication",
    "poller.getready.pca.auth.qr" : "QR",
    "poller.getready.pca.auth.allow" : "Allow",
    "poller.getready.pca.auth.check" : "Check",
    "poller.getready.pca.auth.simplicity" : "Simplicity",
    "poller.getready.pca.auth.security" : "Security",
    "poller.getready.pca.auth.A" : "Anyone",
    "poller.getready.pca.auth.E" : "Registered",
    "poller.getready.pca.auth.1" : "Shared",
    "poller.getready.pca.auth.9" : "Individual",
    "poller.getready.pca.auth.__" : "None",
    "poller.getready.pca.auth.n_" : "Name",
    "poller.getready.pca.auth.nc" : "Name,Passcode",
    "poller.getready.pca.pcode" : "Personal passcode",
    "poller.getready.pca.pcode.c" : "공통 식별코드 사용",
    "poller.getready.pca.pcode.i" : "개인 {1}의 마지막 4자리 숫자/문자",
    "poller.getready.pca.pcode.r" : "개인별 임의생성 후 온라인 전송",
    "poller.getready.pca.price.label" : "Service cost",
    "poller.getready.pca.price.total" : "Total price",
    "poller.getready.pca.price.topay" : "Actual cost to pay",
    "poller.getready.pca.fq.rem" : "Remaining free quota",
    "poller.getready.pca.fq.after" : "Free quota after purchase",
    "poller.getready.pca.fq.payable" : "Usability of free quota",
    "poller.getready.pca.fq.yes" : "Usable",
    "poller.getready.pca.fq.no" : "Not usable",
    "poller.getready.pca.button.edit" : "Edit Options",
    "poller.getready.pca.paid" : "결재가 완료되었습니다",

    "poller.getready.payfq.title" : "Buy with Free Quota",
    "poller.getready.payfq.remaining" : "Remaining FQ",
    "poller.getready.payfq.tobeused" : "FQ To be used",
    "poller.getready.payfq.newprice" : "Price to Pay",
    "poller.getready.payfq.button" : "Buy",

    "poller.getready.paymn.title" : "Payment",

    "poller.invite.boardtitle" : "Invitation",

    "poller.runpoll.open.event" : "이벤트 참가자 입장허용 시작",
    "poller.runpoll.open.runner" : "이벤트 개최화면 열기",
    "poller.runpoll.xpoll.title" : "이벤트 종료",
    "poller.runpoll.xpoll.msg" : "이벤트를 종료하고, 모든 참가자들의 접속을 즉시 차단합니다.\n 계속하시겠습니까?",
    "poller.runpoll.ypoll.title" : "이벤트 조기종료 준비",
    "poller.runpoll.ypoll.msg" : "남은 질문들은 무시하고,\n 이벤트를 조기종료하고자 최종결과를 준비합니다.\n 계속하시겠습니까?",
    "poller.runpoll.zpqst.title" : "이벤트 현재질문 초기화",
    "poller.runpoll.zpqst.msg" : "현재 진행중인 질문에 대하여 \n 투표내용을 초기화하고 투표를 다시 시작합니다. \n 계속하시겠습니까?",
    "poller.runpoll.deadline.broken" : "이벤트 예정시간을 초과하였습니다",

    "poller.xreport.boardtitle" : "Final Report",

    "staff.entrance.boardtitle" : "Poll Entrance",
    "staff.eligibility.boardtitle" : "Eligibility Check",
    "staff.votehelper.boardtitle" : "Vote Helper",
    "staff.watchpoll.boardtitle" : "Watch Poll",
    "staff.view.entrance.qr" : "View Entrance QR-code",
    "staff.view.entrance.png" : "Download Entrance Image (PNG)",
    "staff.view.entrance.pdf" : "Download Printable File (PDF)",
    "staff.open.runner.as.manager" : "Open to Run Event as a Manager",
    "staff.open.support.as.staff" : "Open to Assist Voters as a Staff",
    "staff.xresult.boardtitle" : "Final Result",

    "monitor.event.poller.label": "Poller",
    "monitor.event.eligibles.label": "{Voter}",
    "monitor.event.attendee.label": "{Voter}",
    "monitor.event.myname.label": "My name",
    "monitor.quest.selectfirst": "Select the first question to be asked",
    "monitor.quest.selectnext": "Select the next question to be asked",
    "monitor.quest.planned": "The following questions are planned to be asked",
    "monitor.quest.voting.asked": "Voting started for the question below",
    "monitor.quest.voting.now": "Please vote now",
    "monitor.quest.chosen.A": "Chosen answer",
    "monitor.quest.chosen.S": "Chosen answers so far",
    "monitor.quest.chosen.E": "Chosen candidates so far",
    "monitor.quest.goal.label" : "Goal",
    "monitor.quest.goal.A" : "Approve or Reject",
    "monitor.quest.goal.S" : "Select {1} among {2}",
    "monitor.quest.goal.E" : "Elect {1} among {2}",
    "monitor.quest.goal.roundmax" : "Maximum number of Rounds",
    "monitor.quest.goal.total" : "Total",
    "monitor.quest.goal.selected" : "Selected",
    "monitor.quest.goal.dropped" : "Dropped",
    "monitor.quest.goal.vcheck.S" : "{1}개만 선택하였으며 나머지는 기권처리됩니다.\n그대로 투표하시겠습니까?",
    "monitor.quest.goal.vcheck.E" : "{1}명만 선택하였으며 나머지는 기권처리됩니다.\n그대로 투표하시겠습니까?",
    "monitor.quest.criteria.var" : "Variable",
    "monitor.quest.criteria.abs" : "Absolute",
    "monitor.quest.criteria.natt" : "(of Attendees)",
    "monitor.quest.tally.count" : "Tallying up the votes",
    "monitor.quest.tally.result" : "Vote tally result",
    "monitor.quest.result.chosen" : "Chosen",
    "monitor.quest.result.todo" : "To do",
    "monitor.quest.todo.done" : "Achieved the goal",
    "monitor.quest.todo.fail0" : "미결정 상태로 완료되었습니다",
    "monitor.quest.todo.fail1" : "일부({1}) 미결정 상태로 완료되었습니다",
    "monitor.quest.todo.A" : "Voting again in the next round",
    "monitor.quest.todo.S" : "Select {1} out of {2} in the next round",
    "monitor.quest.todo.E" : "Elect {1} out of {2} in the next round",
    "monitor.progress.checkin": "Attendee check-in progress",
    "monitor.progress.checked": "Minimum number of attendees ({1}) satisfied",
    "monitor.progress.voting": "Voting in progress",
    "monitor.progress.rresult.ends": "Round {1} : {2} selected",
    "monitor.progress.rresult.endf": "Round {1} : {2} selected, closing with {3} undecided",
    "monitor.progress.rresult.next": "Round {1} : {2} selected, next round for {3} more",
    "monitor.result.title": "Final Result",
    "monitor.result.finalresult": "Final Result",
    "monitor.result.votecounts": "Vote Counts",
    "monitor.result.nochosen.A": "Undecided",
    "monitor.result.nochosen.S": "No answer chosen",
    "monitor.result.nochosen.E": "No candidate chosen",
    "monitor.action.event.open" : "Open event",
    "monitor.action.event.start" : "Start event",
    "monitor.action.event.close" : "Close event",
    "monitor.action.event.final" : "Final results",
    "monitor.action.quest.close" : "Close question",
    "monitor.action.quest.vstart" : "Start voting",
    "monitor.action.quest.vstop" : "Stop voting",
    "monitor.action.quest.nextr" : "Next Round",
    "monitor.action.quest.nextq" : "Next question",
    "monitor.action.castvote" : "Vote",
    "monitor.action.checkout" : "Check out",
    "monitor.action.copyresult" : "Copy the result",
    "monitor.help.stafflist" : "스태프 명단 확인",
    "monitor.help.voterlist" : "참가자 명단 확인",
    "monitor.help.qrcode" : "QR-code for voter entrance",
    "monitor.help.help2checkin" : "참가자 체크인 현장지원",
    "monitor.help.help2vote" : "참가자 투표 현장지원",
    "monitor.help.qsummary" : "{1} 요약 정보",
    "monitor.help.rhistory" : "{1} 라운드 이력",
    "monitor.waiting.event.checkin" : "Waiting for the voters' check-in",
    "monitor.waiting.event.checkin2" : "Waiting for the other voters' check-in",
    "monitor.waiting.event.start" : "Waiting for the event to be started",
    "monitor.waiting.quest.select" : "Waiting for the next question to be selected",
    "monitor.waiting.quest.close": "Waiting for the question to be closed",
    "monitor.waiting.quest.vstart" : "Waiting for the voting to be started",
    "monitor.waiting.quest.vstop" : "Waiting for the other voters to vote",
    "monitor.waiting.quest.nextr" : "Waiting to proceed to the next round",
    "monitor.splash.loading": "Loading event ...",
    "monitor.splash.badaddr": "Sorry, it's not a valid entrance.",
    "monitor.splash.checkin": "You need to check-in first.",
    "monitor.splash.closed": "The event has been closed.",
    "monitor.splash.thankyou": "Thank you for participating.",

    "viewer.button.qst.next" : "Next",
    "viewer.button.qst.prev" : "Prev",

    "entrance.qrcode.for.transfer" : "Open on another device",
    "entrance.qrcode.instruct.user" : "Click or scan this QR-code with Camera app.",
    "entrance.qrcode.instruction" : "Use Camera app to scan this QR-code.",

    "voter.schedule.day-1": "Yesterday",
    "voter.schedule.day+0": "Today",
    "voter.schedule.day+1": "Tomorrow",
    "voter.schedule.soon": "Soon",
    "voter.schedule.wait": "Please wait until this event is open to participate.",
    "voter.checkin.invitation": "'{1}'\nhave invited you to the event\n\"{2}\"",
    "voter.checkin.direction.nc": "Enter your name and passcode for eligibility check",
    "voter.checkin.direction.n": "Enter your name for eligibility check",
    "voter.checkin.label.name": "Name",
    "voter.checkin.label.code": "Personal Code",
    "voter.checkin.button": "Participate",
    "voter.checkin.err.pnotfound": "The event cannot be found.",
    "voter.checkin.err.pnotopen": "The event is not open yet.",
    "voter.checkin.err.pclosed": "The event has been closed.",
    "voter.checkin.err.vnotfound": "The name is not registered for this event.",
    "voter.checkin.err.vexlimit": "Maximum number of participants ({1}) has been reached.",
    "voter.checkin.nav.label": "More about Pollerz.net?",
    "voter.checkin.nav.button": "Visit homepage",
    "voter.vcast.err.duplicate": "You have voted already.",
    
    "entrance.poller.title": "Open the event\n'{1}',\nas the organizer of the event",
    "entrance.poller.title2": "Open the event\n'{1}'\nusing another mobile device",
    "entrance.manager.title": "Open the event\n'{1}',\nas the manager of the event",
    "entrance.rehearsal.title": "You are opening the event,\n'{1}',\nto test with vote simulation.",
    "entrance.rehearsal.poller": "Poller",
    "entrance.rehearsal.nvoters": "Voters",
    "entrance.rehearsal.vmethod": "Vote Simulation",
    "entrance.staff.title": "Assist voters in the event\n'{1}',\nas a staff member of the event",
    "entrance.staff.title2": "Assist voters in the event\n'{1}'\nusing another mobile device",
    "entrance.voteself.title": "Cast my vote for the event\n'{1}',\nas a eligible voter",
    "entrance.voteself.title2": "Cast my vote for the event\n'{1}'\nusing another mobile device",
    "entrance.trial.asvoter.title": "You are invited to an event,\n\"{1}\",\nfor a trial as voter.",
    "entrance.trial.aspoller.title": "You are organizing the trial event,\n\"{1}\".",
    "entrance.trial.aspoller.instr0": "Plan and prepare the event.",
    "entrance.trial.aspoller.instr1": "Invite voters for the event.",
    "entrance.trial.aspoller.instr2": "Open and run the event.",
    "entrance.trial.example.name": "John Doe",
    "entrance.trial.title": "You have an event with agenda prepared,\n\"{1}\".\nNow run the event.",
    "entrance.trial.label": "Enter your name as the host of event",
    "entrance.button.openevent": "Open the event",
    "entrance.button.startevent": "Start the event",
    "entrance.button.assistvoters": "Open to Assist Voters",
    "entrance.button.voteself": "Vote",
    "entrance.simulated": "This event is a simulation, and will not be saved.",

};

export default localeStrMap;