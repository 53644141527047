import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton"
import PzDialog from "../../common/PzDialog";
import EventTimer from "../../event/EventTimer";
import EvtStaffList from "../../event/EvtStaffList";
import EvtVoterList from "../../event/EvtVoterList";
import PollCard from "../../event/PollCard";
import EntranceToRunPoll from "../../entrance/EntranceToRunPoll";
import EMWithPzServer from "../../eventmonitor/EMWithPzServer";
import { ReactComponent as IconStepNext } from "../../resource/icons/pz-step-next.svg";
import { ReactComponent as IconStepPrev } from "../../resource/icons/pz-step-prev.svg";
import { ReactComponent as IconSmartPhone } from "../../resource/icons/pz-tool-smartphone.svg";
import UserSplash from "../UserSplash";

export default function RunPoll() {
    const [cookies, removeCookie] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const [poll, setPoll] = React.useState(upoll);  
    const navigate = useNavigate();
    const localeMap = useSelector(utils.selectLocaleMap);
    const [open4EntRun, setOpen4EntRun] = React.useState(false);
    const [open4Close, setOpen4Close] = React.useState(false);
    const location = useLocation();
    const query = utils.useQuery();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!uauth) {
            let pguid = (upoll ? upoll.pguid : query.get("p"))
            navigate("/auth/login", {state:{referer:location.pathname, pguid:pguid}});
        }
    }, [cookies]);

    function setPoll2(new_poll) {
        setPoll(new_poll);                  // update the poll in memory
        dispatch(setUPoll(new_poll));       // update the poll in Redux storage
    }

    function updatePollInfo() {
        if (!upoll) return;
        EMWithPzServer.updateEventInfo(upoll.pguid, upoll, setPoll2, '/api/xpp/event', upoll.xrole);
    }

    function onClickToOpenEvent() {
        if (!upoll || upoll.pstatus !== 'Ax') return;
        EMWithPzServer.setPollStatus(upoll, setPoll2, 'Oo');
    }

    function onConfirmToCloseEvent() {
        if (!upoll || upoll.pstatus !== 'Oz') return;
        EMWithPzServer.setPollStatusClosed(upoll, setPoll2);
    }

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    }  

    return (
        <PzArea.Container >
            <PzArea.TBoard >

                <div className="w-full px-5 flex flex-col justify-start items-start gap-4">
                    <PollCard.Item poll={upoll} size='lg' expandable={true} withClock={true} />
                </div>


                <h2>{localeMap["menu.poller.runpoll"]}</h2>

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    <div className="w-full flex flex-row justify-center items-start">
                        <div className="w-fit flex flex-col justify-start items-center gap-5">
                            {/* <PzButton.Std text={localeMap["poller.runpoll.open.event"]} size='xl' 
                                className={"w-full " + (EventTimer.isOpenable(upoll) ? "heartbeat" : "")}
                                onClick={onClickToOpenEvent} disabled={!EventTimer.isOpenable(upoll)} /> */}
                            <PzButton.Std Icon={IconSmartPhone} text={localeMap["poller.runpoll.open.runner"]}  size='2xl' 
                                className={"w-full " + (upoll.pstatus === 'Oo' && upoll.qactive < 0 ? "heartbeat" : "")}
                                onClick={(e)=>{setOpen4EntRun(true);}} disabled={upoll.pstatus < 'Ax' || upoll.pstatus > 'Oz'}/>
                            { upoll.pstatus === 'Oz' &&
                            <PzButton.Std text={localeMap["poller.runpoll.xpoll.title"]}  size='xl' 
                                className={"w-full "}
                                onClick={(e)=>{setOpen4Close(true);}} disabled={upoll.pstatus !== 'Oz'}/>
                            }
                        </div>
                    </div>
                </div>

                {/* Staff */}
                <EvtStaffList.Module poll={upoll} setPoll={setPoll2} asPoller={true} />

                {/* Voters */}
                <EvtVoterList.Module poll={upoll} setPoll={setPoll2} asPoller={true} />

                <div className="w-full flex flex-row justify-between items-center mt-10">
                    <PzButton.Icon Icon={IconStepPrev} size='xl' onClick={()=>{navigate(-1);}}/>
                    <PzButton.Std  Icon={IconStepNext} text={localeMap["menu.poller.xresult"]} size='lg' 
                        onClick={()=>{navigate("/pages/poller/xresult");}} 
                        disabled={poll.pstatus < 'Oz'} />
                </div>

            </PzArea.TBoard >

            { open4EntRun && 
                <EntranceToRunPoll.Dialog poll={upoll} onClose={(e)=>{setOpen4EntRun(false);}} />
            }
            { open4Close && 
                <PzDialog.Simple title={localeMap["poller.runpoll.xpoll.title"]} 
                    message={localeMap["poller.runpoll.xpoll.msg"]} 
                    onClose={()=>{setOpen4Close(false);}}
                    onButtonCancel={()=>{setOpen4Close(false);}}
                    onButtonOk={()=>{onConfirmToCloseEvent(); setOpen4Close(false);}} />
            }

        </PzArea.Container >
    );
}

