import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";

import { ReactComponent as IconStepRedo } from "../../resource/icons/pz-step-redo.svg";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PollCard from "../../event/PollCard";
import PollCreator from "../../event/PollCreator";

export default function MyEvents() {
    const [cookies] = useCookies(['uauth']);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const localeMap = useSelector(utils.selectLocaleMap);
    const [newPoll, setNewPoll] = React.useState([]);  
    const [loading4Pln, setLoading4Pln] = React.useState(false);
    const [loading4Ach, setLoading4Ach] = React.useState(false);
    const [loading4Sys, setLoading4Sys] = React.useState(false);
    const [planned, setPlanned] = React.useState(null);
    const [archived, setArchived] = React.useState(null);
    const [sysevents, setSysEvents] = React.useState(null);
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    // console.log("MyEvents :", location.pathname, location.state);

    React.useEffect(() => {
        if (uauth) {
            fetchMyEvents('PLANNED', location.state?.pguid);
        }
    }, []);

    function fetchMyEvents(target='PLANNED', pguid2select=undefined) {
        let pzapi = '';
        if      (target === 'SYSEVENTS') { pzapi = "/api/my/sysevts";  setLoading4Sys(true); }
        else if (target === 'ARCEVENTS') { pzapi = "/api/my/archived"; setLoading4Ach(true); }
        else                             { pzapi = "/api/my/planned";  setLoading4Pln(true); }
        appConfig.getAPI().post(pzapi, null)
        .then((response) => {
            // console.log("PzApi 'myevents' responded : ", response.data);
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            if (pzdata.respcode !== 0) {                // PzAPI returned failure
                console.log("PzAPI 'myevents' returned failure : ", pzdata);
            } else if (target == 'SYSEVENTS') {
                setSysEvents(pzdata.resource);
            } else if (target == 'ARCEVENTS') {
                setArchived(pzdata.resource);
            } else {
                let apidata = pzdata.resource;
                let new_planned = apidata.events.concat(apidata.staffing);          // combine MY_OWN with STAFFING events
                new_planned.sort((a,b)=>{return (a.pguid < b.pguid ? +1 : -1);});   // sort the list in descending order
                //
                setPlanned(new_planned);
                // update the event selection
                if (new_planned.length == 1) {
                    dispatch(setUPoll(new_planned[0]));
                } else if (pguid2select) {
                    for (let i = 0; i < new_planned.length; i++) {
                        if (pguid2select === new_planned[i].pguid) { 
                            dispatch(setUPoll(new_planned[i]));
                            break;
                        }
                    }
                } else if (upoll && upoll.pstatus < 'Xx') { 
                    let upoll_found = false; // if there is a selected 'upoll', then update it
                    for (let i = 0; i < new_planned.length; i++) {
                        if (upoll.pguid === new_planned[i].pguid) { upoll_found = true; break; }
                    }
                    if (!upoll_found) dispatch(setUPoll(null)); 
                }
            }
        })
        .catch((error) => {
            console.log("PzAPI '" + pzapi + "' error : ", error);
        })
        .finally(() => {
            if      (target === 'SYSEVENTS') { setLoading4Sys(false); }
            else if (target === 'ARCEVENTS') { setLoading4Ach(false); }
            else                             { setLoading4Pln(false); }
        });
    };

    function getBoardTitle(keyward, plist) {
        return localeMap[keyward] + (uauth && plist ? ` [${plist?.length}]` : '');
    }

    function onChangeToArcEvtsFold(folded_old, folded_new) {
        if (!folded_new && (!archived || archived.length === 0)) {
            fetchMyEvents('ARCEVENTS');
        }
    }

    function onChangeToSysEvtsFold(folded_old, folded_new) {
        if (!folded_new && (!sysevents || sysevents.length === 0)) {
            fetchMyEvents('SYSEVENTS');
        }
    }

    function onSuccessToCreateEvent(created_pguid) {
        fetchMyEvents('PLANNED', created_pguid);
    }

    return (
        <PzArea.InvisibleBoardInContainer>

            { uauth?.uemail ? 
                <div className="w-full px-10 flex flex-row justify-between items-center gap-5">
                    <PzButton.Std text={"PzA Dashboard"} size='xl' className={uauth.ispza ? '' : 'invisible'}
                        onClick={()=>{navigate("/pages/pza/dashboard");}} />
                    <PollCreator.Button poll={newPoll} setPoll={setNewPoll} 
                        size='xl' useicon={true} onSuccess={onSuccessToCreateEvent} />
                </div> 
            : 
                <div className="w-full flex flex-row justify-center items-center gap-2">
                    <p className="text-md font-semibold text-slate-900">
                        {localeMap["myevents.needtologin"]}
                    </p>
                    <PzButton.Std text={localeMap["menu.auth.login"]} size='md' onClick={()=>{navigate("/auth/login",{state:{funnel:"myevents"}});}} />
                </div>
            }

            <PzArea.TBoard title={getBoardTitle("myevents.planned", planned)} 
                foldable={true} folded={(upoll && upoll.pstatus === 'Xx')} >
                <div className="w-full pl-5 flex flex-col justify-start items-start gap-5">
                    { planned && planned.length > 0 && uauth?.uemail ?
                        <PollCard.List plist={planned} size='lg' className="w-full" />
                    : 
                        <PollCard.Empty mode='planned' />
                    }
                    <div className="w-full text-center">
                        <PzButton.Icon Icon={IconStepRedo} size='md' onClick={()=>{fetchMyEvents('PLANNED');}} />
                    </div>
                </div>
            </PzArea.TBoard>

            <PzArea.TBoard title={getBoardTitle("myevents.archived", archived)} 
                foldable={true} folded={!upoll || upoll.pstatus !== 'Xx'} onChangeToFold={onChangeToArcEvtsFold} >
                <div className="w-full pl-5 flex flex-col justify-start items-start gap-5">
                    { archived && archived.length > 0 && uauth?.uemail ?
                        <PollCard.List plist={archived} size='lg' className="w-full" />
                    :
                       <PollCard.Empty mode='archived' />
                    }
                    <div className="w-full text-center">
                        <PzButton.Icon Icon={IconStepRedo} size='md' onClick={()=>{fetchMyEvents('ARCEVENTS');}} />
                    </div>
                </div>
            </PzArea.TBoard>

            { uauth && uauth.ispza && 
            <PzArea.TBoard title={getBoardTitle("myevents.sysevents", sysevents)} 
                foldable={true} folded={true} onChangeToFold={onChangeToSysEvtsFold} >
                <div className="w-full pl-5 flex flex-col justify-start items-start gap-5">
                    { sysevents && sysevents.length > 0 && uauth?.uemail ?
                        <PollCard.List plist={sysevents} size='lg' className="w-full" />
                    :
                        <PollCard.Empty mode='sysevent' />
                    }
                    <div className="w-full text-center">
                        <PzButton.Icon Icon={IconStepRedo} size='md' onClick={()=>{fetchMyEvents('SYSEVENTS');}} />
                    </div>
                </div>
            </PzArea.TBoard>
            }

        </PzArea.InvisibleBoardInContainer>
    );
}
